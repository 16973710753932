import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { LANGUAGE_FILE_NAME_SUFFIX, SUPPORTED_LANGUAGE } from '../../constants';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { TranslateRouterService } from '../../core/services/translate-router.service';
import { CalculatedMutationOperationPairsType, ISelectOption, tryToUnsubscribeFromSubscription } from 'sfx-commons';
import { ObjectUtils } from '../../core/utils/object.utils';
import { nameOfDirectProperty } from '../../core/utils/utils';
import { ISupportedLanguagePack } from '../../mad-translate/models/i-supported-language-pack';
import { LocaleUtils } from '../../mad-translate/utils/locale.utils';
import { AuthenticationService } from '../../projects/services/authentication.service';

interface ILanguageFormGroup {
    uiLanguage: FormControl<SUPPORTED_LANGUAGE>;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-language-picker',
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent {
    @Input()
    public hasSidebarLayout = false;

    public currentUser: IApplicationUser;
    public formGroup: FormGroup<ILanguageFormGroup>;
    public supportedLanguages: Array<ISelectOption<SUPPORTED_LANGUAGE>> = [];
    public supportedLanguagePack: ISupportedLanguagePack = LocaleUtils.getSupportedLanguages();

    private selectedUiLanguage: { uiLanguage: SUPPORTED_LANGUAGE };
    private formValueChangesSubscription: Subscription;

    constructor(private readonly authSvc: AuthenticationService,
                private readonly localStorageSvc: LocalStorageService,
                private readonly translateRouterSvc: TranslateRouterService) {
        Object.keys(this.supportedLanguagePack.supportedLanguages)
            .map(key => this.supportedLanguages.push({
                value: key as SUPPORTED_LANGUAGE,
                label: `settings.languages.${ key }`
            }));

        this.authSvc.currentUser
            .pipe(untilDestroyed(this))
            .subscribe((user: IApplicationUser) => this.setUpFormGroup(user));
    }

    public async setLanguage(language: SUPPORTED_LANGUAGE): Promise<void> {
        this.formGroup.controls.uiLanguage.setValue(language);
    }

    private async applyLanguageChange(language: SUPPORTED_LANGUAGE): Promise<void> {
        window.location.pathname = await this.translateRouterSvc.parseAndTranslateUrl(language, location.pathname);
    }

    private setUiLanguageValue(): void {
        let uiLanguageValue: string = SUPPORTED_LANGUAGE.EN;
        const storageLanguageValue = this.localStorageSvc.get(LOCAL_STORAGE_KEY.LANGUAGE);
        if (storageLanguageValue) {
            uiLanguageValue = ObjectUtils.getKeyForValue(LANGUAGE_FILE_NAME_SUFFIX, storageLanguageValue);
        }
        if (this.currentUser && this.currentUser.uiLanguage) {
            uiLanguageValue = this.currentUser.uiLanguage;
        }

        this.selectedUiLanguage = {
            uiLanguage: uiLanguageValue as SUPPORTED_LANGUAGE
        };
    }

    private setUpFormGroup(user: IApplicationUser) {
        this.currentUser = user;

        if (this.currentUser) {
            this.setUiLanguageValue();
            this.formGroup = new FormGroup<ILanguageFormGroup>({
                uiLanguage: new FormControl(this.selectedUiLanguage?.uiLanguage)
            }).setDisplayErrorsOn('never');

            tryToUnsubscribeFromSubscription(this.formValueChangesSubscription);
            this.formValueChangesSubscription = this.formGroup.setupBlurUpdates(this.updateCurrentUser, this, nameOfDirectProperty<LanguagePickerComponent>(c => c.selectedUiLanguage, this));
        } else {
            this.formGroup = null;
        }
    }

    private updateCurrentUser = async (mutationOperationPairs: CalculatedMutationOperationPairsType): Promise<void> => {
        try {
            const mutations = mutationOperationPairs.map(m => m.mutation);

            if (!mutations.length) {
                this.formGroup.markAsPristine();
                return;
            }

            if (!this.currentUser.isInternalUser) {
                await this.authSvc.updateCurrentUser(mutations, this.formGroup);
            }

            const selectedLanguage = this.formGroup.controls.uiLanguage.value as SUPPORTED_LANGUAGE;
            this.applyLanguageChange(selectedLanguage);
            this.selectedUiLanguage.uiLanguage = selectedLanguage;
        } catch (ex) {
            console.error(ex);
            this.formGroup.revertAndHighlightErrors(this.selectedUiLanguage);
        }
    };
}
