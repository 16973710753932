import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mathAbs'
})
export class MathAbsPipe implements PipeTransform {
    public transform(num: number): number {
        return Math.abs(num);
    }
}
