<div class="mad-navigation-bar" [class.mad-navigation-bar--compact]="isCompact">
    <div class="mad-navigation-bar__body">
        <div class="mad-navigation-bar__logo-wrapper">
            <div class="mad-navgation-bar__logo"
                 appConfirmLeaveBeforeClick (afterConfirmed)="navigateToHomePage()"
                 [isControlDisabled]="HOME_NAV_ITEM.isDisabled"
                 [title]="HOME_NAV_ITEM.key | translate">
                 <sfx-logo [path]="isCompact ? 'sfx.svg#sfx' : 'swissfex.svg#swissfex'"></sfx-logo>
            </div>
        </div>
        <app-sidebar-item [navItem]="HOME_NAV_ITEM" [isCollapsed]="isCompact" class="mad-navigation-bar__home"></app-sidebar-item>
        <sfx-section-separator *ngIf="areNavItemsVisible" extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>


        <div class="mad-navigation-bar__group">
            <ng-container *ngFor="let navItem of NAV_ITEMS">
                <app-sidebar-item *ngIf="navItem.isVisible"
                                  [navItem]="navItem"
                                  [isCollapsed]="isCompact">
                </app-sidebar-item>
            </ng-container>
        </div>
        <sfx-section-separator *ngIf="areNavItemsVisible" extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
        <div class="mad-spacer"></div>
        <sfx-section-separator extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
        <app-sidebar-item *ngFor="let navItem of BOTTOM_NAV_ITEMS" [navItem]="navItem" [isCollapsed]="isCompact"></app-sidebar-item>
        <app-sidebar-item [navItem]="logoutItem" [isCollapsed]="isCompact"></app-sidebar-item>
        <sfx-section-separator extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
        <app-sidebar-item [navItem]="privacyModeItem" [isCollapsed]="isCompact"></app-sidebar-item>
    </div>
</div>

