<div class="page-wrapper">
    <sfx-loader class="no-flex-grow" *ngIf="isCallInProgress"></sfx-loader>
    <div class="redirect-container mad-list mad--vertical" *ngIf="didCallFail">
        <sfx-info type="danger" text="key4_training_completed.failed.title"></sfx-info>
        <sfx-button type="main"
                    label="key4_training_completed.failed.retry"
                    (clicked)="retryCall()"
        ></sfx-button>
        <sfx-button *ngIf="hasWaitedAnExtendedPeriod"
                    type="main"
                    label="auth.redirect_to_projects.button"
                    (clicked)="redirectToProjects()"
        ></sfx-button>
    </div>
</div>
