import { Component } from '@angular/core';

@Component({
    selector: 'app-new-document-indicator',
    templateUrl: './new-document-indicator.component.html',
    styleUrls: ['./new-document-indicator.component.scss']
})
export class NewDocumentIndicatorComponent {

}
