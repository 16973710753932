import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SfxCommonsModule } from 'sfx-commons';
import { CommonUtilsModule } from '../common-utils/common-utils.module';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { NewDocumentIndicatorComponent } from './new-document-indicator/new-document-indicator.component';
import { RocketComponent } from './rocket/rocket.component';
import { LtvAffordabilitySummaryComponent } from './ltv-affordability-summary/ltv-affordability-summary.component';
import { ServiceCenterModalComponent } from './service-center-modal/service-center-modal.component';
import { SettingsModalComponent } from './settings-modal/settings-modal.component';
import { StampComponent } from './stamp/stamp.component';
import { SidebarNavigationComponent } from './sidebar-navigation/sidebar-navigation.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { NewsBarComponent } from './news/news-bar.component';
import { NewsModalComponent } from './news-modal/news-modal.component';
import { UnmatchedRouteComponent } from './unmatched-route/unmatched-route.component';
import { WalkthroughComponent } from './walkthrough/walkthrough.component';
import { SessionStatusNotificationComponent } from './session-status-notification/session-status-notification.component';

@NgModule({
    declarations: [
        LanguagePickerComponent,
        LtvAffordabilitySummaryComponent,
        NewDocumentIndicatorComponent,
        NewsBarComponent,
        NewsModalComponent,
        RocketComponent,
        ServiceCenterModalComponent,
        SessionStatusNotificationComponent,
        SettingsModalComponent,
        SidebarItemComponent,
        SidebarNavigationComponent,
        StampComponent,
        UnmatchedRouteComponent,
        WalkthroughComponent
    ],
    exports: [
        LanguagePickerComponent,
        LtvAffordabilitySummaryComponent,
        NewDocumentIndicatorComponent,
        NewsBarComponent,
        ServiceCenterModalComponent,
        SessionStatusNotificationComponent,
        SettingsModalComponent,
        SidebarNavigationComponent,
        StampComponent,
        UnmatchedRouteComponent,
        WalkthroughComponent,

        SfxCommonsModule
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        CommonUtilsModule,

        SfxCommonsModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CommonComponentsModule {}
