import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { takeLast, takeWhile } from 'rxjs';
import { MAIN_ROUTES } from '../../constants';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { RouterService } from '../services/router.service';
import { LocationUtils } from '../utils/location.utils';

@Injectable({ providedIn: 'root' })
class UnauthenticatedGuardService {
    constructor(private readonly authSvc: AuthenticationService,
                private readonly routerSvc: RouterService) {}

    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.authSvc.authStatus
                .pipe(
                    takeWhile(authData => !authData, true),
                    takeLast(1)
                )
                .subscribe(async authData => {
                    if (authData.isAuthenticated) {
                        const nextRoute = route.queryParams && route.queryParams.returnUrl ?
                            route.queryParams.returnUrl : [MAIN_ROUTES.PROJECTS];

                        const queryParams = LocationUtils.parseQueryString();
                        await this.routerSvc.navigateToRoute(nextRoute, null, queryParams ? queryParams : undefined);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    }
}


export const canActivateNextRoute: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    return inject(UnauthenticatedGuardService).canActivate(route);
};
