import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, ResolveEnd, Router } from '@angular/router';
import {
    faArrowRightFromBracket as falArrowRightFromBracket,
    faBell as falBell,
    faFileLines as falFileLines,
    faGear as falGear,
    faHouseChimney as falHouseChimney,
    faMessagePen as falMessagePen,
    faNoteSticky as falNoteSticky,
    faUserGear as falUserGear,
    faUserHeadset as falUserHeadset,
    faUsers as falUsers,
    faUserSecret as falUserSecret
} from '@fortawesome/pro-light-svg-icons';
import { faUserSecret as fasUserSecret } from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Userpilot } from 'userpilot';
import { environment } from '../../../environments/environment';
import {
    EDIT_PROJECT_PATHS,
    MAIN_ROUTES,
    OUTLET_NAMES,
    PATH_NAMES_PER_OUTLETS
} from '../../constants';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { RealTimeNotificationService } from '../../core/services/real-time-notification.service';
import { RouterService } from '../../core/services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../../core/services/session-storage.service';
import { RouterUtils } from '../../core/utils/router.utils';
import { isUserPilotFlowEnabled } from '../../core/utils/utils';
import { ProjectStatus } from '../../projects/models/enums/projectStatus';
import { INews } from '../../projects/models/INews';
import { IPrivacySettings } from '../../projects/models/IPrivacySettings';
import { IProject } from '../../projects/models/IProject';
import { IRequiredDocuments } from '../../projects/models/IRequiredDocuments';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { AuthorizationService } from '../../projects/services/authorization.service';
import { DocumentsService } from '../../projects/services/documents.service';
import { NewsService } from '../../projects/services/news.service';
import { PrivacyService } from '../../projects/services/privacy.service';
import { ProjectsService } from '../../projects/services/projects.service';
import { ISidebarItem, SidebarItemHighlightType } from '../sidebar-item/sidebar-item.component';

const COMPACT_TRIGGER_WIDTH = 1680;

@UntilDestroy()
@Component({
    selector: 'app-sidebar-navigation',
    templateUrl: './sidebar-navigation.component.html',
    styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent implements OnInit {
    private readonly userProfileItem: ISidebarItem = {
        routeSubItems: ['profile'],
        key: '',
        extraClasses: 'nav-max-width',
        icon: falUserGear,
        outstandingNotifications: 0,
        isDisabled: true,
        isVisible: true,
        hideCollapsed: true
    };
    public HOME_NAV_ITEM: ISidebarItem;
    public isCompact = false;
    public readonly NAV_ITEMS: Array<ISidebarItem> = [
        {
            key: 'navigation_title.team_selling',
            icon: falUsers,
            outstandingNotifications: 0,
            activatorParents: [EDIT_PROJECT_PATHS.EDIT],
            isVisible: true,
            outlet: { [OUTLET_NAMES.TEAM_SELLING_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.TEAM_SELLING_MODAL_OUTLET] },
            queryParamsHandling: 'preserve'
        },
        {
            key: 'navigation_title.description',
            icon: falNoteSticky,
            outstandingNotifications: 0,
            activatorParents: [EDIT_PROJECT_PATHS.EDIT],
            isVisible: true,
            outlet: { [OUTLET_NAMES.PROJECT_DESCRIPTION_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.PROJECT_DESCRIPTION_OUTLET] },
            queryParamsHandling: 'preserve'
        }
    ];
    public readonly BOTTOM_NAV_ITEMS = [this.userProfileItem];
    public readonly logoutItem: ISidebarItem = {
        key: 'navigation_title.logout',
        icon: falArrowRightFromBracket,
        isVisible: false,
        hasToBeConfirmed: true
    };
    public readonly privacyModeItem: ISidebarItem = {
        key: 'navigation_title.privacy_mode_off',
        icon: falUserSecret,
        isVisible: true
    };
    public readonly newsItem: ISidebarItem = {
        key: 'navigation_title.news',
        icon: falBell,
        isVisible: true
    };

    public project: IProject;

    public isAnonymized: boolean;
    public isBackofficeUser = false;
    public currentUser: IApplicationUser;
    public news: Array<INews>;
    public areNavItemsVisible = false;

    private restrictedMenuItems: Array<ISidebarItem> = [];
    private hasTeamSellingAccessPermission = false;
    private readonly webSocketConnectedClass = 'web-socket-connection-status__connected';
    private readonly webSocketDisconnectedClass = 'web-socket-connection-status__disconnected';
    private readonly documentsModalNavItem: ISidebarItem = {
        key: 'navigation_title.documents',
        icon: falFileLines,
        outstandingNotifications: 0,
        activatorParents: [EDIT_PROJECT_PATHS.EDIT],
        isVisible: true,
        outlet: { [OUTLET_NAMES.DOCUMENT_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.DOCUMENT_MODAL_OUTLET] }
    };
    private readonly documentsPageNavItem: ISidebarItem = {
        key: 'edit_project.documents',
        icon: falFileLines,
        outstandingNotifications: 0,
        activatorParents: [EDIT_PROJECT_PATHS.EDIT],
        isVisible: true,
        routeSubItems: [EDIT_PROJECT_PATHS.DOCUMENTS],
        isAbsolute: false,
        queryParamsHandling: 'preserve'
    };

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly authenticationSvc: AuthenticationService,
                private readonly authorizationSvc: AuthorizationService,
                private readonly documentsSvc: DocumentsService,
                private readonly newsSvc: NewsService,
                private readonly privacyService: PrivacyService,
                private readonly projectSvc: ProjectsService,
                private readonly realTimeNotificationSvc: RealTimeNotificationService,
                private readonly router: Router,
                private readonly routerSvc: RouterService,
                private readonly sessionStorageSvc: SessionStorageService,
                private readonly translateSvc: TranslateService) {
        this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.RETURN_URL_KEY);
        this.setupHomeNavItem();
        this.privacyModeItem.onClick = this.togglePrivacyMode;
        this.logoutItem.onClick = this.logout;

        if (this.NAV_ITEMS) {
            this.NAV_ITEMS.map(item => {
                if (item.activatorParents && item.activatorParents.length) {
                    this.restrictedMenuItems.push(item);
                }
            });
        }

        if (this.BOTTOM_NAV_ITEMS) {
            this.BOTTOM_NAV_ITEMS.unshift({
                key: 'navigation_title.service_center',
                extraClasses: 'nav-max-width',
                icon: falUserHeadset,
                isVisible: true,
                onClick: this.openServiceCenterModal
            }, {
                key: 'navigation_title.settings',
                extraClasses: 'nav-max-width',
                icon: falGear,
                isVisible: true,
                onClick: this.openSettingsModal
            });

            if (isUserPilotFlowEnabled(environment.features?.userPilot?.flows?.leave_feedback)) {
                this.BOTTOM_NAV_ITEMS.splice(1, 0, {
                    key: 'navigation_title.feedback',
                    extraClasses: 'nav-max-width',
                    icon: falMessagePen,
                    isVisible: true,
                    onClick: () => Userpilot.trigger(environment.features.userPilot.flows.leave_feedback.key)
                });
            }

            this.BOTTOM_NAV_ITEMS.map(item => {
                if (item.activatorParents && item.activatorParents.length) {
                    this.restrictedMenuItems.push(item);
                }
            });
        }

        this.translateActivatorParentsForRestrictedMenuItems();
        this.checkAndToggleClass(window.innerWidth);
    }

    public async ngOnInit(): Promise<void> {
        this.privacyService.privacyObservable
            .pipe(untilDestroyed(this))
            .subscribe(({ isAnonymized }: IPrivacySettings) => {
                this.isAnonymized = isAnonymized;

                this.privacyModeItem.key = this.isAnonymized ? 'navigation_title.privacy_mode_on' : 'navigation_title.privacy_mode_off';
                this.privacyModeItem.icon = this.isAnonymized ? fasUserSecret : falUserSecret;
                this.privacyModeItem.isInverted = this.isAnonymized;
            });

        this.authenticationSvc.authStatus
            .pipe(untilDestroyed(this))
            .subscribe(authData => {
                if (authData) {
                    this.logoutItem.isVisible = authData.isAuthenticated;
                }
            });

        this.authenticationSvc.currentUser
            .pipe(untilDestroyed(this))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
                this.isBackofficeUser = this.authenticationSvc.isSfxAdmin;

                if (this.isBackofficeUser) {
                    this.userProfileItem.hideCollapsed = false;
                }

                if (currentUser) {
                    this.userProfileItem.isVisible = true;
                    this.userProfileItem.key = `${ currentUser.firstname } ${ currentUser.lastname }`;
                } else {
                    this.userProfileItem.isVisible = false;
                }

                this.updateTeamSellingAvailability();
                this.setNavItemsSeparator();
            });

        this.authorizationSvc.openedProjectPermissionMapObservable
            .pipe(untilDestroyed(this))
            .subscribe(projectPermissionMap => {
                this.hasTeamSellingAccessPermission = !!projectPermissionMap?.PP_TeamSellingReadOnlyAccess;
                this.updateTeamSellingAvailability();
            });

        this.projectSvc.openedProjectObservable
            .pipe(untilDestroyed(this))
            .subscribe(project => {
                this.project = project;
                this.updateDocumentsAvailability();
                this.updateTeamSellingAvailability();
                this.updateDescriptionAvailability();
                this.setNavItemsSeparator();
            });

        this.documentsSvc.requiredDocumentsObservable
            .pipe(untilDestroyed(this))
            .subscribe((requiredDocuments: IRequiredDocuments) => {
                const docsIndex = this.NAV_ITEMS.findIndex(navItem => navItem.key === this.documentsModalNavItem.key || navItem.key === this.documentsPageNavItem.key);
                if (docsIndex > -1 && this.NAV_ITEMS[docsIndex].highlightType !== SidebarItemHighlightType.Error) {
                    let shouldDocumentsNavItemBeHighlighted = false;
                    const flatGroups = requiredDocuments?.categories?.map(category => category.groups)
                        .flat() || [];
                    if (flatGroups.length && !!flatGroups.find(g => !g.isDisabled)) {
                        const documents = flatGroups
                            .map(group => group.assignedDocuments)
                            .flat();

                        if (documents?.length) {
                            shouldDocumentsNavItemBeHighlighted = documents.some(document => document.isNew && document.isValid && !document.isTooBig);
                        }
                    }

                    this.NAV_ITEMS[docsIndex].highlightType = shouldDocumentsNavItemBeHighlighted ? SidebarItemHighlightType.Warning : null;
                }
            });

        this.realTimeNotificationSvc.isWebSocketConnected
            .pipe(untilDestroyed(this))
            .subscribe((isConnected: boolean) => {
                if (this.isBackofficeUser) {
                    this.userProfileItem.extraClasses = `nav-max-width ${ isConnected ? this.webSocketConnectedClass : this.webSocketDisconnectedClass }`;
                }
            });

        if (this.restrictedMenuItems.length) {
            this.setVisibilityOfRestrictedItems(this.router.url);

            this.translateSvc.onLangChange
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.translateActivatorParentsForRestrictedMenuItems();
                });

            this.router.events
                .pipe(untilDestroyed(this))
                .subscribe(event => {
                    switch (true) {
                        case event instanceof GuardsCheckEnd && event.shouldActivate:
                            this.setVisibilityOfRestrictedItems((event as GuardsCheckEnd).urlAfterRedirects);
                            break;
                        case event instanceof ResolveEnd && !!event.urlAfterRedirects:
                            this.setVisibilityOfRestrictedItems((event as unknown as GuardsCheckEnd).urlAfterRedirects);
                            break;
                    }
                });
        }

        this.news = await this.newsSvc.getNews();
        if (!!this.news?.length && !this.BOTTOM_NAV_ITEMS.includes(this.newsItem)) {
            this.newsItem.onClick = this.openNewsModal;
            this.BOTTOM_NAV_ITEMS.unshift(this.newsItem);
        }
    }

    public updateTeamSellingAvailability = (): void => {
        const teamSellingIndex = this.NAV_ITEMS
            .findIndex(navItem => navItem.key === 'navigation_title.team_selling');
        this.NAV_ITEMS[teamSellingIndex].isVisible = RouterUtils.canUserAccessTeamSelling(this.currentUser, this.project?.owner?.id, this.project?.owner?.tenantSettings?.isTeamSellingEnabled, this.hasTeamSellingAccessPermission);
    };

    public updateDescriptionAvailability = (): void => {
        const descriptionIndex = this.NAV_ITEMS.findIndex(navItem => navItem.key === 'navigation_title.description');
        this.NAV_ITEMS[descriptionIndex].isVisible = !!this.project;
    };

    public updateDocumentsAvailability(): void {
        if (this.project) {
            let visibleDocumentsSidebarItem: ISidebarItem;
            let hiddenDocumentsSidebarItem: ISidebarItem;
            const highlightType = this.project.projectStatus === ProjectStatus.AdditionalDocumentsRequestedByCC ? SidebarItemHighlightType.Error : null;
            if (this.project.externalDocumentManagement) {
                visibleDocumentsSidebarItem = { ...this.documentsPageNavItem, highlightType };
                hiddenDocumentsSidebarItem = this.documentsModalNavItem;
            } else {
                visibleDocumentsSidebarItem = { ...this.documentsModalNavItem, highlightType };
                hiddenDocumentsSidebarItem = this.documentsPageNavItem;
            }
            this.NAV_ITEMS.removeItem(hiddenDocumentsSidebarItem, 'key');
            const documentsNavItem = this.NAV_ITEMS.find(item => item.key === visibleDocumentsSidebarItem.key);
            if (!documentsNavItem) {
                this.NAV_ITEMS.push(visibleDocumentsSidebarItem);
            } else {
                documentsNavItem.highlightType = highlightType;
            }
            this.documentsSvc.signalGetRequiredDocuments();
        } else {
            this.NAV_ITEMS.removeItem(this.documentsModalNavItem, 'key');
            this.NAV_ITEMS.removeItem(this.documentsPageNavItem, 'key');
        }
    }

    public togglePrivacyMode = () => {
        this.privacyService.setAnonymization(!this.isAnonymized);
    };

    public navigateToHomePage = () => {
        this.routerSvc.navigateToRoute([MAIN_ROUTES.PROJECTS]);
    };

    public openServiceCenterModal = async () => {
        await this.router.navigate([{ outlets: { [OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET] } }], {
            relativeTo: this.activatedRoute.root,
            queryParamsHandling: 'preserve'
        });
    };

    public openSettingsModal = async () => {
        await this.router.navigate([{ outlets: { [OUTLET_NAMES.SETTINGS_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.SETTINGS_MODAL_OUTLET] } }], {
            relativeTo: this.activatedRoute.root,
            queryParamsHandling: 'preserve'
        });
    };

    public openNewsModal = async () => {
        await this.router.navigate([{ outlets: { [OUTLET_NAMES.NEWS_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.NEWS_MODAL_OUTLET] } }], {
            relativeTo: this.activatedRoute.root,
            queryParamsHandling: 'preserve'
        });
    };

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.checkAndToggleClass(event.target.innerWidth);
    }

    public logout = async () => {
        this.sessionStorageSvc.set(SESSION_STORAGE_KEY.USER_LOGOUT, true);
        this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN);
        this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS);
        await this.authenticationSvc.logout(true);
    };

    private checkAndToggleClass(width: number) {
        this.isCompact = width <= COMPACT_TRIGGER_WIDTH;
    }

    private translateActivatorParentsForRestrictedMenuItems(): void {
        this.restrictedMenuItems.map(item => {
            item.activatorParents = item.activatorParents.map(parent => this.routerSvc.translateRoute([parent]) as string);
        });
    }

    private setupHomeNavItem(): void {
        this.HOME_NAV_ITEM = {
            onClick: this.navigateToHomePage,
            key: 'navigation_title.home',
            icon: falHouseChimney,
            outstandingNotifications: 0,
            hasToBeConfirmed: true,
            isVisible: true
        };

        this.setHomeNavItemActiveAndDisabled(location.pathname);

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(untilDestroyed(this))
            .subscribe((event: NavigationEnd) => this.setHomeNavItemActiveAndDisabled(event.urlAfterRedirects));
    }

    private setHomeNavItemActiveAndDisabled(inputUrl: string): void {
        const localizedHomeRoute = this.routerSvc.translateRoute([MAIN_ROUTES.PROJECTS], null, true);
        const url = inputUrl.split('?')[0];
        this.HOME_NAV_ITEM.isActive = url === localizedHomeRoute;
        this.HOME_NAV_ITEM.isDisabled = url === localizedHomeRoute ? true : null;
    }

    private setNavItemsSeparator(): void {
        this.areNavItemsVisible = this.NAV_ITEMS.some(navItem => navItem.isVisible);
    }

    private setVisibilityOfRestrictedItems(urlAfterRedirect: string): void {
        const splitUrl = urlAfterRedirect.split('/');
        this.restrictedMenuItems.map(item => {
            item.isVisible = item.activatorParents.some(parent => splitUrl.includes(parent));
        });
        this.updateTeamSellingAvailability();
        this.updateDescriptionAvailability();
        this.setNavItemsSeparator();
    }
}
