import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPaperPlane, faPhoneOffice, faUserHeadset } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { OUTLET_NAMES } from '../../constants';
import { RouterService } from '../../core/services/router.service';

@Component({
    selector: 'app-service-center',
    templateUrl: './service-center-modal.component.html',
    styleUrls: ['./service-center-modal.component.scss']
})
export class ServiceCenterModalComponent {
    public readonly email = environment.service.email;
    public readonly paperPlaneIcon = faPaperPlane;
    public readonly phoneOfficeIcon = faPhoneOffice;
    public readonly userHeadsetIcon = faUserHeadset;

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly routerSvc: RouterService,
                private readonly translate: TranslateService) {
    }

    public closeModal = async () => {
        await this.routerSvc.closeOutlet(OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET, { relativeTo: this.activatedRoute.parent, queryParamsHandling: 'preserve' });
    };

    public openCallPhoneNumber(): void {
        const phoneNumber = this.translate.instant('service_center.phone_number');
        window.location.href = `tel:${phoneNumber}`;
    }

    public openSendEmail(): void {
        window.location.href = `mailto:${this.email};`;
    }
}
