import { Directive, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SafeAny } from '../../constants';
import { ModalUtils } from 'sfx-commons';

@Directive({
    selector: '[appClearInputWarner]'
})
export class ClearInputWarnerDirective {
    @Input()
    public onClear: SafeAny;
    @Input()
    public onCancel: SafeAny;

    constructor(private readonly modalSvc: NzModalService,
                private readonly translate: TranslateService) {}

    @HostListener('click', ['$event'])
    /* eslint-disable */
    public async onClick(event?: SafeAny): Promise<void> {
        const { isConfirmedPromise } = ModalUtils.createResetConfirmation(this.modalSvc, this.translate);
        const isConfirmed = await isConfirmedPromise;
        if (isConfirmed && this.onClear) {
            this.onClear();
        } else if (!isConfirmed && this.onCancel) {
            this.onCancel();
        }
    }

    /* eslint-enable */
}
