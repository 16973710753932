import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { takeLast, takeWhile } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MAIN_ROUTES } from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { DerivedRole } from '../models/enums/derivedRole';
import { IApplicationUser } from '../models/IApplicationUser';
import { RouterService } from '../services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../services/session-storage.service';
import { TranslateRouterService } from '../services/translate-router.service';

@Injectable({ providedIn: 'root' })
class AuthenticatedGuardService {
    constructor(private readonly authSvc: AuthenticationService,
                private readonly madTranslateSvc: MadTranslateService,
                private readonly routerSvc: RouterService,
                private readonly sessionStorageSvc: SessionStorageService,
                private readonly translateRouterSvc: TranslateRouterService) {}

    public canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.authSvc.authStatus
                .pipe(
                    takeWhile(authData => !authData, true),
                    takeLast(1)
                )
                .subscribe(async (authData) => {
                    if (authData.isAuthenticated) {
                        const currentUser = await this.authSvc.getCurrentUser();

                        if (currentUser) {
                            if (currentUser.userType && currentUser.derivedRole !== DerivedRole.SfxAdmin) {
                                let location = window.location.toString();
                                const host = window.location.host;
                                if (host !== environment.originsPerUserType[currentUser.userType]) {
                                    location = location.replace(host,
                                        environment.originsPerUserType[currentUser.userType]);
                                    window.location.href = location;
                                    return;
                                }
                            }

                            await this.handleAuthenticatedUserLanguageChanges(currentUser);
                        }
                        resolve(true);
                    } else {
                        await this.routerSvc.navigateToRoute([MAIN_ROUTES.LOGIN], null, {
                            queryParams: {
                                returnUrl: `${ location.pathname }${ location.search }`
                            }
                        });
                        resolve(false);
                    }
                });
        });
    }

    private async handleAuthenticatedUserLanguageChanges(currentUser: IApplicationUser): Promise<void> {
        const language = this.madTranslateSvc.getAppropriateUiLanguage(currentUser);
        const urlLanguage = this.madTranslateSvc.getSelectedLanguage();
        if (language !== urlLanguage) {
            const pathName = await this.translateRouterSvc.parseAndTranslateUrl(language, location.pathname);
            this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.RETURN_URL_KEY);
            window.location.pathname = pathName;
            return;
        } else {
            this.madTranslateSvc.setNonRouterInvasiveServicesLanguage(language);
        }
    }
}


export const canAuthenticate: CanActivateFn = () => {
    return inject(AuthenticatedGuardService).canActivate();
};
