export class JsonUtils {
    public static parseText(value: unknown): string | unknown {
        if (typeof value !== 'string') {
            return value;
        }

        try {
            return JSON.parse(value);
        } catch {
            return value;
        }
    }
}
