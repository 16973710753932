import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { IWalkthroughConfig, IWalkthroughRequiredElement } from './IWalkthroughConfig';
import Step from 'shepherd.js/src/types/step';

@Component({
    selector: 'app-walkthrough',
    template: '',
    styleUrls: ['./walkthrough.component.scss']
})
export class WalkthroughComponent implements AfterViewInit {
    @Input({ required: true })
    public config: IWalkthroughConfig;
    @Output()
    public walkthroughStarted: EventEmitter<void> = new EventEmitter<void>();

    private shepherdSvc: ShepherdService;

    constructor(private readonly translate: TranslateService) {}

    public ngAfterViewInit(): void {
        this.setupWalkthrough();
    }

    private setupWalkthrough(): void {
        if (!this.config) {
            return;
        }

        this.shepherdSvc = new ShepherdService();

        this.shepherdSvc.modal = this.config.modal;
        this.shepherdSvc.confirmCancel = this.config.confirmCancel;
        this.shepherdSvc.confirmCancelMessage = this.config.confirmCancelMessage ?
            this.translate.instant(this.config.confirmCancelMessage) : null;
        const defaultStepOpts = this.config.defaultStepOptions || {};
        this.shepherdSvc.defaultStepOptions = {
            classes: defaultStepOpts.classes || 'mad-walkthrough-wrapper',
            scrollTo: defaultStepOpts.scrollTo || false,
            cancelIcon: defaultStepOpts.cancelIcon || {
                enabled: true,
            }
        };
        if (this.config.requiredElements && this.config.requiredElements.length) {
            const reqElements: Array<IWalkthroughRequiredElement> = [];
            this.config.requiredElements.map(({ selector, message, title }) => reqElements.push({
                selector,
                message,
                title
            }));
            this.shepherdSvc.requiredElements = reqElements;
        }

        if (this.config.steps && this.config.steps.length) {
            const stepsToAdd: Array<Step.StepOptions> = [];
            this.config.steps.map((step, index) => stepsToAdd.push({
                id: step.id || `step-${index + 1}`,
                attachTo: step.attachTo,
                beforeShowPromise: () => {
                    return new Promise<void>(resolve => {
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                            this.walkthroughStarted.emit();
                            resolve();
                        }, 50);
                    });
                },
                buttons: step.buttons,
                cancelIcon: step.cancelIcon || {
                    enabled: true
                },
                classes: step.classes,
                highlightClass: step.highlightClass,
                scrollTo: step.scrollTo || false,
                title: step.title,
                text: step.text,
                when: step.when
            }));
            this.shepherdSvc.addSteps(stepsToAdd);
        }

        this.shepherdSvc.start();
    }
}
