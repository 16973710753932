import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import gsap, { Linear, Power3, Sine } from 'gsap';
import { RoughEase } from 'gsap/EasePack';
const { timeline } = gsap;

@Component({
    selector: 'app-rocket',
    templateUrl: './rocket.component.html'
})
export class RocketComponent implements OnInit, OnChanges, AfterViewInit {
    @Input()
    public height = 128;
    @Input()
    public ratio = 4 / 3;
    @Input()
    public doAnimate = false;

    @ViewChild('rocket')
    public rocket: ElementRef;
    @ViewChild('rocketFire')
    public rocketFire: ElementRef;
    @ViewChild('rocketBody')
    public rocketBody: ElementRef;
    @ViewChild('cloud1')
    public cloud1: ElementRef;
    @ViewChild('cloud2')
    public cloud2: ElementRef;

    public styles: {
        height: string,
        width: string,
        '--mad-height': string
    };
    public isReady = false;

    constructor() {
        gsap.registerPlugin(RoughEase);
    }

    public ngOnInit(): void {
        this.styles = {
            height: `${this.height}px`,
            width: `${this.height * this.ratio}px`,
            '--mad-height': `${this.height}`
        };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.doAnimate.currentValue && !changes.doAnimate.previousValue) {
            this.animate();
        }
    }

    public ngAfterViewInit(): void {
        this.prepare();
    }

    private animate() {
        const rocket = this.rocket.nativeElement;
        const rocketFire = this.rocketFire.nativeElement;
        const rocketBody = this.rocketBody.nativeElement;
        const cloud1 = this.cloud1.nativeElement;
        const cloud2 = this.cloud2.nativeElement;

        const tlCloud1 = () =>
            timeline({ repeat: 0 })
                .to(cloud1, {
                    alpha: 0.8,
                    scale: 0.5,
                    x: '-=10',
                    ease: Sine.easeOut,
                    duration: 1,
                })
                .to(cloud1, {
                    alpha: 0,
                    scale: 1.5,
                    x: '-=10',
                    ease: Sine.easeOut,
                    duration: 8,
                });
        const tlCloud2 = () =>
            timeline({ repeat: 0 })
                .to(cloud2, {
                    alpha: 0.8,
                    scale: 0.5,
                    x: '+=10',
                    ease: Sine.easeOut,
                    duration: 1,
                })
                .to(cloud2, {
                    alpha: 0,
                    scale: 1.5,
                    x: '+=10',
                    ease: Sine.easeOut,
                    duration: 8,
                });
        const tlRocketFire = () =>
            timeline({ repeat: 0 })
                .fromTo(
                    rocketFire,
                    { scaleX: 0, scaleY: 0 },
                    {
                        scaleX: 0.8,
                        scaleY: 1.3,
                        ease: Power3.easeIn,
                        duration: 0.2,
                    },
                )
                .add(
                    timeline({ repeat: -1 }).to(rocketFire, {
                        scaleX: 0.9,
                        scaleY: 1.4,
                        yoyo: true,
                        ease: RoughEase.ease.config({
                            strength: 4,
                            points: 8,
                            template: Linear.easeNone,
                            randomize: false,
                        }),
                    }),
                );
        const tlRocketBody = () =>
            timeline({ repeat: -1 }).fromTo(
                rocketBody,
                { x: -1 },
                {
                    x: 1,
                    ease: RoughEase.ease.config({
                        strength: 4,
                        points: 8,
                        template: Linear.easeNone,
                        randomize: false,
                    }),
                },
            );
        const tlRocketLiftOff = () =>
            timeline({ repeat: 0 }).to(rocket, {
                y: -window.innerHeight,
                ease: Power3.easeIn,
                duration: 4,
            });
        const tl = timeline({ repeat: 0 });
        tl.add([tlRocketBody(), tlRocketFire(), tlCloud1(), tlCloud2()])
            .add([tlRocketLiftOff()], '-=7')
            .call(
                () => {
                    tl.kill();
                },
                null,
                '+=3',
            );
    }

    private prepare(): void {
        const rocket = this.rocket.nativeElement;
        const rocketFire = this.rocketFire.nativeElement;
        const rocketBody = this.rocketBody.nativeElement;
        const cloud1 = this.cloud1.nativeElement;
        const cloud2 = this.cloud2.nativeElement;

        gsap.set([cloud1, cloud2], {
            alpha: 0,
            scale: 0,
            transformOrigin: '50% 100%',
        });
        gsap.set(rocket, {
            y: 5,
            transformOrigin: '50% 100%',
        });
        gsap.set(rocketFire, {
            scale: 0,
            transformOrigin: '50% 0%',
        });
        gsap.set(rocketBody, { transformOrigin: '50% 75%' });
        this.isReady = true;
    }
}
