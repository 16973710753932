export enum ProjectStatus {
    Open = 'Open',
    LightOfferCreated = 'LightOfferCreated',
    OfferOverviewCreated = 'OfferOverviewCreated',
    FinancingConfirmationRequested = 'FinancingConfirmationRequested',
    PreliminaryCheckInquiryCreated = 'PreliminaryCheckInquiryCreated',
    FinancingConfirmationRejected = 'FinancingConfirmationRejected',
    FinancingConfirmationReceived = 'FinancingConfirmationReceived',
    OffersBound = 'OffersBound',
    RejectedByCompetenceCenter = 'RejectedByCompetenceCenter',
    PreliminaryCheckInquiryReleased = 'PreliminaryCheckInquiryReleased',
    CompetenceCenterCheck = 'CompetenceCenterCheck',
    AdditionalDocumentsRequestedByCC = 'AdditionalDocumentsRequestedByCC',
    SubmittedToLenders = 'SubmittedToLenders',
    AllOffersReceived = 'AllOffersReceived',
    OfferSelected = 'OfferSelected',
    InterestRateFixingRequested = 'InterestRateFixingRequested',
    InterestRatePartiallyFixed = 'InterestRatePartiallyFixed',
    TrancheInterestRateFixingRequested = 'TrancheInterestRateFixingRequested',
    InterestRateFixed = 'InterestRateFixed',
    RejectedByLenders = 'RejectedByLenders',
    RejectedByAdvisor = 'RejectedByAdvisor',
    Closed = 'Closed',
}
