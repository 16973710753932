import { Component } from '@angular/core';
import { MAIN_ROUTES } from '../../constants';
import { RouterService } from '../../core/services/router.service';
import { DiagnosticsService } from '../../diagnostics/services/diagnostics.service';

@Component({
    templateUrl: './unmatched-route.component.html'
})
export class UnmatchedRouteComponent {
    constructor(private readonly diagnosticsSvc: DiagnosticsService,
                private readonly routerSvc: RouterService) {
        this.diagnosticsSvc.log({ key: 'unmatchedRoute', value: location.href });
        void this.routerSvc.navigateToRoute([MAIN_ROUTES.PROJECTS]);
    }
}
