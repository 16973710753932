import { Pipe, PipeTransform } from '@angular/core';
import { IDropdownOption } from 'sfx-commons';
import { SafeAny } from '../../constants';

@Pipe({
    name: 'selectedDropdownItem'
})
export class SelectedDropdownItemPipe implements PipeTransform {
    public transform<T extends IDropdownOption<SafeAny>>(values: Array<T>, value: SafeAny): T {
        if (!value || !values?.length) {
            return value;
        }

        return values.find(v => v.value === value);
    }
}
