export class LocationUtils {
    public static parseQueryString(): { [key: string]: { [key: string]: string } } {
        if (window.location.search?.length) {
            const navigationExtras: { [key: string]: string } = {};
            window.location.search.substring(1).split('&').map(item => {
                const keyValue = item.split('=');
                navigationExtras[keyValue[0]] = keyValue[1];
            });

            return { queryParams: navigationExtras };
        }

        return null;
    }
}