import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DefaultEmptyLenderId } from '../../constants';
import { IAssignDocument } from '../../projects/models/IAssignDocument';
import { DocumentsService } from '../../projects/services/documents.service';

@UntilDestroy()
@Directive({
    selector: '[appScrollToDocumentGroup]'
})
export class ScrollToDocumentGroupDirective implements OnInit {
    @Input({ required: true })
    public parentSelector: string;

    constructor(protected readonly documentSvc: DocumentsService,
                protected readonly elementRef: ElementRef) {}

    public ngOnInit(): void {
        this.documentSvc.makeDocumentGroupVisibleObservable
            .pipe(untilDestroyed(this))
            .subscribe((documentGroupData: IAssignDocument) => {
                if (this.isCurrentDocumentGroup(documentGroupData)) {
                    void this.scrollToDocumentGroup();
                }
            });
    }

    protected get documentGroup(): HTMLElement {
        return this.elementRef.nativeElement as HTMLElement;
    }

    protected get documentGroupParent(): HTMLElement {
        return document.querySelector(this.parentSelector) as HTMLElement;
    }

    protected isCurrentDocumentGroup(documentGroupData: IAssignDocument): boolean {
        if (!documentGroupData) {
            return false;
        }
        return this.getDocumentGroupId(documentGroupData) === this.documentGroup.id;
    }

    protected getDocumentGroupId(documentGroupData: IAssignDocument): string {
        return `groupType-${ documentGroupData.group }-lenderId-${ documentGroupData.lenderId || DefaultEmptyLenderId }`;
    }

    protected async scrollToDocumentGroup(waitUntilElementIsFullyVisibleCallback?: () => Promise<void>): Promise<void> {
        const parentDOMRect = this.documentGroupParent?.getBoundingClientRect();
        const documentGroupDOMRect = this.documentGroup?.getBoundingClientRect();
        if (parentDOMRect && documentGroupDOMRect) {
            if (documentGroupDOMRect.top < parentDOMRect.top) {
                if (waitUntilElementIsFullyVisibleCallback) {
                    await waitUntilElementIsFullyVisibleCallback();
                }
                this.documentGroup.scrollIntoView();
            } else if (parentDOMRect.bottom < documentGroupDOMRect.bottom) {
                if (waitUntilElementIsFullyVisibleCallback) {
                    await waitUntilElementIsFullyVisibleCallback();
                }
                this.documentGroup.scrollIntoView(false);
            }
        }
    }
}
