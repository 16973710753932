import { Directive, HostBinding, Inject, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { DEFAULT_NUMERIC_MASK } from 'sfx-commons';
import { InputComponent, INTERFACE_TOKEN, NullableMaskedNumber } from 'sfx-commons';
import { CustomInputDirective } from './customInput.directive';

@Directive({
    selector: '[appPositiveInput]'
})
export class PositiveNumberDirective extends CustomInputDirective implements OnInit, OnChanges {
    @HostBinding('class')
    public elementClass = 'number-input-align-right';

    public override mask = new NullableMaskedNumber(DEFAULT_NUMERIC_MASK);
    public override customInputValidation = (event) => event.key !== '-';

    constructor(@Inject(INTERFACE_TOKEN) @Self() @Optional() public override readonly host: InputComponent) {
        super(host);
    }

    public override ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.mask) {
            if (this.mask?.mask === Number && !(this.mask instanceof NullableMaskedNumber)) {
                this.mask = new NullableMaskedNumber(this.mask);
            }
        }
    }
}
