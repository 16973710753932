import { Pipe, ChangeDetectorRef, PipeTransform, OnDestroy, NgZone } from '@angular/core';
import * as DateFns from 'date-fns';
import { DateFnsLocales, SUPPORTED_LANGUAGE } from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';

@Pipe({ name: 'sfxTimeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private currentTimer: number | null;

    private lastTime: number;
    private lastValue: Date;
    private lastLocale: SUPPORTED_LANGUAGE;
    private lastText: string;

    constructor(private readonly cdr: ChangeDetectorRef,
                private readonly ngZone: NgZone,
                private readonly madTranslateSvc: MadTranslateService) {}

    public ngOnDestroy(): void {
        this.removeTimer();
    }

    public transform(value: string | Date): string {
        value = value instanceof Date ? value : new Date(value);

        if (this.hasChanged(value)) {
            this.lastTime = this.getTime(value);
            this.lastValue = (value instanceof Date) ? value : new Date(value);
            this.lastLocale = this.madTranslateSvc.getSelectedLanguage();
            this.removeTimer();
            this.createTimer();
            this.lastText = this.format(value);
        } else {
            this.createTimer();
        }

        return this.lastText;
    }

    private createTimer(): void {
        if (this.currentTimer) {
            return;
        }

        const timeToUpdate = this.getSecondsUntilUpdate(this.lastValue) * 1000;

        this.currentTimer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    this.lastText = this.format(this.lastValue);

                    this.currentTimer = null;
                    this.ngZone.run(() => this.cdr.markForCheck());
                }, timeToUpdate);
            } else {
                return null;
            }
        });
    }

    private format(date: Date) {
        return DateFns.intlFormatDistance(date, new Date(), {
            locale: DateFnsLocales[this.getLocale()].code
        });
    }

    private getSecondsUntilUpdate(date: Date): number {
        const howOld = Math.abs(DateFns.differenceInMinutes(new Date(), date));
        if (howOld < 1) {
            return 1;
        } else if (howOld < 60) {
            return 30;
        } else if (howOld < 180) {
            return 300;
        } else {
            return 3600;
        }
    }

    private getLocale(): SUPPORTED_LANGUAGE {
        return this.madTranslateSvc.getSelectedLanguage();
    }

    private getTime(date: Date): number {
        return new Date(date).getTime();
    }

    private hasChanged(value: Date): boolean {
        return (this.getTime(value) !== this.lastTime || this.getLocale() !== this.lastLocale);
    }

    private removeTimer(): void {
        if (this.currentTimer) {
            window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }
}