import { ISelectOption, IUIControlOption } from 'sfx-commons';
import { CivilStatus } from '../projects/models/enums/civilStatus';
import { Gender } from '../projects/models/enums/gender';
import { HousingSituation } from '../projects/models/enums/housingSituation';
import { PensionType } from '../projects/models/enums/pensionType';
import { PropertyLaw } from '../projects/models/enums/propertyLaw';
import { ProsecutionHistory } from '../projects/models/enums/prosecutionHistory';
import { ResidencePermit } from '../projects/models/enums/residencePermit';
import { SelfEmploymentType } from '../projects/models/enums/selfEmploymentType';
import { TypeOfIncome } from '../projects/models/enums/typeOfIncome';

export const BOOLEAN_SELECT_OPTIONS: Array<IUIControlOption<boolean>> = [
    { value: true, label: 'global.yes' },
    { value: false, label: 'global.no' }
];

export const CIVIL_STATUS_OPTIONS: Array<ISelectOption<CivilStatus>> = [
    { value: CivilStatus.Single, label: `edit_borrower.personal_details.civil_status.${CivilStatus.Single}` },
    { value: CivilStatus.Married, label: `edit_borrower.personal_details.civil_status.${CivilStatus.Married}` },
    { value: CivilStatus.InRegisteredPartnership, label: `edit_borrower.personal_details.civil_status.${CivilStatus.InRegisteredPartnership}` },
    { value: CivilStatus.Divorced, label: `edit_borrower.personal_details.civil_status.${CivilStatus.Divorced}` },
    { value: CivilStatus.Separated, label: `edit_borrower.personal_details.civil_status.${CivilStatus.Separated}` },
    { value: CivilStatus.Widowed, label: `edit_borrower.personal_details.civil_status.${CivilStatus.Widowed}` }
];

export const PROPERTY_LAW_OPTIONS: Array<ISelectOption<PropertyLaw>> = [
    { value: PropertyLaw.CommunityOfAcquisitions, label: `edit_borrower.personal_details.property_law.${PropertyLaw.CommunityOfAcquisitions}` },
    { value: PropertyLaw.SeparationOfProperty, label: `edit_borrower.personal_details.property_law.${PropertyLaw.SeparationOfProperty}` },
    { value: PropertyLaw.CommunityOfProperty, label: `edit_borrower.personal_details.property_law.${PropertyLaw.CommunityOfProperty}` },
    { value: PropertyLaw.Contract, label: `edit_borrower.personal_details.property_law.${PropertyLaw.Contract}` },
    { value: PropertyLaw.NotKnown, label: `edit_borrower.personal_details.property_law.${PropertyLaw.NotKnown}` }
];

export const GENDER_TITLE_SELECT_OPTIONS: Array<IUIControlOption<Gender>> = [
    { value: Gender.Male, label: 'edit_borrower.personal_details.gender_male' },
    { value: Gender.Female, label: 'edit_borrower.personal_details.gender_female' }
];

export const PENSION_TYPE_OPTIONS: Array<ISelectOption<PensionType>> = [
    {
        value: PensionType.Pillar,
        label: `collateral.pension_type.${PensionType.Pillar}`
    },
    {
        value: PensionType.VestedBenefitsPolicy,
        label: `collateral.pension_type.${PensionType.VestedBenefitsPolicy}`
    },
    {
        value: PensionType.VestedBenefitsAccount,
        label: `collateral.pension_type.${PensionType.VestedBenefitsAccount}`
    },
    {
        value: PensionType.BankBalances,
        label: `collateral.pension_type.${PensionType.BankBalances}`
    },
    {
        value: PensionType.ClassicPolicy3A,
        label: `collateral.pension_type.${PensionType.ClassicPolicy3A}`
    },
    {
        value: PensionType.FundPolicyWithGuarantee3A,
        label: `collateral.pension_type.${PensionType.FundPolicyWithGuarantee3A}`
    },
    {
        value: PensionType.FundPolicyWithoutGuarantee3A,
        label: `collateral.pension_type.${PensionType.FundPolicyWithoutGuarantee3A}`
    },
    {
        value: PensionType.ClassicPolicy3B,
        label: `collateral.pension_type.${PensionType.ClassicPolicy3B}`
    },
    {
        value: PensionType.FundPolicyWithGuarantee3B,
        label: `collateral.pension_type.${PensionType.FundPolicyWithGuarantee3B}`
    },
    {
        value: PensionType.FundPolicyWithoutGuarantee3B,
        label: `collateral.pension_type.${PensionType.FundPolicyWithoutGuarantee3B}`
    }
];

export const PENSION_TYPE_OPTIONS_3B: Array<ISelectOption<PensionType>> = [
    {
        value: PensionType.ClassicPolicy3B,
        label: `collateral.pension_type.${PensionType.ClassicPolicy3B}`
    },
    {
        value: PensionType.FundPolicyWithGuarantee3B,
        label: `collateral.pension_type.${PensionType.FundPolicyWithGuarantee3B}`
    },
    {
        value: PensionType.FundPolicyWithoutGuarantee3B,
        label: `collateral.pension_type.${PensionType.FundPolicyWithoutGuarantee3B}`
    }
];

export const PROSECUTION_HISTORY_OPTIONS: Array<{ value: ProsecutionHistory, label: string }> = [
    { value: ProsecutionHistory.None, label: ProsecutionHistory.None },
    { value: ProsecutionHistory.AllDoneAndOlderThan2Years, label: ProsecutionHistory.AllDoneAndOlderThan2Years },
    { value: ProsecutionHistory.LatestEntriesLessThan2Years, label: ProsecutionHistory.LatestEntriesLessThan2Years },
    { value: ProsecutionHistory.Further, label: ProsecutionHistory.Further },
    {
        value: ProsecutionHistory.AllDoneAndOlderThan2YearsAndUnder500CHF,
        label: ProsecutionHistory.AllDoneAndOlderThan2YearsAndUnder500CHF
    },
    {
        value: ProsecutionHistory.AllDoneAndOlderThan2YearsAndUnder1000CHF,
        label: ProsecutionHistory.AllDoneAndOlderThan2YearsAndUnder1000CHF
    },
];

export const RESIDENCE_PERMIT_OPTIONS: Array<ISelectOption<ResidencePermit>> = [
    { value: ResidencePermit.PermanentResidencePermitC, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.PermanentResidencePermitC },
    { value: ResidencePermit.ResidencePermitB, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.ResidencePermitB },
    { value: ResidencePermit.AsylumSeekersN, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.AsylumSeekersN },
    { value: ResidencePermit.ProvisionallyAdmittedForeigner, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.ProvisionallyAdmittedForeigner },
    { value: ResidencePermit.ShortTermResidencePermitL, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.ShortTermResidencePermitL },
    { value: ResidencePermit.PeopleInNeedOfProtectionS, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.PeopleInNeedOfProtectionS },
    { value: ResidencePermit.CrossBorderCommuterG, label: 'edit_borrower.personal_details.residence_permit_options.' +
            ResidencePermit.CrossBorderCommuterG },
];

export const SELF_EMPLOYMENT_OPTIONS: Array<{ value: SelfEmploymentType, label: string }> = [
    { value: SelfEmploymentType.ThreeYears, label: SelfEmploymentType.ThreeYears },
    { value: SelfEmploymentType.FiveYears, label: SelfEmploymentType.FiveYears }
];

export const TYPE_OF_INCOME_OPTIONS: Array<ISelectOption<TypeOfIncome>> = [
    {
        value: TypeOfIncome.Employed,
        label: `edit_borrower.employment_type.${TypeOfIncome.Employed}`
    },
    {
        value: TypeOfIncome.PaidPerHour,
        label: `edit_borrower.employment_type.${TypeOfIncome.PaidPerHour}`
    },
    {
        value: TypeOfIncome.SelfEmployed,
        label: `edit_borrower.employment_type.${TypeOfIncome.SelfEmployed}`
    },
    {
        value: TypeOfIncome.Unemployed,
        label: `edit_borrower.employment_type.${TypeOfIncome.Unemployed}`
    },
    {
        value: TypeOfIncome.Pensioner,
        label: `edit_borrower.employment_type.${TypeOfIncome.Pensioner}`
    }
];

export const HOUSING_SITUATION_OPTIONS: Array<{ label: string, value: HousingSituation }> = [
    {
        label: `edit_borrower.liabilities.housing_situation.${HousingSituation.Residence}`,
        value: HousingSituation.Residence
    },
    {
        label: `edit_borrower.liabilities.housing_situation.${HousingSituation.RentedProperty}`,
        value: HousingSituation.RentedProperty
    },
    {
        label: `edit_borrower.liabilities.housing_situation.${HousingSituation.WithOtherBorrower}`,
        value: HousingSituation.WithOtherBorrower
    },
];
