import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ROUTE_ID } from '../../constants/api-routes';
import { UserRole } from '../../core/models/enums/userRole';
import { HttpService } from '../../core/services/http.service';
import { IListableAdvisor } from '../models/IListableAdvisor';
import { IGetAllAdvisorsFilter } from '../models/IGetAllAdvisorsFilter';
import { IListablePagedResult } from '../models/IListablePagedResult';

@Injectable({
    providedIn: 'root'
})
export class AdvisorService {
    private readonly supportersSubject: BehaviorSubject<Array<IListableAdvisor>>;

    constructor(private readonly httpSvc: HttpService) {
        this.supportersSubject = new BehaviorSubject<Array<IListableAdvisor>>(undefined);
    }

    public clearCache(): void {
        this.supportersSubject.next(undefined);
    }

    public async getAdvisors(projectId: number): Promise<Array<IListableAdvisor>> {
        return await this.httpSvc.get<Array<IListableAdvisor>>(null, ROUTE_ID.ADVISORS, { projectId });
    }

    public async getAllAdvisors(queryParams: IGetAllAdvisorsFilter = { skip: 0, take: 20 }, isCancellable = true): Promise<IListablePagedResult<IListableAdvisor>> {
        return await this.httpSvc.getWithQuery<IListablePagedResult<IListableAdvisor>>(ROUTE_ID.ALL_ADVISORS, queryParams, null, null, null, isCancellable);
    }

    public async getSupporters(): Promise<Array<IListableAdvisor>> {
        const cachedSupporters = this.supportersSubject.getValue();
        if (cachedSupporters) {
            return cachedSupporters;
        }

        const supporters = await this.getAdvisorsByRole(UserRole.Supporter);
        this.supportersSubject.next(supporters);

        return supporters;
    }

    public async getAdvisorsByRole(userRole: UserRole): Promise<Array<IListableAdvisor>> {
        return await this.httpSvc.get<Array<IListableAdvisor>>(null, ROUTE_ID.ADVISORS_BY_ROLE, {
            userRole
        });
    }

    public async getAdvisorsForTrainee(): Promise<Array<IListableAdvisor>> {
        return await this.httpSvc.get<Array<IListableAdvisor>>(null, ROUTE_ID.ADVISORS_FOR_TRAINEE);
    }
}