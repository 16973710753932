import { Pipe, PipeTransform } from '@angular/core';
import { TextEndingWithParenthesesRegex } from '../../constants';

@Pipe({
    name: 'extractEndingParentheses'
})
export class ExtractEndingParenthesesPipe implements PipeTransform {
    public transform(text: string): string {
        if (!text || !TextEndingWithParenthesesRegex.test(text)) {
            return text;
        }

        const matches = TextEndingWithParenthesesRegex.exec(text);
        const textWithoutParentheses = matches[1].trim();
        const textInParentheses = matches[2];

        return `${ textWithoutParentheses } <span class="parentheses-text">(${ textInParentheses })</span>`;
    }
}