import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appSetHeightOfBodyToCurrentElement]',
})
export class SetHeightOfBodyToCurrentElementDirective implements AfterViewInit, OnDestroy {
    private resizeObserver: ResizeObserver;

    constructor(private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        this.setUpResizeObserver();
    }

    public ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private async setHeightOfBody(): Promise<void> {
        const body = document.getElementsByTagName('body')[0];
        body.style.maxHeight = null;
        body.style.minHeight = null;

        setTimeout(() => {
            const valueToSet = `${ this.elementRef.nativeElement.offsetHeight }px`;
            body.style.maxHeight = valueToSet;
            body.style.minHeight = valueToSet;
        });
    }

    private setUpResizeObserver(): void {
        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(async () => {
                await this.setHeightOfBody();
            });
            this.resizeObserver.observe(document.documentElement);
            this.resizeObserver.observe(this.elementRef.nativeElement);
        }
    }
}