import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { calculateMutations, isNullOrUndefined } from 'sfx-commons';
import { ROUTE_ID } from '../../constants/api-routes';

import { HttpService } from '../../core/services/http.service';
import { ModalUtils } from '../../core/utils/modal.utils';
import { BorrowerType } from '../models/enums/borrowerType';
import { BusinessType } from '../models/enums/businessType';
import { ObjectType } from '../models/enums/objectType';
import { ProjectStatus } from '../models/enums/projectStatus';
import { ICopyProjectDataPayload } from '../models/ICopyProjectDataPayload';
import { IGetLatestModifiedProjectsFilter } from '../models/IGetLatestModifiedProjectsFilter';
import { IListableProject } from '../models/IListableProject';
import { IListablePagedResult } from '../models/IListablePagedResult';
import { IProject } from '../models/IProject';
import { IProjectStatus } from '../models/IProjectStatus';
import { IRevokeProjectData } from '../models/IRevokeProjectData';
import { IUnsupportedPartnersPerBusinessType } from '../models/IUnsupportedPartnersPerBusinessType';
import { AuthorizationService } from './authorization.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    public allProjectStatuses: Observable<Array<IProjectStatus>>;
    public cachedProjectsObservable: Observable<Array<IProject>>;
    public openedProjectObservable: Observable<IProject>;
    public openedProjectWithoutDescriptionObservable: Observable<IProject>;
    public projectToImportObservable: Observable<IListableProject>;
    public listedProjectVersionObservable: Observable<{ previousProjectId: number, newProject: IListableProject }>;
    public signalAccessLostToOpenedProjectObservable: Observable<void>;

    private allProjectStatusesSubject = new BehaviorSubject<Array<IProjectStatus>>(null);
    private allProjectStatusesHttpRequestPromise: Promise<Array<IProjectStatus>> = null;
    private cachedProjectsSubject = new BehaviorSubject<Array<IProject>>([]);
    private listableProjects: IListablePagedResult<IListableProject>;
    private openedProjectSubject = new BehaviorSubject<IProject>(null);
    private openedProjectWithoutDescriptionSubject = new BehaviorSubject<IProject>(null);
    private projectToImportSubject = new BehaviorSubject<IListableProject>(null);
    private listedProjectVersionSubject = new BehaviorSubject<{ previousProjectId: number, newProject: IListableProject }>(null);
    private unsupportedPartnersPerBusinessTypeSubject = new BehaviorSubject<Array<IUnsupportedPartnersPerBusinessType>>(null);
    private signalAccessLostToOpenedProjectSubject = new Subject<void>();
    private readonly defaultLatestModifiedProjectsFilter: IGetLatestModifiedProjectsFilter = {
        skip: 0,
        take: 5
    };

    constructor(private readonly authorizationSvc: AuthorizationService,
                private readonly httpService: HttpService,
                private readonly modalSvc: NzModalService,
                private readonly translate: TranslateService) {
        this.cachedProjectsObservable = this.cachedProjectsSubject.asObservable();
        this.openedProjectObservable = this.openedProjectSubject.asObservable();
        this.openedProjectWithoutDescriptionObservable = this.openedProjectWithoutDescriptionSubject.asObservable();
        this.allProjectStatuses = this.allProjectStatusesSubject.asObservable();
        this.projectToImportObservable = this.projectToImportSubject.asObservable();
        this.listedProjectVersionObservable = this.listedProjectVersionSubject.asObservable();
        this.signalAccessLostToOpenedProjectObservable = this.signalAccessLostToOpenedProjectSubject.asObservable();
    }

    public clearCache(): void {
        this.allProjectStatusesSubject.next(undefined);
        this.cachedProjectsSubject.next(undefined);
        this.openedProjectSubject.next(undefined);
        this.openedProjectWithoutDescriptionSubject.next(undefined);
        this.projectToImportSubject.next(undefined);
        this.listedProjectVersionSubject.next(undefined);
        this.unsupportedPartnersPerBusinessTypeSubject.next(undefined);
        this.listableProjects = undefined;
    }

    public async getLatestModifiedProjects(queryParams: IGetLatestModifiedProjectsFilter = this.defaultLatestModifiedProjectsFilter, isCancellable = false): Promise<IListablePagedResult<IListableProject>> {
        const response = await this.httpService
            .getWithQuery<IListablePagedResult<IListableProject>>(ROUTE_ID.LATEST_MODIFIED_PROJECTS, queryParams, null, null, null, isCancellable);

        if (!this.listableProjects || !response.skip) {
            this.listableProjects = response;
        } else {
            const listableProjectsList = this.listableProjects.data;
            this.listableProjects = {
                ...response,
                data: [...listableProjectsList, ...response.data]
            };
        }

        return this.listableProjects;
    }

    public cancelGetLatestModifiedProjects(): void {
        this.httpService.cancelRequest(ROUTE_ID.LATEST_MODIFIED_PROJECTS, 'GET');
    }

    public async bindOffersForProject(id: number, payload: { language: string }): Promise<IProject> {
        const project = await this.httpService.patch<IProject>(ROUTE_ID.OFFERS_BIND, {}, {
            id,
            ...payload
        }, null, null, true);

        this.updateProjectInCache(project);
        return project;
    }

    public cancelBindOffersForProject(): void {
        this.httpService.cancelRequest(ROUTE_ID.OFFERS_BIND, 'PATCH');
    }

    public async cloneProject(id: number): Promise<IProject> {
        const clone = await this.httpService
            .post<IProject>(ROUTE_ID.CLONE_PROJECT, {}, { id });

        this.addProjectToCache(clone, true);
        return clone;
    }

    public async copyProjectData(payload: ICopyProjectDataPayload): Promise<IProject> {
        const copy = await this.httpService
            .post<IProject>(ROUTE_ID.COPY_PROJECT_DATA, {}, payload);

        this.addProjectToCache(copy);
        return copy;
    }

    public async createProject(title: string, businessType: BusinessType, objectType: ObjectType, lenderId?: number, ownerMail?: string): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.CREATE_PROJECT, {}, {
            title,
            businessType,
            objectType,
            lenderId,
            ownerMail
        });

        this.addProjectToCache(project);
        return project;
    }

    public async acceptOffer(projectId: number, id: number): Promise<IProject> {
        const project = await this.httpService.patch<IProject>(ROUTE_ID.OFFER_ACCEPT, undefined,
            { projectId, id });

        this.updateProjectInCache(project);
        return project;
    }

    public async cancelAcceptedOfferForProject(id: number, executeBeforeUpdatingProject?: () => Promise<void>): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.OFFERS_CANCEL_ACCEPTED_OFFER, {}, { id });

        if (executeBeforeUpdatingProject) {
            await executeBeforeUpdatingProject();
        }

        this.updateProjectInCache(project);
        return project;
    }

    public async deleteProject(id: number): Promise<boolean> {
        const response = await this.httpService
            .delete<boolean>(ROUTE_ID.PROJECT_BY_ID, {}, { id });

        this.removeProjectFromCache(id);
        return response;
    }

    public async fixInterestRateForProject(id: number, interestRateFixedMessage: string): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.OFFERS_FIX_INTEREST_RATE, {}, {
            id,
            interestRateFixedMessage
        });

        this.updateProjectInCache(project);
        return project;
    }

    public getCachedProject(id: number): IProject {
        const cachedProjects = this.cachedProjectsSubject.getValue();
        const cachedProject = cachedProjects
            .find((currentProject: IProject) => currentProject.id === id);

        this.notifyOpenedProjectObservers(cachedProject);
        return cachedProject;
    }

    public async getProject(id: number, doNotify = true, actionBeforeNotification?: (project: IProject) => void, isCancellable = false): Promise<IProject> {
        const project = await this.httpService.get<IProject>(null, ROUTE_ID.PROJECT_BY_ID, { id }, null, null, isCancellable);

        if (actionBeforeNotification) {
            actionBeforeNotification(project);
        }

        if (doNotify) {
            this.updateProjectInCache(project);
        }
        return project;
    }

    public async getListableProjectById(id: number): Promise<IListableProject> {
        return await this.httpService.get<IListableProject>(null, ROUTE_ID.LISTABLE_PROJECT_BY_ID, { id });
    }

    public async getAllProjectStatuses(): Promise<Array<IProjectStatus>> {
        if (!isNullOrUndefined(this.allProjectStatusesHttpRequestPromise)) {
            return await this.allProjectStatusesHttpRequestPromise;
        } else {
            try {
                this.allProjectStatusesHttpRequestPromise = this.httpService
                    .get<Array<IProjectStatus>>(null, ROUTE_ID.ALL_PROJECT_STATUSES);
                const allProjectStatuses = await this.allProjectStatusesHttpRequestPromise;
                this.allProjectStatusesHttpRequestPromise = null;
                this.allProjectStatusesSubject.next(allProjectStatuses);
                return allProjectStatuses;
            } catch (ex) {
                this.allProjectStatusesHttpRequestPromise = null;
                return Promise.reject(ex);
            }
        }
    }

    public async getProjectStatuses(id: number): Promise<Array<IProjectStatus>> {
        return await this.httpService.get<Array<IProjectStatus>>(null, ROUTE_ID.PROJECT_STATUSES_BY_ID, { id });
    }

    public getOpenedProject(): IProject {
        return this.openedProjectSubject.getValue();
    }

    public doesOpenedProjectNeedActionBeforeLeaving(): boolean {
        const project = this.getOpenedProject();
        return project && (project.projectStatus === ProjectStatus.OffersBound || project.projectStatus === ProjectStatus.PreliminaryCheckInquiryCreated);
    }

    public async markAllDocumentsAsCompleted(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.DOCUMENTS_COMPLETE, {}, {
            id
        });

        this.updateProjectInCache(project);
        return project;
    }

    public resetOpenedProject(): void {
        const project = this.getOpenedProject();
        if (project) {
            this.notifyOpenedProjectObservers(null);
        }
    }

    public async rejectProject(id: number, payload: IRevokeProjectData): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.REJECT_PROJECT, {}, { id, ...payload });

        this.updateProjectInCache(project);
        return project;
    }

    public async requestFinancingConfirmationForProject(id: number, payload: { language: string, withObjectData: boolean }): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.PROJECT_REQUEST_FINANCING_CONFIRMATION, {}, {
            id,
            ...payload
        });

        this.updateProjectInCache(project);
        return project;
    }

    public async requestFixableOfferForProject(id: number): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.OFFERS_REQUEST_FIXABLE_OFFER, {}, { id });

        this.updateProjectInCache(project);
        return project;
    }

    public async resetProjectStatusDueToIncompleteData(id: number): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.RESET_PROJECT_STATUS_DUE_TO_INCOMPLETE_DATA, {}, { id });

        this.updateProjectInCache(project);
        return project;
    }

    public shallowRemoveProjectFromCache(projectId: number) {
        this.removeProjectFromCache(projectId, true);
    }

    public async startCompetenceCenterCheck(id: number): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.START_COMPETENCE_CENTER_CHECK, {}, { id });

        this.updateProjectInCache(project);
        return project;
    }

    public async submitDocumentsToLenders(id: number, requestId?: string): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.SUBMIT_DOCUMENTS_TO_LENDERS, {}, { id, requestId });

        this.updateProjectInCache(project);
        return project;
    }

    public async submitDocumentsToServiceCenter(id: number): Promise<IProject> {
        const project = await this.httpService
            .post<IProject>(ROUTE_ID.SUBMIT_DOCUMENTS_TO_SERVICE_CENTER, {}, { id });

        this.updateProjectInCache(project);
        return project;
    }

    public async isOfferBindingCancellationPossibleForProject(id: number): Promise<boolean> {
        return await this.httpService.get<boolean>(null, ROUTE_ID.IS_OFFER_BINDING_CANCELLATION_POSSIBLE, { id });
    }

    public updateProjectInCache(project: IProject, onlyDescriptionChanged = false): void {
        if (project) {
            const cachedProjects = [...this.cachedProjectsSubject.getValue()];
            const projectIndex = cachedProjects.findIndex(currentProject => currentProject.id === project.id);
            if (projectIndex < 0) {
                cachedProjects.push(project);
            } else {
                const mutations = calculateMutations(project, cachedProjects[projectIndex]);
                if (mutations.length) {
                    cachedProjects[projectIndex] = project;
                }
            }

            this.notifyProjectsObservers(project, cachedProjects, onlyDescriptionChanged);
        } else {
            this.notifyOpenedProjectObservers(project, onlyDescriptionChanged);
        }
    }

    public async updateDescription(id: number, description: string): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.UPDATE_PROJECT_DESCRIPTION, { id }, { description });
        this.updateProjectInCache(project, true);
        return project;
    }

    public getBorrowerNamesArray(project: IListableProject): Array<string> {
        if (!project || project.borrowersType === BorrowerType.Individual && !project.borrowers?.length
            || project.borrowersType === BorrowerType.LegalEntity && !project.legalEntities?.length) {
            return null;
        }
        const borrowersNames = [];

        if (project.borrowersType === BorrowerType.Individual) {
            project.borrowers
                .map(borrower => {
                    if (borrower.firstName || borrower.lastName) {
                        borrowersNames.push(`${ borrower.firstName || '' } ${ borrower.lastName || '' }`.trim());
                    }
                });
        } else if (project.borrowersType === BorrowerType.LegalEntity) {
            project.legalEntities
                .map(borrower => {
                    if (borrower.name) {
                        borrowersNames.push(borrower.name.trim());
                    }
                });
        }
        return borrowersNames;
    }

    public async canSelectProjectForImport(project: IListableProject): Promise<boolean> {
        const currentSelection = this.projectToImportSubject.getValue();
        if (currentSelection) {
            let confirmationPromise: Promise<boolean>;
            if (project) {
                confirmationPromise = ModalUtils.createOverwriteProjectImportConfirmation(this.modalSvc, this.translate).isConfirmedPromise;
            } else {
                confirmationPromise = ModalUtils.createCancelProjectImportConfirmation(this.modalSvc, this.translate).isConfirmedPromise;
            }
            if (await confirmationPromise) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    public selectProjectForImport(project: IListableProject): void {
        this.projectToImportSubject.next(project);
    }

    public async changeListedProjectVersion(previousProjectVersionId: number, selectedProjectVersionId: number): Promise<void> {
        const currentSelection = this.projectToImportSubject.getValue();
        if (currentSelection?.id === previousProjectVersionId) {
            const { isConfirmedPromise } = ModalUtils.createOverwriteProjectImportConfirmation(this.modalSvc, this.translate);
            if (await isConfirmedPromise) {
                const selectedProject = await this.getListableProjectById(selectedProjectVersionId);
                this.listedProjectVersionSubject.next({
                    previousProjectId: previousProjectVersionId,
                    newProject: selectedProject
                });
                this.projectToImportSubject.next(selectedProject);
            }
        } else {
            const selectedProject = await this.getListableProjectById(selectedProjectVersionId);
            this.listedProjectVersionSubject.next({
                previousProjectId: previousProjectVersionId,
                newProject: selectedProject
            });
        }
    }

    public async getUnsupportedPartnersPerBusinessType(): Promise<Array<IUnsupportedPartnersPerBusinessType>> {
        const currentUnsupportedPartnersPerBusinessType = this.unsupportedPartnersPerBusinessTypeSubject.getValue();
        if (currentUnsupportedPartnersPerBusinessType) {
            return currentUnsupportedPartnersPerBusinessType;
        }

        const unsupportedPartnersPerBusinessType = await this.httpService.get<Array<IUnsupportedPartnersPerBusinessType>>(null, ROUTE_ID.UNSUPPORTED_PARTNERS_PER_BUSINESS_TYPE);
        this.unsupportedPartnersPerBusinessTypeSubject.next(unsupportedPartnersPerBusinessType);

        return unsupportedPartnersPerBusinessType;
    }

    public async changeProjectOwnerToCurrentUser(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.CHANGE_PROJECT_OWNER, { id });

        this.updateProjectInCache(project);
        return project;
    }

    public signalAccessLostToOpenedProject(): void {
        this.signalAccessLostToOpenedProjectSubject.next();
    }

    private addProjectToCache(project: IProject, isCloning = false): void {
        const cachedProjects = [...this.cachedProjectsSubject.getValue()];
        if (isCloning) {
            for (const version of project.projectVersions) {
                const indexOfVersion = cachedProjects.findIndex(p => p.id === version.projectId);
                if (indexOfVersion > -1) {
                    cachedProjects[indexOfVersion].projectVersions = [...project.projectVersions];
                }
            }
        }

        cachedProjects.unshift(project);

        this.notifyProjectsObservers(project, cachedProjects);
    }

    private notifyOpenedProjectObservers(project: IProject, onlyDescriptionChanged = false) {
        const cachedProject = this.openedProjectSubject.getValue();
        const mutations = calculateMutations(project, cachedProject);

        if (mutations.length) {
            if (mutations.findIndex(mutation => mutation.path.includes('/permissions')) > -1 || !project) {
                this.authorizationSvc.setOpenedProjectPermissionMap(project);
            }

            this.openedProjectSubject.next(project);
            if (!onlyDescriptionChanged) {
                this.openedProjectWithoutDescriptionSubject.next(project);
            }
        }
    }

    private notifyCachedProjectsObservers(cachedProjects: Array<IProject>) {
        this.cachedProjectsSubject.next(cachedProjects);
    }

    private notifyProjectsObservers(project: IProject, cachedProjects: Array<IProject>, onlyDescriptionChanged = false): void {
        this.notifyCachedProjectsObservers(cachedProjects);
        this.notifyOpenedProjectObservers(project, onlyDescriptionChanged);
    }

    /**
     * Removes a project from cache
     * @param deletedProjectId - the id of the project to be removed from cache
     * @param doNotDeleteFromVersions - if true, it only performs a soft delete, as in, it only removes the project from
     * the cached projects array, but not each project versions' list
     **/
    private removeProjectFromCache(deletedProjectId: number, doNotDeleteFromVersions = false): void {
        const cachedProjects = [...this.cachedProjectsSubject.getValue()];
        const projectIndexInCache = cachedProjects.findIndex(p => p.id === deletedProjectId);
        if (projectIndexInCache > -1) {
            cachedProjects.splice(projectIndexInCache, 1);

            if (!doNotDeleteFromVersions) {
                cachedProjects.map(project => {
                    const indexOfVersion = project.projectVersions.findIndex(p => p.projectId === deletedProjectId);
                    if (indexOfVersion > -1) {
                        project.projectVersions.splice(indexOfVersion, 1);
                    }
                });
            }

            this.notifyCachedProjectsObservers(cachedProjects);
        }
    }
}
