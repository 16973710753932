import { Pipe, PipeTransform } from '@angular/core';
import { OUTLET_NAMES } from '../../constants';
import { RouterService } from '../../core/services/router.service';

@Pipe({
    name: 'translateOutlet',
})
export class TranslateOutletPipe implements PipeTransform {
    constructor(private readonly routerSvc: RouterService) {}

    public transform(outlet: { [key: string]: string }, params?: Array<number | string>): { [key: string]: string } {
        // eslint-disable-next-line prefer-const
        let { outletName, pathName } = this.routerSvc.translateOutlet(Object.keys(outlet)[0] as OUTLET_NAMES);
        params?.map(param => pathName += `/${param}`);

        return { [outletName]: pathName };
    }
}
