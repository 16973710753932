import { Injectable } from '@angular/core';
import { JsonUtils } from '../utils/json.utils';
import { ObjectUtils } from '../utils/object.utils';

export enum LOCAL_STORAGE_KEY {
    LANGUAGE = 'LANGUAGE',
    LOCALIZE_DEFAULT_LANGUAGE = 'LOCALIZE_DEFAULT_LANGUAGE',

    SSO_AUTH_TOKEN = 'SSO_AUTH_TOKEN',
    SSO_AUTH_TOKEN_EXPIRATION = 'SSO_AUTH_TOKEN_EXPIRATION',
    SSO_REFRESH_TOKEN = 'SSO_REFRESH_TOKEN',
    SSO_REFRESH_TOKEN_EXPIRATION = 'SSO_REFRESH_TOKEN_EXPIRATION',

    DFS_AUTH_TOKEN = 'DFS_AUTH_TOKEN',
    DFS_AUTH_TOKEN_EXPIRATION = 'DFS_AUTH_TOKEN_EXPIRATION',
    DFS_REFRESH_TOKEN = 'DFS_REFRESH_TOKEN',
    DFS_REFRESH_TOKEN_EXPIRATION = 'DFS_REFRESH_TOKEN_EXPIRATION',

    USER = 'USER',

    SSO_ROOT_REDIRECT_URI = 'SSO_ROOT_REDIRECT_URI',
    SSO_NONCE = 'SSO_NONCE',
    SSO_NAME = 'SSO_NAME',
    SSO_ACTIVE_ID = 'SSO_ACTIVE_ID',

    IS_ANONYMIZED = 'IS_ANONYMIZED',

    LAST_READ_NEWS = 'LAST_READ_NEWS',
    WALKTHROUGH_DEMO_TOUR_SEEN = 'WALKTHROUGH_DEMO_TOUR_SEEN',

    DIAGNOSTIC_LOGS = 'DIAGNOSTIC_LOGS',
    DIAGNOSTIC_LOGS_ENABLED_DATE = 'DIAGNOSTIC_LOGS_ENABLED_DATE',
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    public get<T>(key: LOCAL_STORAGE_KEY): T {
        const item = localStorage.getItem(key);
        return JsonUtils.parseText(item) as T;
    }

    public set(key: LOCAL_STORAGE_KEY, content: unknown): void {
        let valueToSet;
        if (ObjectUtils.isObjectOrArray(content)) {
            valueToSet = JSON.stringify(content);
        } else {
            valueToSet = content;
        }

        localStorage.setItem(key, valueToSet);
    }

    public remove(key: LOCAL_STORAGE_KEY): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
