import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { MOMENT_DATE_FORMATS_PER_LANGUAGE, OUTLET_NAMES, SafeAny } from '../../constants';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { RouterService } from '../../core/services/router.service';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { NewsStatus } from '../../projects/models/enums/newsStatus';
import { INews } from '../../projects/models/INews';
import { NewsService } from '../../projects/services/news.service';

@Component({
    selector: 'app-news-modal',
    templateUrl: './news-modal.component.html',
    styleUrls: ['./news-modal.component.scss']
})
export class NewsModalComponent implements OnInit {
    public news: Array<INews> = [];

    public dateFormat: SafeAny;

    public readonly NewsStatus = NewsStatus;
    public readonly bellIcon = faBell;

    constructor(private activatedRoute: ActivatedRoute,
                private localStorageSvc: LocalStorageService,
                private madTranslateSvc: MadTranslateService,
                private newsSvc: NewsService,
                private routerSvc: RouterService) {
        this.dateFormat = MOMENT_DATE_FORMATS_PER_LANGUAGE[this.madTranslateSvc.getSelectedLanguage()][0];
    }

    public async ngOnInit() {
        this.localStorageSvc.set(LOCAL_STORAGE_KEY.LAST_READ_NEWS, new Date());
        this.newsSvc.updateUnreadNewsStatus(false);
        this.news = await this.newsSvc.getNews();
    }

    public hideModal = async () => {
        await this.routerSvc.closeOutlet(OUTLET_NAMES.NEWS_MODAL_OUTLET, { relativeTo: this.activatedRoute.parent, queryParamsHandling: 'preserve' });
    };

    public onNewsEntryClick(newsEntry: INews, isExpandable: boolean): void {
        if (isExpandable) {
            newsEntry.isExpanded = !newsEntry.isExpanded;
        }
    }
}
