import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appSetUpOfferListFooterPositioning]',
})
export class SetUpOfferListFooterPositioningDirective implements AfterViewInit, OnDestroy {
    private child: HTMLElement;
    private resizeObserver: ResizeObserver;
    private style: CSSStyleDeclaration = {
        bottom: 'calc(var(--project-page-container-margin) + var(--project-container-border-width))'
    } as CSSStyleDeclaration;
    private readonly fixedPositionClass = 'mad-offers-list__footer--position-fixed';

    constructor(private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        this.setUpResizeObserverForChild();
    }

    public ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    @HostListener('window:scroll')
    public onScrollEvent() {
        this.setParentChildProps();
    }

    private setParentChildProps(): void {
        const html = document.documentElement;
        const htmlBoundingRect = html.getBoundingClientRect();

        if (html.scrollHeight > htmlBoundingRect.height) {
            let visualViewportOffsetLeft = 0;
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                visualViewportOffsetLeft = window.visualViewport.offsetLeft;
            }

            this.child.classList.add(this.fixedPositionClass);

            const {
                width: childWidthToBe,
                left: childLeftToBe
            } = this.elementRef.nativeElement.getBoundingClientRect();

            const { height: parentHeightToBe } = ((this.elementRef.nativeElement as HTMLElement)
                .children[0] as HTMLElement).getBoundingClientRect();

            this.child.style.left = `${visualViewportOffsetLeft + childLeftToBe}px`;
            this.child.style.width = `calc(${childWidthToBe}px + 2 * var(--project-container-border-width))`;
            this.child.style.bottom = this.style.bottom;

            this.elementRef.nativeElement.style.height =
                `calc(${parentHeightToBe}px - var(--project-container-border-width))`;
        } else {
            if (this.child.classList.contains(this.fixedPositionClass)) {
                this.child.classList.remove(this.fixedPositionClass);
            }
            this.child.removeAttribute('style');
            this.elementRef.nativeElement.removeAttribute('style');
        }
    }

    private setUpResizeObserverForChild(): void {
        this.child = (this.elementRef.nativeElement as HTMLElement).children[0] as HTMLElement;

        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(() => {
                this.setParentChildProps();
            });
            this.resizeObserver.observe(this.elementRef.nativeElement);
            this.resizeObserver.observe(this.child);
        }
    }
}