import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';

@Pipe({
    name: 'capitalizeFirstWord',
})
export class CapitalizeFirstWordPipe implements PipeTransform {
    public transform(value: string): SafeAny {
        return value ? value.capitalizeFirstWord() : '';
    }
}
