import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { LANGUAGE_FILE_NAME_SUFFIX, SUPPORTED_LANGUAGE } from '../../constants';
import { ISupportedLanguagePack } from '../models/i-supported-language-pack';

export class LocaleUtils {
    public static getSupportedLanguages(): ISupportedLanguagePack {
        const configSupportedLanguages = environment.supportedLanguages;
        const supportedLanguages: { [language: string]: SUPPORTED_LANGUAGE } = {};
        const languageFileNameSuffixes: { [language: string]: string } = {};

        configSupportedLanguages
            .map(language => {
                if (language && SUPPORTED_LANGUAGE[language]) {
                    if (!supportedLanguages[language]) {
                        supportedLanguages[language] = SUPPORTED_LANGUAGE[language] as SUPPORTED_LANGUAGE;
                    }
                    if (!languageFileNameSuffixes[language]) {
                        languageFileNameSuffixes[language] = LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE[language]];
                    }
                }
            });

        if (!Object.keys(supportedLanguages).length) {
            return {
                supportedLanguages: {
                    [SUPPORTED_LANGUAGE.DE_CH]: SUPPORTED_LANGUAGE.DE_CH
                },
                languageFileNameSuffixes: {
                    [SUPPORTED_LANGUAGE.DE_CH]: LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.DE_CH]
                }
            };
        }

        return {
            supportedLanguages,
            languageFileNameSuffixes
        };
    }

    public static setLanguage(translateSvc: TranslateService, language: SUPPORTED_LANGUAGE | string): void {
        const languageFileToUse = LANGUAGE_FILE_NAME_SUFFIX[language];

        if (translateSvc && languageFileToUse) {
            translateSvc.use(languageFileToUse);
        }
    }
}
