import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';
import { isNumber } from 'sfx-commons';

@Pipe({
    name: 'byteToMegabyte'
})
export class ByteToMegabytePipe implements PipeTransform {
    public transform(value: SafeAny): SafeAny {
        if (!value || !isNumber(value)) {
            return value;
        }

        return value / 1048576;
    }
}
