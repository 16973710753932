import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdentityProvider } from '../constants';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../core/services/session-storage.service';
import { AuthenticationService } from '../projects/services/authentication.service';

@Component({
    selector: 'app-login-azure',
    templateUrl: './login-azure.component.html',
    styleUrls: ['./login-azure.component.scss']
})
export class LoginAzureComponent implements OnInit {
    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly authSvc: AuthenticationService,
                private readonly sessionStorageSvc: SessionStorageService) {}

    public async ngOnInit(): Promise<void> {
        await this.loginAzure();
    }

    private loginAzure = async () => {
        const returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl;
        if (returnUrl) {
            this.sessionStorageSvc.set(SESSION_STORAGE_KEY.RETURN_URL_KEY, this.activatedRoute.snapshot.queryParams.returnUrl);
        } else {
            this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.RETURN_URL_KEY);
        }
        await this.authSvc.login(IdentityProvider.Azure);
    };
}
