<ng-template #prolongNotificationTpl>
    <ng-container [ngTemplateOutlet]="standardNotificationTpl"
                  [ngTemplateOutletContext]="{
                    timestamp: userInactivityLogoutTimestamp || logoutTimestamp,
                    canCollapseNotification: true,
                    textKey: 'auth.prolong_session.logout_in_x',
                    prolongActionKey: 'auth.prolong_session.prolong_session'
                  }"
    ></ng-container>
</ng-template>
<ng-template #inactivityNotificationTpl>
    <ng-container [ngTemplateOutlet]="standardNotificationTpl"
                  [ngTemplateOutletContext]="{
                    timestamp: userInactivityLogoutTimestamp,
                    textKey: 'auth.user_inactivity.logout_in_x'
                  }"
    ></ng-container>
</ng-template>

<ng-template #standardNotificationTpl
             let-timestamp="timestamp"
             let-canCollapseNotification="canCollapseNotification"
             let-textKey="textKey"
             let-prolongActionKey="prolongActionKey"
>
    <div class="ant-notification-notice-content standard-notification">
        <div class="ant-notification-notice-with-icon">
            <span class="ant-notification-notice-icon">
                <sfx-icon [icon]="falTimer" size="m" class="standard-notification__icon"> </sfx-icon>
            </span>

            <div class="ant-notification-notice-message standard-notification__timer"
                 [ngClass]="{ 'mad-padding-right-0': !canCollapseNotification}">
                {{ textKey | translate: { x: timestamp | sfxTime | async } }}
            </div>

            <div *ngIf="canCollapseNotification"
                 class="ant-notification-notice-close standard-notification__close mad-margin-top-2">
                <sfx-button type="icon" iconButtonSize="s" [icon]="falArrowLeft"
                            (click)="removeStandardProlongNotification()">
                </sfx-button>
            </div>

            <div *ngIf="prolongActionKey" class="ant-notification-notice-description">
                <sfx-button class="standard-notification__prolong-session-button mad-margin-left-n2" type="text"
                            [label]="prolongActionKey"
                            (clicked)="onProlongSessionClicked()">
                </sfx-button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #shortProlongNotificationTpl>
    <div class="short-notification" (click)="removeShortProlongNotification()">
        <sfx-icon [icon]="hourglassIcon" size="l" class="fa-shake short-notification__hourglass-icon"></sfx-icon>

        <div class="short-notification__timer">
            {{ userInactivityLogoutTimestamp || logoutTimestamp | sfxTime | async }}
        </div>
    </div>
</ng-template>

<ng-template #closeIconTpl></ng-template>