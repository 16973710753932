import { Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appSetHeightOfOffersPageVerticalLine]'
})
export class SetHeightOfOffersPageVerticalLineDirective implements OnDestroy {
    private readonly verticalLineSelector = '.icon-td__vertical-line';
    private readonly observer: ResizeObserver;

    constructor(private elementRef: ElementRef) {
        this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
            entries.forEach((entry) => this.setHeightOfVerticalLines(entry.target as HTMLElement)));
        this.observer.observe(this.elementRef.nativeElement);
    }

    public ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    private findElementsForTableRow(tableRow: HTMLElement): { line: HTMLElement, nextElement: HTMLElement } {
        const line = tableRow.querySelector(this.verticalLineSelector) as HTMLElement;
        const nextElement = tableRow.nextElementSibling as HTMLElement;

        return { line, nextElement };
    }

    private setHeightOfVerticalLines(table: HTMLElement): void {
        table.querySelectorAll('tr').forEach(row => {
            const { line, nextElement } = this.findElementsForTableRow(row);

            if (line && nextElement) {
                const elementHeight = row.getBoundingClientRect().height;
                const nextElementHeight = nextElement.getBoundingClientRect().height;

                line.style.height = `calc(${ (elementHeight + nextElementHeight) / 2 }px - 4rem)`;
            }
        });
    }
}