<sfx-modal [isVisible]="true"
           [title]="modalTitle"
           wrapClassName="service-center-modal"
           extraModalClasses="service-modal"
           (cancelClicked)="closeModal()"
>
    <ng-template #modalTitle>
        <sfx-title-bar [icon]="userHeadsetIcon"
                       [isModalTitleBar]="true"
                       [isDataInputInModal]="false"
                       titleKey="service_center.title"
                       [hasCloseButton]="true"
                       (closeClicked)="closeModal()"
        ></sfx-title-bar>
    </ng-template>
    <article class="mad-article mad-padding-16">
        <div class="mad-title mad-title--s" [innerHTML]="'service_center.do_you_have_questions' | translate"></div>
        <div class="mad-list mad--vertical contact-list">
            <sfx-button size="large"
                        dress="primaryLight"
                        [icon]="phoneOfficeIcon"
                        label="service_center.phone_number"
                        (clicked)="openCallPhoneNumber()"
            ></sfx-button>
            <sfx-button size="large"
                        dress="primaryLight"
                        [icon]="paperPlaneIcon"
                        [label]="email"
                        (clicked)="openSendEmail()"
            ></sfx-button>
        </div>
        <p class="mad--tabular mad-padding-top-16" [innerHTML]="'service_center.opening_hours' | translate"></p>
        <sfx-section-separator></sfx-section-separator>
        <p [innerHTML]="'service_center.contact' | translate"></p>
    </article>
</sfx-modal>
