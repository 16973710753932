import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer, Route } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { NewsModalComponent } from './common-components/news-modal/news-modal.component';
import { ServiceCenterModalComponent } from './common-components/service-center-modal/service-center-modal.component';
import { SettingsModalComponent } from './common-components/settings-modal/settings-modal.component';
import { UnmatchedRouteComponent } from './common-components/unmatched-route/unmatched-route.component';
import {
    LANGUAGE_FILE_NAME_SUFFIX,
    MAIN_ROUTES,
    OUTLET_NAMES,
    SIDEBAR_OUTLET_PATHS,
    SUPPORTED_LANGUAGE
} from './constants';
import { canAuthenticate } from './core/guards/authenticated.guard';
import { canActivateApp } from './core/guards/is-app-initialized.guard';
import { canActivateNextRoute } from './core/guards/unauthenticated.guard';
import { AppInitializerService } from './core/services/app-initializer.service';
import { Key4TrainingCompletedComponent } from './key4-training-completed/key4-training-completed.component';
import { LanguageBaseComponent } from './language-base.component';
import { LoginAzureComponent } from './login-azure/login-azure.component';
import { CleanUrlSerializer } from './projects/edit-project/utils/clean-url-serializer';

export function generateAppRoutes(): Routes {
    const isEnglishSupported = environment.supportedLanguages.includes(SUPPORTED_LANGUAGE.EN);
    const fallbackLanguage = isEnglishSupported ? SUPPORTED_LANGUAGE.EN : environment.supportedLanguages[0];

    return [
        ...environment.supportedLanguages.map((language: string): Route => ({
                path: LANGUAGE_FILE_NAME_SUFFIX[language],
                component: LanguageBaseComponent,
                canActivate: [canActivateApp],
                children: [
                    {
                        path: AppInitializerService.asyncRoutes[language][MAIN_ROUTES.PROJECTS],
                        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
                        canActivate: [canAuthenticate],
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.PROJECTS]
                    }, {
                        path: MAIN_ROUTES.LOGGING_IN,
                        loadChildren: () => import('./logging-in/logging-in.module').then((m) => m.LoggingInModule),
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGGING_IN]
                    }, {
                        path: MAIN_ROUTES.LOGIN,
                        canActivate: [canActivateNextRoute],
                        pathMatch: 'full',
                        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGIN]
                    }, {
                        path: MAIN_ROUTES.LOGIN_BACKOFFICE,
                        component: LoginAzureComponent,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGIN_BACKOFFICE]
                    }, {
                        path: MAIN_ROUTES.KEY4_TRAINING_COMPLETED,
                        canActivate: [canAuthenticate],
                        component: Key4TrainingCompletedComponent,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.KEY4_TRAINING_COMPLETED]
                    }, {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: AppInitializerService.asyncRoutes[language][MAIN_ROUTES.PROJECTS]
                    }
                ]
            })
        ), {
            path: '',
            pathMatch: 'full',
            redirectTo: `${ LANGUAGE_FILE_NAME_SUFFIX[fallbackLanguage] }/${ MAIN_ROUTES.LOGIN }`
        },
        {
            path: SIDEBAR_OUTLET_PATHS.SERVICE_CENTER,
            component: ServiceCenterModalComponent,
            outlet: OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET,
        },
        {
            path: SIDEBAR_OUTLET_PATHS.SETTINGS,
            component: SettingsModalComponent,
            outlet: OUTLET_NAMES.SETTINGS_MODAL_OUTLET,
        },
        {
            path: SIDEBAR_OUTLET_PATHS.NEWS,
            component: NewsModalComponent,
            outlet: OUTLET_NAMES.NEWS_MODAL_OUTLET,
        },
        {
            path: MAIN_ROUTES.LOGIN,
            pathMatch: 'full',
            redirectTo: `${ LANGUAGE_FILE_NAME_SUFFIX[fallbackLanguage] }/${ MAIN_ROUTES.LOGIN }`
        }, {
            path: '**',
            pathMatch: 'full',
            component: UnmatchedRouteComponent
        }
    ];
}

@NgModule({
    imports: [
        TranslateModule,
        RouterModule.forRoot([], { paramsInheritanceStrategy: 'always' })
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: UrlSerializer,
            useClass: CleanUrlSerializer
        }
    ]
})
export class AppModuleRouting {}
