import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { generateAppRoutes } from '../../app.module.routing';
import { LANGUAGE_FILE_NAME_SUFFIX } from '../../constants';
import { TranslateFallbackHttpLoader } from '../../mad-translate/mad-translate.module';

@Injectable()
export class AppInitializerService {
    public static asyncRoutes = {};
    public static pageTitles = {};
    public areRoutesLoaded: Observable<object>;
    private areRoutesLoadedSubject = new BehaviorSubject<object>(null);

    constructor(private readonly http: HttpClient,
                private readonly router: Router) {
        this.areRoutesLoaded = this.areRoutesLoadedSubject.asObservable();
    }

    public getLoadedRoutes(): object {
        return this.areRoutesLoadedSubject.getValue();
    }

    public async init(): Promise<void> {
        const routesPerLanguage = {};
        const pageTitlesPerLanguage = {};
        const translateLoader = new TranslateFallbackHttpLoader(this.http, environment.translationsUrl);
        await Promise.all(environment.supportedLanguages.map(async (language) => {
            let results;
            try {
                results = await firstValueFrom(translateLoader.getTranslation(LANGUAGE_FILE_NAME_SUFFIX[language]));
            } catch (ex) {
                console.error(ex);
            }
            routesPerLanguage[language] = results.routes;
            pageTitlesPerLanguage[language] = this.generatePageTitles(results['page_titles']);
        }));

        AppInitializerService.asyncRoutes = routesPerLanguage;
        AppInitializerService.pageTitles = pageTitlesPerLanguage;
        this.areRoutesLoadedSubject.next(routesPerLanguage);
        const routes = generateAppRoutes();
        this.router.resetConfig(routes);
    }

    private generatePageTitles(plainPageTitles: Record<string, string>): Record<string, string> {
        const pageTitles: Record<string, string> = {};

        for (const term in plainPageTitles) {
            pageTitles[term] = `${ environment.title } | ${ plainPageTitles[term] }`;
        }

        return pageTitles;
    }
}
