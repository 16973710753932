export class ObjectUtils {
    public static getKeyForValue<Type>(object: unknown, value: unknown): Type {
        if (!object || !value) {
            return null;
        }

        const keys = Object.keys(object);
        for (const key of keys) {
            if (object[key] === value) {
                return key as unknown as Type;
            }
        }
        return null;
    }

    public static isObjectOrArray(value: unknown): boolean {
        return value === Object(value);
    }
}
