import { Directive, ElementRef, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAssignDocument } from '../../projects/models/IAssignDocument';
import { DocumentsService } from '../../projects/services/documents.service';
import { ScrollToDocumentGroupDirective } from './scroll-to-document-group.directive';

@UntilDestroy()
@Directive({
    selector: '[appScrollToCollapsibleDocumentGroup]'
})
export class ScrollToCollapsibleDocumentGroupDirective extends ScrollToDocumentGroupDirective implements OnInit {

    constructor(protected override readonly documentSvc: DocumentsService,
                protected override readonly elementRef: ElementRef) {
        super(documentSvc, elementRef);
    }

    public override ngOnInit(): void {
        this.documentSvc.makeDocumentGroupVisibleObservable
            .pipe(untilDestroyed(this))
            .subscribe(async (documentGroupData: IAssignDocument) => {
                if (this.isCurrentDocumentGroup(documentGroupData)) {
                    await this.scrollToDocumentGroup(this.waitUntilElementIsFullyVisible);
                }
            });
    }

    private waitUntilElementIsFullyVisible = (): Promise<void> => {
        return new Promise<void>((resolve) => {
            const collapsibleContentBox = this.documentGroup?.parentElement;
            const collapsibleContent = collapsibleContentBox?.parentElement;

            if (!collapsibleContentBox || !collapsibleContent) {
                resolve();
                return;
            }

            const collapsibleContentHeight = () => {
                const collapsibleContentBoxStyle = getComputedStyle(collapsibleContentBox);
                const collapsibleContentStyle = getComputedStyle(collapsibleContent);
                return collapsibleContentBox.offsetHeight +
                    +collapsibleContentBoxStyle.marginTop.split('px')[0] +
                    +collapsibleContentBoxStyle.marginBottom.split('px')[0] +
                    +collapsibleContentStyle.paddingTop.split('px')[0] +
                    +collapsibleContentStyle.paddingBottom.split('px')[0];
            };

            if (collapsibleContent.clientHeight === collapsibleContentHeight()) {
                resolve();
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                if (collapsibleContent.clientHeight === collapsibleContentHeight()) {
                    resizeObserver.disconnect();
                    resolve();
                }
            });
            resizeObserver.observe(collapsibleContent);
        });
    };
}
