import { Injectable } from '@angular/core';
import { AdvisorService } from '../../projects/services/advisor.service';
import { ProjectsService } from '../../projects/services/projects.service';

@Injectable({
    providedIn: 'root'
})
export class LogoutImplicationsService {
    constructor(private readonly advisorSvc: AdvisorService, private readonly projectsSvc: ProjectsService) {}

    public clearCache(): void {
        this.advisorSvc.clearCache();
        this.projectsSvc.clearCache();
    }
}