export enum PensionType {
    Pillar = 'Pillar',
    VestedBenefitsPolicy = 'VestedBenefitsPolicy',
    VestedBenefitsAccount = 'VestedBenefitsAccount',
    BankBalances = 'BankBalances',
    ClassicPolicy3A = 'ClassicPolicy3A',
    FundPolicyWithGuarantee3A = 'FundPolicyWithGuarantee3A',
    FundPolicyWithoutGuarantee3A = 'FundPolicyWithoutGuarantee3A',
    ClassicPolicy3B = 'ClassicPolicy3B',
    FundPolicyWithGuarantee3B = 'FundPolicyWithGuarantee3B',
    FundPolicyWithoutGuarantee3B = 'FundPolicyWithoutGuarantee3B'
}
