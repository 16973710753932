export enum OfferStatus {
    Possible = 'Possible',
    Knockout = 'Knockout',
    SelectedByAdvisor = 'SelectedByAdvisor',
    Bound = 'Bound',
    AmendedByLender = 'AmendedByLender',
    AcceptedByLender = 'AcceptedByLender',
    RejectedByLender = 'RejectedByLender',
    AcceptedByCustomer = 'AcceptedByCustomer',
    WaitingForInterestRateFixing = 'WaitingForInterestRateFixing',
    InterestRatePartiallyFixed = 'InterestRatePartiallyFixed',
    WaitingForTrancheRateFixing = 'WaitingForTrancheRateFixing',
    InterestRateFixed = 'InterestRateFixed',
    RejectedByAdvisor = 'RejectedByAdvisor'
}
