import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CleanUrlSerializer extends DefaultUrlSerializer {
    public override parse(url: string): UrlTree {
        if (url.includes(':')) {
            return super.parse(url);
        } else {
            const newStr = url.replace(/\(|\)/g, '');
            return super.parse(newStr);
        }
    }

    public override serialize(tree: UrlTree): string {
        const url = super.serialize(tree);
        if (url.includes(':')) {
            return url;
        } else {
            return url.replace(/\(|\)/g, '');
        }
    }
}
