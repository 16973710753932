import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IsDateBeforePipe } from '../../common-utils/pipes/is-date-before.pipe';
import { ROUTE_ID } from '../../constants/api-routes';
import { HttpService } from '../../core/services/http.service';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { NewsStatus } from '../models/enums/newsStatus';
import { INews } from '../models/INews';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    public hasUnreadNewsObservable: Observable<boolean>;

    private hasUnreadNewsSubject = new BehaviorSubject(false);
    private news: Array<INews> = [];

    constructor(private httpService: HttpService, private storage: LocalStorageService) {
        this.hasUnreadNewsObservable = this.hasUnreadNewsSubject.asObservable();
    }

    public updateUnreadNewsStatus(status: boolean): void {
        this.hasUnreadNewsSubject.next(status);
    }

    public async getNews(): Promise<Array<INews>> {
        try {
            if (!this.news?.length) {
                const news = await this.httpService.get<Array<INews>>(null, ROUTE_ID.GET_NEWS);
                if (news?.length) {
                    this.news = news;
                    if (!!this.news.some(news => news.status === NewsStatus.Published)) {
                        const hasUnreadNews = new IsDateBeforePipe()
                            .transform(this.storage.get(LOCAL_STORAGE_KEY.LAST_READ_NEWS), this.news[0].publishedAt);
                        this.hasUnreadNewsSubject.next(hasUnreadNews);
                    }
                }
            }

            return this.news;
        } catch (e) {
            return null;
        }
    }
}
