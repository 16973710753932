<ng-container *ngIf="formGroup">
    <ng-container *ngIf="!hasSidebarLayout; else sidebarLayout">
        <div class="language-picker">
            <div class="language" *ngFor="let language of supportedLanguages">
                <sfx-button type="text"
                            [label]="language.label"
                            (clicked)="setLanguage(language.value)"
                ></sfx-button>
            </div>
        </div>
    </ng-container>
    <ng-template #sidebarLayout>
        <sfx-field label="settings.language" layout="horizontal" type="select">
            <sfx-select class="language-picker-select"
                        [control]="formGroup.controls.uiLanguage"
                        [options]="supportedLanguages"></sfx-select>
        </sfx-field>
    </ng-template>
</ng-container>
