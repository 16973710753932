import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[appScrollToBottom]'
})
export class ScrollToBottomDirective {
    @Input()
    public triggerPercent = 80;
    @Output()
    public scrolledToBottom = new EventEmitter<void>();

    @HostListener('scroll', ['$event'])
    public onScroll(event: Event): void {
        const element = event.target as HTMLElement;
        let scrolledDistance = Math.round(element.scrollTop) + element.clientHeight;
        if (this.triggerPercent === 100) {
            scrolledDistance += 5;
        }
        if (scrolledDistance >= element.scrollHeight * this.triggerPercent / 100) {
            this.scrolledToBottom.emit();
        }
    }

}
