export enum ObjectType {
    Condominium = 'Condominium',
    SingleFamilyHouse = 'SingleFamilyHouse',
    RentalCondominium = 'RentalCondominium',
    RentalHouse = 'RentalHouse',
    HolidayCondominium = 'HolidayCondominium',
    HolidayHouse = 'HolidayHouse',
    InvestmentProperty = 'InvestmentProperty',
    Unknown = 'Unknown',
}
