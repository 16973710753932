import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerivedRole } from '../../core/models/enums/derivedRole';
import { GlobalPermission } from '../../core/models/enums/globalPermission';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { IRoleAndPermissionShortcuts } from '../../core/models/IRoleAndPermissionShortcuts';
import { ProjectPermission } from '../models/enums/projectPermission';
import { IProject } from '../models/IProject';
import { IRolesAndPermissions } from '../models/IRolesAndPermissions';

type BooleanRecord<T extends DerivedRole | GlobalPermission | ProjectPermission> = Record<T, boolean>;

type RoleAndGlobalPermissionMaps = {
    roleMap: BooleanRecord<DerivedRole>;
    globalPermissionMap: BooleanRecord<GlobalPermission>;
}

type RoleAndPermissionMapsCombined = RoleAndGlobalPermissionMaps & {
    openedProjectPermissionMap: BooleanRecord<ProjectPermission>;
}

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService implements IRoleAndPermissionShortcuts {
    public readonly roleAndGlobalPermissionMapsObservable: Observable<RoleAndGlobalPermissionMaps>;
    public readonly openedProjectPermissionMapObservable: Observable<BooleanRecord<ProjectPermission>>;
    public readonly roleAndPermissionMapsCombinedObservable: Observable<RoleAndPermissionMapsCombined>;

    private readonly roleAndGlobalPermissionMapsSubject: BehaviorSubject<RoleAndGlobalPermissionMaps>;
    private readonly openedProjectPermissionMapSubject: BehaviorSubject<BooleanRecord<ProjectPermission>>;

    private readonly INITIAL_ROLE_AND_GLOBAL_PERMISSION_MAPS: RoleAndGlobalPermissionMaps = {
        roleMap: undefined,
        globalPermissionMap: undefined
    };

    constructor() {
        this.roleAndGlobalPermissionMapsSubject = new BehaviorSubject<RoleAndGlobalPermissionMaps>(this.INITIAL_ROLE_AND_GLOBAL_PERMISSION_MAPS);
        this.openedProjectPermissionMapSubject = new BehaviorSubject<BooleanRecord<ProjectPermission>>(undefined);

        this.roleAndGlobalPermissionMapsObservable = this.roleAndGlobalPermissionMapsSubject.asObservable();
        this.openedProjectPermissionMapObservable = this.openedProjectPermissionMapSubject.asObservable();

        this.roleAndPermissionMapsCombinedObservable = combineLatest([this.roleAndGlobalPermissionMapsObservable, this.openedProjectPermissionMapObservable]).pipe(
            map(roleAndPermissionMaps => {
                if (!roleAndPermissionMaps) {
                    return {
                        ...this.INITIAL_ROLE_AND_GLOBAL_PERMISSION_MAPS,
                        openedProjectPermissionMap: undefined
                    };
                }

                return {
                    roleMap: roleAndPermissionMaps[0]?.roleMap,
                    globalPermissionMap: roleAndPermissionMaps[0]?.globalPermissionMap,
                    openedProjectPermissionMap: roleAndPermissionMaps[1]
                };
            }));
    }

    public get roleMap(): BooleanRecord<DerivedRole> {
        return this.roleAndGlobalPermissionMapsSubject.getValue()?.roleMap;
    }

    public get globalPermissionMap(): BooleanRecord<GlobalPermission> {
        return this.roleAndGlobalPermissionMapsSubject.getValue()?.globalPermissionMap;
    }

    public get openedProjectPermissionMap(): BooleanRecord<ProjectPermission> {
        return this.openedProjectPermissionMapSubject.getValue();
    }

    public get isAdmin(): boolean {
        return !!this.roleMap?.Admin;
    }

    public get isSfxAdmin(): boolean {
        return !!this.roleMap?.SfxAdmin;
    }

    public get isSupporter(): boolean {
        return !!this.roleMap?.Supporter;
    }

    public get isUser(): boolean {
        return !!this.roleMap?.User;
    }

    public get isTrainee(): boolean {
        return !!this.roleMap?.Trainee;
    }

    public setRoleAndGlobalPermissionMaps(user: IApplicationUser): void {
        if (user) {
            const roleAndPermissionMaps = this.getRoleAndGlobalPermissionMaps(user);
            this.roleAndGlobalPermissionMapsSubject.next(roleAndPermissionMaps);
        } else {
            this.roleAndGlobalPermissionMapsSubject.next(this.INITIAL_ROLE_AND_GLOBAL_PERMISSION_MAPS);
        }
    }

    public setOpenedProjectPermissionMap(project: IProject): void {
        if (project) {
            const permissionMap: Partial<BooleanRecord<ProjectPermission>> = {};

            Object.values(ProjectPermission).forEach(permission => {
                permissionMap[permission] = !!project.permissions?.includes(permission);
            });

            this.openedProjectPermissionMapSubject.next(permissionMap as BooleanRecord<ProjectPermission>);
        } else {
            this.openedProjectPermissionMapSubject.next(undefined);
        }
    }

    public hasRole(role: DerivedRole): boolean {
        return this.hasEntity(role, this.roleMap);
    }

    public hasAnyRole(roles: Array<DerivedRole>): boolean {
        return this.hasEntities(roles, this.roleMap);
    }

    public hasEveryRole(roles: Array<DerivedRole>): boolean {
        return this.hasEntities(roles, this.roleMap, true);
    }

    public hasPermission(permission: GlobalPermission | ProjectPermission): boolean {
        return this.hasEntity(permission, this.globalPermissionMap) ||
            this.hasEntity(permission, this.openedProjectPermissionMap);
    }

    public hasAnyPermission(permissions: Array<GlobalPermission> | Array<ProjectPermission>): boolean {
        return this.hasEntities(permissions, this.globalPermissionMap) ||
            this.hasEntities(permissions, this.openedProjectPermissionMap);
    }

    public hasEveryPermission(permissions: Array<GlobalPermission> | Array<ProjectPermission>): boolean {
        return this.hasEntities(permissions, this.globalPermissionMap, true) ||
            this.hasEntities(permissions, this.openedProjectPermissionMap, true);
    }

    public hasAnyRoleOrPermission(rolesAndPermissions: IRolesAndPermissions): boolean {
        if (!this.isRoleOrPermissionProvided(rolesAndPermissions)) {
            return false;
        }

        const { roles, permissions } = rolesAndPermissions;

        return (!!roles?.length && this.hasAnyRole(roles)) || (!!permissions?.length && this.hasAnyPermission(permissions));
    }

    public hasEveryRoleOrPermission(rolesAndPermissions: IRolesAndPermissions): boolean {
        if (!this.isRoleOrPermissionProvided(rolesAndPermissions)) {
            return false;
        }

        const { roles, permissions } = rolesAndPermissions;

        const hasEveryRole = roles?.length ? this.hasEveryRole(roles) : true;
        const hasEveryPermission = permissions?.length ? this.hasEveryPermission(permissions) : true;

        return hasEveryRole && hasEveryPermission;
    }

    public isRoleOrPermissionProvided(rolesAndPermissions: IRolesAndPermissions): boolean {
        return !!rolesAndPermissions?.roles?.length || !!rolesAndPermissions?.permissions?.length;
    }

    private getRoleAndGlobalPermissionMaps(user: IApplicationUser): RoleAndGlobalPermissionMaps {
        const roleMap: Partial<BooleanRecord<DerivedRole>> = {};
        const permissionMap: Partial<BooleanRecord<GlobalPermission>> = {};

        if (user) {
            Object.values(DerivedRole).forEach(role => {
                roleMap[role] = user.derivedRole === role;
            });

            Object.values(GlobalPermission).forEach(permission => {
                permissionMap[permission] = !!user.permissions?.includes(permission);
            });

            return {
                roleMap: roleMap as BooleanRecord<DerivedRole>,
                globalPermissionMap: permissionMap as BooleanRecord<GlobalPermission>
            };
        }

        return this.INITIAL_ROLE_AND_GLOBAL_PERMISSION_MAPS;
    }

    private hasEntity(entity: string, entityMap: Record<string, boolean>): boolean {
        if (!entity || !entityMap) {
            return false;
        }

        return !!entityMap[entity];
    }

    private hasEntities(entities: Array<string>, entityMap: Record<string, boolean>, shouldCheckEveryEntity: boolean = false): boolean {
        if (!entities?.length || !entityMap) {
            return false;
        }

        if (shouldCheckEveryEntity) {
            return entities.every(entity => this.hasEntity(entity, entityMap));
        } else {
            return entities.some(entity => this.hasEntity(entity, entityMap));
        }
    }
}