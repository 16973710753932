import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError, NavigationExtras,
    NavigationStart,
    Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    LANGUAGE_FILE_NAME_SUFFIX,
    OUTLET_NAMES,
    PATH_NAMES_PER_OUTLETS,
    SafeAny
} from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { isNullOrUndefined, isNumeric } from 'sfx-commons';
import { ObjectUtils } from '../utils/object.utils';
import { AppInitializerService } from './app-initializer.service';

export type OutletWithSubOutlets = {
    name: OUTLET_NAMES,
    parameters?: Array<number | string>,
    subOutlets?: Array<OutletWithSubOutlets>
};

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    public readonly isRoutingInProgress: Observable<{ isInProgress: boolean, event: Event }>;
    public readonly isRouteGuardWaitingForUserActionObservable: Observable<boolean>;

    private readonly isRoutingInProgressSubject: BehaviorSubject<{ isInProgress: boolean, event: Event }>;
    private readonly isRouteGuardWaitingForUserActionSubject: BehaviorSubject<boolean>;

    private readonly ROUTE_PREFIX = 'routes.';

    constructor(private readonly router: Router,
                private readonly translate: TranslateService,
                private readonly madTranslateSvc: MadTranslateService) {
        this.isRoutingInProgressSubject = new BehaviorSubject<{ isInProgress: boolean, event: Event }>(
            { isInProgress: false, event: null });
        this.isRouteGuardWaitingForUserActionSubject = new BehaviorSubject<boolean>(false);

        this.isRoutingInProgress = this.isRoutingInProgressSubject.asObservable();
        this.isRouteGuardWaitingForUserActionObservable = this.isRouteGuardWaitingForUserActionSubject.asObservable();

        this.router.events.subscribe(event => {
            switch (true) {
                case (event instanceof NavigationStart):
                    this.isRoutingInProgressSubject.next({ isInProgress: true, event });
                    break;
                case (event instanceof NavigationEnd):
                case (event instanceof NavigationCancel):
                case (event instanceof NavigationError):
                    this.isRoutingInProgressSubject.next({ isInProgress: false, event });
                    break;
                default:
                    break;
            }
        });
    }

    public async closeOutlet(outlet: OUTLET_NAMES,
                             extras?: NavigationExtras) {
        const { outletName } = this.translateOutlet(outlet);
        await this.router.navigate([{ outlets: { [outletName]: null } }], extras);
    }

    public async navigateToOutlet(outlet: OUTLET_NAMES,
                                  parameters: Array<number>,
                                  relativeTo: ActivatedRoute | null,
                                  replaceUrl?: boolean): Promise<boolean> {
        const { outletName, pathName } = this.translateOutlet(outlet);
        let routeNamesParams: Array<string | number> = [pathName];
        if (parameters?.length) {
            routeNamesParams = [pathName, ...parameters];
        }
        return await this.router.navigate([{ outlets: {
                [outletName]: routeNamesParams
            } }], { relativeTo, replaceUrl });
    }

    public async navigateToOutlets(outletsWithParams: Array<OutletWithSubOutlets>,
                                   replaceUrl?: boolean): Promise<boolean> {
        return await Promise.sequentialEach<boolean>(outletsWithParams, async (outlet, i) => {
            const { outletName, pathName } = this.translateOutlet(outlet.name);
            let routeNamesParams: Array<string | number | SafeAny> = [pathName];
            if (outlet.parameters?.length) {
                routeNamesParams = [pathName, ...outlet.parameters];
            }
            const relativeTo = this.getCurrentActivatedRoute();

            const result = await this.router
                .navigate([{ outlets: { [outletName]: routeNamesParams } }], { relativeTo, replaceUrl });

            if (i === outletsWithParams.length - 1) {
                return result;
            }
        });
    }

    public async navigateToRoute(routeSubItems: string | Array<string | number>,
                                 parameters?: Array<number>,
                                 extras?: NavigationExtras,
                                 isAbsoluteRoute = true): Promise<boolean> {
        let url = '';
        if (routeSubItems instanceof Array) {
            const subItems = [...routeSubItems];
            url = this.translateRoute(subItems, parameters, isAbsoluteRoute);
        } else {
            url = routeSubItems;
        }
        return await this.router.navigate([url], extras);
    }

    public async navigateToRelativeSubRouteWithParam(routeSubItems: string | Array<string | number>, currentStep: number, newStep: number): Promise<boolean> {
        let localizedRoute = '';
        if (routeSubItems instanceof Array) {
            const subItems = [...routeSubItems];
            localizedRoute = this.translateRoute(subItems);
        } else {
            localizedRoute = this.translateRoute([routeSubItems]);
        }

        let oldRoute = `${localizedRoute}`;
        if (!isNullOrUndefined(currentStep)) {
            oldRoute += `/${currentStep}`;
        }

        let newRoute = `${localizedRoute}`;
        if (!isNullOrUndefined(newStep)) {
            newRoute += `/${newStep}`;
        }

        return await this.router.navigateByUrl(this.router.url.replace(oldRoute, newRoute));
    }

    public translateOutlet(outletName: OUTLET_NAMES): { outletName: OUTLET_NAMES, pathName: string } {
        let pathName = this.translate.instant(`${this.ROUTE_PREFIX}${PATH_NAMES_PER_OUTLETS[outletName]}`);
        pathName = this.useFallbackTranslationForRoute(pathName);

        return {
            outletName,
            pathName
        };
    }

    public translateRoute(routeSubItems: Array<string | number>, parameters?: Array<number>, isAbsoluteRoute = false): string {
        let route = '';
        let paramIndex = 0;
        for (let index = 0; index < routeSubItems?.length; index++) {
            let subRoute = routeSubItems[index];
            if (subRoute === '/') {
                subRoute = '';
            } else if (!isNumeric(subRoute)) {
                if (subRoute === '{projectId}' && parameters?.length) {
                    subRoute = parameters[paramIndex];
                    paramIndex++;
                } else {
                    const item = subRoute + '';
                    if (item.startsWith(this.ROUTE_PREFIX)) {
                        subRoute = this.translate.instant(item) as string;
                    } else {
                        subRoute = this.translate.instant(`${ this.ROUTE_PREFIX }${ item }`) as string;
                    }
                    subRoute = this.useFallbackTranslationForRoute(subRoute);
                }
            }
            if (index > 0) {
                route += '/';
            }
            route += subRoute;
        }

        if (isAbsoluteRoute) {
            route = `/${ this.madTranslateSvc.getSelectedLanguageFileSuffix() }/${ route }`;
        }

        return route;
    }

    public setIsRouteGuardWaitingForUserAction(value: boolean): void {
        this.isRouteGuardWaitingForUserActionSubject.next(value);
    }

    private getCurrentActivatedRoute(): ActivatedRoute {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }

        return route;
    }

    private removeNonTranslatedRoutePrefix(subRoute: string): string {
        if (subRoute.startsWith(this.ROUTE_PREFIX)) {
            subRoute = subRoute.substring(this.ROUTE_PREFIX.length);
        }

        return subRoute;
    }

    private useFallbackTranslationForRoute(subRoute: string): string {
        if (subRoute.startsWith(this.ROUTE_PREFIX)) {
            const currentLanguage = this.madTranslateSvc.getSelectedLanguageFileSuffix();
            const currentLanguageKey = ObjectUtils.getKeyForValue<string>(LANGUAGE_FILE_NAME_SUFFIX, currentLanguage);
            subRoute = this.removeNonTranslatedRoutePrefix(subRoute);
            const localTranslatedRoute = AppInitializerService.asyncRoutes[currentLanguageKey][subRoute] as string;
            if (localTranslatedRoute) {
                subRoute = localTranslatedRoute;
            }
        }

        return subRoute;
    }
}
