export enum LenderName {
    Acrevis = 'Acrevis',
    ASGA = 'Asga',
    BankCler = 'BankCler',
    BDL = 'BDL',
    BEKB = 'BEKB',
    BPS = 'BPS',
    CreditSuisse = 'CreditSuisse',
    Demo = 'Demo',
    EHypo = 'EHypo',
    GKB = 'GKB',
    GLKB = 'GLKB',
    LUKB = 'LUKB',
    MigrosBank = 'MigrosBank',
    PAX = 'PAX',
    PSW = 'PSW',
    Raiffeisen = 'Raiffeisen',
    SoBa = 'SoBa',
    Suva = 'Suva',
    SwisscantoAnlagestiftung = 'SwisscantoAnlagestiftung',
    SwissLife = 'SwissLife',
    UBS = 'UBS',
    UBSKey4 = 'UBSKey4',
    WIRBank = 'WIRBank',
    WKB = 'WKB',
    ZKB = 'ZKB'
}
