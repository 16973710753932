export enum RealTimeNotificationType {
    Default = 'Default',
    Unspecified = 'Unspecified',
    ProjectStatusUpdate = 'ProjectStatusUpdate',
    OfferStatusUpdate = 'OfferStatusUpdate',
    NotificationMessage = 'NotificationMessage',
    OffersCalculated = 'OffersCalculated',
    OneOfferCalculated = 'OneOfferCalculated',
    ProjectHasBeenSentToCompetenceCheck = 'ProjectHasBeenSentToCompetenceCheck',
    DocumentThumbnailsGenerated = 'DocumentThumbnailsGenerated',
    PossiblyNewProjectStatusImplications = 'PossiblyNewProjectStatusImplications',
    DocumentProcessingStatusUpdate = 'DocumentProcessingStatusUpdate',
    AdvisorUpdate = 'AdvisorUpdate',
    OfferPartnerUpdate = 'OfferPartnerUpdate'
}
