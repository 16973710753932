import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserInactivityService {
    public userInactivityObservable: Observable<boolean>;

    private _userInactivityTimeout: ReturnType<typeof setTimeout>;
    private isUserInactiveSubject = new BehaviorSubject<boolean>(false);

    private readonly activityEvents = ['pointermove', 'click', 'keydown', 'wheel'];

    constructor() {
        this.userInactivityObservable = this.isUserInactiveSubject.asObservable();
    }

    private get userInactivityTimeout(): ReturnType<typeof setTimeout> {
        return this._userInactivityTimeout;
    }

    private set userInactivityTimeout(delegate: () => ReturnType<typeof setTimeout>) {
        this.clearUserInactivityTimeout();
        this._userInactivityTimeout = delegate();
    }

    public startInactivityTracking(): void {
        if (!!environment.inactivityTimeout?.monitor) {
            // if not inactive && timeout is not yet set up
            if (!this.isUserInactiveSubject.getValue() && !this.userInactivityTimeout) {
                this.resetUserInactivityTracking();
            }
        }
    }

    public stopInactivityTracking(): void {
        this.removeWindowListeners();
        this.clearUserInactivityTimeout();
    }

    public resetUserInactivityTracking = (): void => {
        this.stopInactivityTracking();

        this.addWindowListeners();
        this.setIsUserInactive(false);
        this.userInactivityTimeout = () => (setTimeout(() => {
            this.setIsUserInactive(true);
        }, environment.inactivityTimeout?.monitor));
    };

    private clearUserInactivityTimeout(): void {
        if (this.userInactivityTimeout) {
            clearTimeout(this.userInactivityTimeout);
        }
    }

    private setIsUserInactive(isUserInactive: boolean): void {
        if (this.isUserInactiveSubject.getValue() === isUserInactive) {
            return;
        }
        this.isUserInactiveSubject.next(isUserInactive);
    }

    private addWindowListeners(): void {
        for (const event of this.activityEvents) {
            window.addEventListener(event, this.resetUserInactivityTracking);
        }
    }

    private removeWindowListeners(): void {
        for (const event of this.activityEvents) {
            window.removeEventListener(event, this.resetUserInactivityTracking);
        }
    }
}