import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OUTLET_NAMES, PATH_NAMES_PER_OUTLETS } from '../../constants';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { INews } from '../../projects/models/INews';
import { NewsService } from '../../projects/services/news.service';

@UntilDestroy()
@Component({
    selector: 'app-news-bar',
    templateUrl: './news-bar.component.html',
    styleUrls: ['./news-bar.component.scss']
})
export class NewsBarComponent implements OnInit {
    @Input({ required: true })
    public news: Array<INews>;

    public hasUnreadNews = false;

    public readonly xMarkIcon = faXmark;

    constructor(private activatedRoute: ActivatedRoute,
                private newsSvc: NewsService,
                private router: Router,
                private storageSvc: LocalStorageService) {}

    public ngOnInit(): void {
        this.newsSvc.hasUnreadNewsObservable
            .pipe(untilDestroyed(this))
            .subscribe(hasUnreadNews => this.hasUnreadNews = hasUnreadNews);
    }

    public hideModal = async () => {
        await this.router.navigate([{ outlets: { [OUTLET_NAMES.NEWS_MODAL_OUTLET]: null } }], {
            relativeTo: this.activatedRoute.parent,
            queryParamsHandling: 'preserve'
        });
    };

    public hideNews = () => {
        this.storageSvc.set(LOCAL_STORAGE_KEY.LAST_READ_NEWS, new Date());
        this.newsSvc.updateUnreadNewsStatus(false);
    };

    public openModal = async () => {
        await this.router.navigate([{ outlets: { [OUTLET_NAMES.NEWS_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.NEWS_MODAL_OUTLET] } }], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'preserve'
        });
        this.storageSvc.set(LOCAL_STORAGE_KEY.LAST_READ_NEWS, new Date());
        this.newsSvc.updateUnreadNewsStatus(false);
    };
}
