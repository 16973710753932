import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeFileExtension' })
export class RemoveFileExtensionPipe implements PipeTransform {
    public transform(item: string): string {
        const extensionIndex = item.lastIndexOf('.');
        if (extensionIndex > -1) {
            return item.substring(0, extensionIndex);
        }

        return item;
    }
}
