import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    public transform(array: Array<SafeAny>, value: string, func: (value: string, item: SafeAny) => SafeAny): Array<SafeAny> {
        return array.filter(item => func(value, item));
    }
}
