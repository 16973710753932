import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { IPrivacySettings } from '../models/IPrivacySettings';

@Injectable({
    providedIn: 'root'
})
export class PrivacyService {
    public privacyObservable: Observable<IPrivacySettings>;

    private privacySubject: BehaviorSubject<IPrivacySettings>;
    private settings: IPrivacySettings;

    constructor(private storageSvc: LocalStorageService) {
        this.settings = {
            isAnonymized: !!this.storageSvc.get(LOCAL_STORAGE_KEY.IS_ANONYMIZED)
        };
        this.privacySubject = new BehaviorSubject<IPrivacySettings>(this.settings);
        this.privacyObservable = this.privacySubject.asObservable();
    }

    public setAnonymization(isAnonymized: boolean): void {
        this.settings.isAnonymized = isAnonymized;
        this.storageSvc.set(LOCAL_STORAGE_KEY.IS_ANONYMIZED, this.settings.isAnonymized);
        this.privacySubject.next(this.settings);
    }
}
