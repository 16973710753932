<div class="mad-rocket-anim" [ngStyle]="styles">
    <svg
        v-show="isReady"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid"
        class="svg-rocket"
        #rocket
    >
        <g class="svg-rocket__fire" #rocketFire>
            <path
                d="M212.07,367.86c0,18.28,18.35,45.66,43.93,68.75c25.69-23.17,43.92-50.54,43.92-68.75
          c0-28.48-18.07-48.39-43.93-48.39C230.14,319.47,212.07,339.38,212.07,367.86z"
            />
            <path
                d="M237.69,362.94c0,7.62,7.65,19.03,18.3,28.65c10.71-9.66,18.31-21.06,18.31-28.65c0-11.87-7.53-20.16-18.3-20.16
          C245.22,342.78,237.69,351.08,237.69,362.94z"
            />
        </g>
        <g class="svg-rocket__body" #rocketBody>
            <path
                d="M147.96,292.25l17.47,52.4c0.65,1.73,1.63,3.32,2.89,4.68c4.69,4.68,12.28,4.68,16.97,0l33.04-33.04
          c-8.47-25.05-22.05-65.19-28.09-83.16c-0.14-0.51-0.27-0.98-0.41-1.48l-36.08,36.08C147.99,273.51,145.4,284.49,147.96,292.25z
          M321.73,233.1c-5.99,18.02-19.58,58.25-28.02,83.23l33.08,33.08c4.71,4.58,12.21,4.55,16.88-0.06c1.25-1.35,2.22-2.93,2.86-4.65
          l17.44-52.37c2.59-7.76,0-18.74-5.78-24.52l-35.89-35.89c0.01-0.04,0.01-0.08,0.02-0.11l-0.19-0.19
          C321.99,232.13,321.87,232.59,321.73,233.1z"
            />
            <path
                d="M260.48,84.3c-2.71-1.7-6.16-1.71-8.88-0.01c-13.91,9-23.05,18.14-31.85,26.94c-31.52,31.52-41.03,69.75-29.94,120.32
          c0.15,0.54,0.29,1.04,0.43,1.58c6.04,17.97,19.61,58.11,28.09,83.16l3.73-3.73c18.75-18.75,49.14-18.75,67.88,0c0,0,0,0,0,0
          l3.76,3.76c8.44-24.98,22.03-65.21,28.02-83.23c0.15-0.53,0.28-1.02,0.42-1.55c11.02-50.66,1.54-88.87-29.85-120.26
          C283.46,102.4,274.46,93.35,260.48,84.3z M272.97,193.77c-9.37,9.37-24.57,9.37-33.94,0c-9.37-9.37-9.37-24.57,0-33.94
          c9.37-9.37,24.57-9.37,33.94,0C282.34,169.21,282.34,184.4,272.97,193.77z"
            />
        </g>
    </svg>
    <svg
        class="svg-rocket__cloud1"
        viewBox="0 0 640 512"
        preserveAspectRatio="xMidYMid"
        #cloud1
    >
        <path
            d="M257.6,512H192c-53.02,0-96-42.98-96-96s42.98-96,96-96c0.6,0,1.1,0.2,1.6,0.2c7.7-54.3,54-96.2,110.4-96.2
  c46.59,0.05,88.26,29.03,104.5,72.7c12.39-5.7,25.86-8.67,39.5-8.7c53.02,0,96.67,42.62,96,96s-48.39,128-131.27,128H257.6z"
        />
    </svg>
    <svg
        class="svg-rocket__cloud2"
        viewBox="0 0 640 512"
        preserveAspectRatio="xMidYMid"
        #cloud2
    >
        <path
            d="M576,416c0,53.02-42.98,96-96,96H160c-53.02,0-96-42.98-96-96c0-42.5,27.8-78.2,66.1-90.8c-1.36-6.99-2.06-14.08-2.1-21.2
  c-0.03-61.82,50.06-111.97,111.88-112c0.04,0,0.08,0,0.12,0c43.2,0,80.4,24.9,99,60.8c14.7-17.5,36.4-28.8,61-28.8
  c44.18,0,80,35.82,80,80c0.03,5.44-0.5,10.87-1.6,16.2c0.5,0,1-0.2,1.6-0.2C533.02,320,576,362.98,576,416z"
        />
    </svg>
</div>
