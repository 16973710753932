<sfx-card class="info-card mad-margin-bottom-16"
          extraClasses="mad-radius-xs"
          [title]="affordabilityHeader">
    <ng-template #affordabilityHeader>
        <sfx-title-bar [icon]="scaleUnbalancedIcon"
                       titleKey="edit_project.affordability"
                       titleSize="s"
                       iconSize="sm"
        ></sfx-title-bar>
    </ng-template>
    <sfx-slider-range [title]="'edit_project.normal_affordability' | translate"
                      [mark]="'%'"
                      [min]="offerList?.minAffordability ? +(offerList?.minAffordability | number: '.0-2') : 0"
                      [max]="offerList?.maxAffordability ? +(offerList?.maxAffordability | number: '.0-2') : 0"
    >
    </sfx-slider-range>
    <sfx-slider-range [title]="'edit_project.affordability_at_retirement_age' | translate"
                      *ngIf="offerList?.minAffordabilityInAge || offerList?.maxAffordabilityInAge"
                      [mark]="'%'"
                      [min]="offerList?.minAffordabilityInAge ? +(offerList?.minAffordabilityInAge | number: '.0-2') : 0"
                      [max]="offerList?.maxAffordabilityInAge ? +(offerList?.maxAffordabilityInAge | number: '.0-2') : 0"
    >
    </sfx-slider-range>
</sfx-card>

<sfx-card class="info-card"
          extraClasses="mad-radius-xs"
          [title]="ltvHeader">
    <ng-template #ltvHeader>
        <sfx-title-bar [icon]="percentIcon"
                       titleKey="edit_project.loan_to_value_ratio"
                       titleSize="s"
                       iconSize="sm"
        ></sfx-title-bar>
    </ng-template>
    <sfx-slider-range [mark]="'%'"
                      [min]="offerList?.minNetLTV ? +(offerList?.minNetLTV | number: '.0-2' ) : 0"
                      [max]="offerList?.maxNetLTV ? +(offerList?.maxNetLTV | number: '.0-2' ) : 0"
    ></sfx-slider-range>
</sfx-card>