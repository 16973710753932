/* eslint-disable */
import { AbstractControl } from '@angular/forms';
import { Operation } from 'fast-json-patch/module/core';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'sfx-commons';
import { environment } from '../../../environments/environment';
import { SafeAny } from '../../constants';

export function joinElements(elements: Array<string | number>, separator?: string) {
    return elements.filter(e => {
            if (isNullOrUndefined(e)) {
                return false;
            }
            return !(typeof e === 'string' && !e.trim().length);
        }
    ).join(separator);
}

export function nameOfDirectProperty<T>(func: (context: T) => SafeAny, context: T, isGetter = false): string {
    const parsedFunc = func.toString().split('=>')
        .map(item => item.trim());

    if (parsedFunc.length !== 2) {
        throw new Error(`Invalid lambda function: '${ func.toString() }'`);
    }

    const returnValue = stripAssertionOperators(parsedFunc[1])
        .split('.');
    if (returnValue.length !== 2) {
        throw new Error(`Invalid expression for property: '${ returnValue }'`);
    }

    const supposedPropertyName = returnValue[1];
    if (func(context) === context[supposedPropertyName] || isGetter) {
        return supposedPropertyName;
    }

    throw new Error(`Could not find matching property with correct value for lambda function '${ func }'`);
}

export function pathOfProperty<T>(func: (context: T) => SafeAny): string {
    const parsedFunc = func.toString().split('=>')
        .map(item => item.trim());

    if (parsedFunc.length !== 2) {
        throw new Error(`Invalid lambda function: '${ func.toString() }'`);
    }

    const returnValue = stripAssertionOperators(parsedFunc[1])
        .split('.');
    if (returnValue.length < 2) {
        throw new Error(`Invalid expression for property: '${ returnValue }'`);
    }

    return `/${ returnValue.slice(1).join('/') }`;
}

function stripAssertionOperators(value: string): string {
    return value?.replace(/[?!]/g, '');
}

export function getMutationObject(operations: Array<Operation>) {
    const object = {};

    operations.forEach(operation => {
        const keys = operation.path.split('/').filter(k => k !== '');
        let temp = object;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (!temp[key]) {
                temp[key] = {};
            }
            if (i === keys.length - 1) {
                temp[key] = ('value' in operation) ? operation.value : null;
            } else {
                temp = temp[key];
            }
        }
    });

    return object;
}

export function unregisterSfxStatusChanges(abstractControl: AbstractControl): void {
    if (abstractControl) {
        abstractControl.unregisterSfxStatusChanges();
    }
}

export function emitAndCompleteSubject(subject: Subject<void>): void {
    if (subject) {
        subject.next();
        subject.complete();
    }
}

export function isUserPilotFlowEnabled(userPilotFlow: { isEnabled: boolean }): boolean {
    return !!environment.features?.userPilot?.isEnabled && !!userPilotFlow?.isEnabled;
}

export function wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}