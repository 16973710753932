import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appAvailableHeightAdjuster]'
})
export class AvailableHeightAdjusterDirective implements AfterViewInit, OnDestroy {
    @Input()
    public additionalHeightProperty: 'maxHeight';
    @Input()
    public heightProviderSelector: string;
    @Input()
    public siblingSelector: string;
    @Input()
    public defaultMaxHeight = '70vh';

    private resizeObserver: ResizeObserver;
    private parentElement: HTMLElement;
    private siblingElement: HTMLElement;

    constructor(private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.setElementMaxHeight();
            this.resizeObserver = new ResizeObserver(() => {
                this.setElementMaxHeight();
            });
            this.parentElement = document.querySelector(this.heightProviderSelector) || this.elementRef.nativeElement.parentElement;
            this.siblingElement = document.querySelector(this.siblingSelector);
            if (this.parentElement) {
                this.resizeObserver.observe(this.parentElement);
            }
            if (this.siblingElement) {
                this.resizeObserver.observe(this.siblingElement);
            }
        }, 50);
    }

    public ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize() {
        this.setElementMaxHeight();
    }

    private setElementMaxHeight(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.style.height = 0;
            if (this.additionalHeightProperty) {
                this.elementRef.nativeElement.style[this.additionalHeightProperty] = 0;
            }

            const parentHeight = this.parentElement.offsetHeight;
            let siblingHeight = 0;
            if (this.siblingElement) {
                siblingHeight = this.siblingElement.offsetHeight;
            }
            const heightValue = (parentHeight - siblingHeight) + 'px';

            this.elementRef.nativeElement.style.height = heightValue;
            if (this.additionalHeightProperty) {
                this.elementRef.nativeElement.style[this.additionalHeightProperty] = heightValue;
            }
        });
    }
}
