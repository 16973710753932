import { Pipe, PipeTransform } from '@angular/core';
import * as DateFns from 'date-fns';

@Pipe({
    name: 'isDateBefore'
})
export class IsDateBeforePipe implements PipeTransform {
    public transform(value: string | Date, comparedDate: string | Date): boolean {
        if (!value) {
            return true;
        }

        return DateFns.isBefore(new Date(value), new Date(comparedDate));
    }
}
