import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { SafeAny } from '../../constants';

@UntilDestroy()
@Directive({
    selector: '[appMinHeightAdjuster]'
})
export class MinHeightAdjusterDirective implements AfterViewInit, OnDestroy {
    @Input({ required: true })
    public offsetTopSelector: string;
    @Input({ required: true })
    public offsetTargetSelector: string;
    @Input({ required: true })
    public containerSelector: string;
    @Input()
    public updateTriggerObservable: Observable<boolean>;

    private containerElement: HTMLElement;
    private offsetTargetElement: HTMLElement;
    private offsetTopElement: HTMLElement;

    private interval: SafeAny;
    private resizeObserver: ResizeObserver;

    constructor(private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        if (this.updateTriggerObservable) {
            this.updateTriggerObservable
                .pipe(untilDestroyed(this))
                .subscribe(shouldUpdate => {
                    if (shouldUpdate) {
                        setTimeout(() => {
                            this.setup();
                        }, 250);
                    }
                });
        }

        this.setup();
    }

    public ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private setup() {
        this.containerElement = document.querySelector(this.containerSelector);
        if (!this.containerElement) {
            return;
        }
        this.resizeObserver = new ResizeObserver(() => {
            this.setMinHeight();
        });
        this.resizeObserver.observe(this.containerElement);

        this.offsetTargetElement = this.containerElement.querySelector(this.offsetTargetSelector);
        this.offsetTopElement = this.containerElement.querySelector(this.offsetTopSelector);
        this.setMinHeight();
    }

    private setMinHeight(): void {
        if (!this.offsetTargetElement || !this.offsetTopElement) {
            return;
        }

        const topY = this.offsetTopElement.getBoundingClientRect().top;
        const targetY = this.offsetTargetElement.getBoundingClientRect().top;
        const height = targetY - topY;

        const targetAnimationTime = 100;
        const iterationCount = 5;
        const heightPerIteration = height / iterationCount;
        let iterationsDone = 1;

        if (this.interval) {
            clearInterval(this.interval);
        }

        this.interval = setInterval(() => {
            if (iterationCount >= iterationsDone) {
                const newHeight = heightPerIteration * iterationsDone + 'px';
                if (this.elementRef.nativeElement.style.minHeight !== newHeight) {
                    this.elementRef.nativeElement.style.height = newHeight;
                }
                iterationsDone++;
            } else {
                clearInterval(this.interval);
            }
        }, targetAnimationTime / iterationCount);
    }
}
