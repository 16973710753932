import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {
    CalculatedMutationOperationPairsType,
    ISelectOption, isNullOrUndefined, joinElements,
    tryToUnsubscribeFromSubscription
} from 'sfx-commons';
import { Userpilot } from 'userpilot';
import { environment } from '../../../environments/environment';
import { OUTLET_NAMES } from '../../constants';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { RouterService } from '../../core/services/router.service';
import { isUserPilotFlowEnabled, nameOfDirectProperty } from '../../core/utils/utils';
import { AdvisorService } from '../../projects/services/advisor.service';
import { AuthenticationService } from '../../projects/services/authentication.service';

interface IAssignedSupporterFormGroup {
    id: FormControl<number>;
}

interface IApplicationUserFormGroup {
    csContactPerson: FormControl<string>;
    wkbContactPerson: FormControl<string>;
    useExternalDocumentManagement: FormControl<boolean>;
    assignedSupporters: FormArray<FormGroup<IAssignedSupporterFormGroup>>;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-settings-modal',
    templateUrl: 'settings-modal.component.html',
    styleUrls: ['settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit, OnChanges {
    public currentUserFormGroup: FormGroup<IApplicationUserFormGroup>;
    public supporterOptions: Array<ISelectOption<number>>;
    public assignedSupporterIdsModel: Array<number>;
    public isLoadingSupporters: boolean;
    public areSupportersLoaded: boolean;
    public isUserPilotSupporterInfoFlowEnabled: boolean;

    public readonly gearIcon = faGear;
    protected readonly faCircleInfo = faCircleInfo;

    private formGroupValueChangesSubscription: Subscription;
    private currentUser: IApplicationUser;
    private isSfxAdmin: boolean;
    private isUserOrAdmin: boolean;
    private isSupporterManagementEnabledForCurrentUser: boolean;

    constructor(private readonly advisorSvc: AdvisorService,
                private readonly authenticationSvc: AuthenticationService,
                private readonly activatedRoute: ActivatedRoute,
                private readonly routerSvc: RouterService) {
        this.authenticationSvc.currentUser
            .pipe(untilDestroyed(this))
            .subscribe((user: IApplicationUser) => {
                this.currentUser = user;
                this.isSfxAdmin = this.authenticationSvc.isSfxAdmin;
                this.isUserOrAdmin = this.authenticationSvc.isUser || this.authenticationSvc.isAdmin;
                this.isSupporterManagementEnabledForCurrentUser = this.isUserOrAdmin && user?.tenantSettings?.isSupporterRoleEnabled;

                this.setUpCurrentUserForm(user);
            });
    }

    public ngOnInit(): void {
        this.isUserPilotSupporterInfoFlowEnabled = isUserPilotFlowEnabled(environment.features?.userPilot?.flows?.supporter_info);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!!changes?.isModalOpen?.currentValue && !this.areSupportersLoaded && this.isSupporterManagementEnabledForCurrentUser) {
            void this.getAndSetSupporterOptions();
        }
    }

    public closeModal = async () => {
        await this.routerSvc.closeOutlet(OUTLET_NAMES.SETTINGS_MODAL_OUTLET, { relativeTo: this.activatedRoute.parent, queryParamsHandling: 'preserve' });
    };

    public onSupportersMultiSelectChange(selectedSupportersIds: Array<number>): void {
        const addedSupporterIds = selectedSupportersIds.filter(supporterId => !this.assignedSupporterIdsModel.includes(supporterId));
        const removedSupporterIndexes = this.assignedSupporterIdsModel.map((supporterId, index) => {
            if (!selectedSupportersIds.includes(supporterId)) {
                return index;
            }
        }).filter(index => !isNullOrUndefined(index));

        if (removedSupporterIndexes.length) {
            removedSupporterIndexes.forEach(index => {
                this.currentUserFormGroup.controls.assignedSupporters.removeAt(index, { emitEvent: false });
            });
        }

        if (addedSupporterIds.length) {
            addedSupporterIds.forEach(supporterId => {
                this.currentUserFormGroup.controls.assignedSupporters.push(
                    new FormGroup<IAssignedSupporterFormGroup>({
                        id: new FormControl(supporterId)
                    }), { emitEvent: false }
                );
            });
        }

        this.setAssignedSupporterIdsModel(this.currentUserFormGroup.controls.assignedSupporters.controls);
        this.currentUserFormGroup.controls.assignedSupporters.updateValueAndValidity();
    }

    private setUpCurrentUserForm(user: IApplicationUser): FormGroup<IApplicationUserFormGroup> {
        if (this.currentUser) {
            this.currentUserFormGroup = new FormGroup<IApplicationUserFormGroup>({
                csContactPerson: new FormControl({ value: user.csContactPerson, disabled: this.isSfxAdmin }),
                wkbContactPerson: new FormControl({ value: user.wkbContactPerson, disabled: this.isSfxAdmin }),
                useExternalDocumentManagement: new FormControl({ value: user.useExternalDocumentManagement, disabled: this.isSfxAdmin || !this.currentUser.isExternalDocumentManagementEnabled }),
                assignedSupporters: new FormArray(this.getAssignedSupportersFormArrayControls())
            }, { updateOn: 'blur' }).setDisplayErrorsOn('never');

            if (!this.isSupporterManagementEnabledForCurrentUser) {
                this.currentUserFormGroup.controls.assignedSupporters.disable();
            } else {
                this.setAssignedSupporterIdsModel(this.currentUserFormGroup.controls.assignedSupporters.controls);
            }

            this.currentUserFormGroup.addMissingFieldsAsDisabled(this.currentUser);
            tryToUnsubscribeFromSubscription(this.formGroupValueChangesSubscription);
            this.formGroupValueChangesSubscription = this.currentUserFormGroup.setupBlurUpdates(this.updateCurrentUser, this, nameOfDirectProperty<SettingsModalComponent>(c => c.currentUser, this));
        } else {
            this.currentUserFormGroup = null;
        }

        return this.currentUserFormGroup;
    }

    private updateCurrentUser = async (mutationOperationPairs: CalculatedMutationOperationPairsType): Promise<void> => {
        try {
            const mutations = mutationOperationPairs.map(m => m.mutation);

            if (!mutations.length) {
                this.currentUserFormGroup.markAsPristine();
                return;
            }

            await this.authenticationSvc.updateCurrentUser(mutations, this.currentUserFormGroup);
        } catch (ex) {
            console.error(ex);
            this.currentUserFormGroup.revertAndHighlightErrors(this.currentUser, null, () => this.setUpCurrentUserForm(this.currentUser));
        }
    };

    private getAssignedSupportersFormArrayControls(): Array<FormGroup<IAssignedSupporterFormGroup>> {
        const assignedSupportersFormArrayControls: Array<FormGroup<IAssignedSupporterFormGroup>> = [];

        if (this.currentUser?.assignedSupporters?.length) {
            this.currentUser.assignedSupporters.forEach(supporter => {
                assignedSupportersFormArrayControls.push(
                    new FormGroup<IAssignedSupporterFormGroup>({
                        id: new FormControl(supporter.id)
                    })
                );
            });
        }

        return assignedSupportersFormArrayControls;
    }

    private setAssignedSupporterIdsModel(assignedSupportersFormArrayControls: Array<FormGroup<IAssignedSupporterFormGroup>>): void {
        this.assignedSupporterIdsModel = assignedSupportersFormArrayControls?.map(assignedSupporterFormGroup => assignedSupporterFormGroup.value.id) || [];
    }

    public onSupporterInfoIconClicked = () => {
        if (this.isUserPilotSupporterInfoFlowEnabled) {
            Userpilot.trigger(environment.features.userPilot.flows.supporter_info.key);
        }
    };

    private async getAndSetSupporterOptions(): Promise<void> {
        if (!this.isLoadingSupporters) {
            try {
                this.isLoadingSupporters = true;
                const supporters = await this.advisorSvc.getSupporters();

                if (supporters) {
                    this.supporterOptions = supporters.map(supporter => {
                        const label = joinElements([supporter.firstname, supporter.lastname], ' ');

                        return {
                            value: supporter.id,
                            label,
                            title: joinElements([label, supporter.mail], ', ')
                        };
                    });
                }

                this.areSupportersLoaded = true;
            } catch (ex) {
                console.error(ex);
            } finally {
                this.isLoadingSupporters = false;
            }
        }
    }
}