import { Directive, Host, Inject, Input, OnInit, Optional, Self } from '@angular/core';
import { InputComponent, INTERFACE_TOKEN } from 'sfx-commons';

@Directive({
    selector: '[appCustomInput]'
})
export class CustomInputDirective implements OnInit {
    @Input() public customInputValidation;
    @Input() public mask;

    constructor(@Inject(INTERFACE_TOKEN) @Host() @Self() @Optional() public host: InputComponent) {}

    public ngOnInit(): void {
        if (this.host) {
            this.host.mask = this.mask || this.host.mask;
            this.host.customInputValidation = this.customInputValidation;
        }
    }
}
