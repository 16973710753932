import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';
import { isNullOrUndefined } from 'sfx-commons';

@Pipe({
    name: 'toString',
})
export class ToStringPipe implements PipeTransform {
    public transform(value: SafeAny): SafeAny {
        return isNullOrUndefined(value) ? '' : value.toString();
    }
}
