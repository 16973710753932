import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalUtils } from '../../core/utils/modal.utils';
import { ProjectsService } from '../../projects/services/projects.service';

@Directive({ selector: '[appConfirmLeaveBeforeClick]' })
export class ConfirmLeaveBeforeClickDirective {
    @Input()
    public isControlDisabled = false;
    @Output()
    public afterConfirmed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    constructor(private readonly modalSvc: NzModalService,
                private readonly projectSvc: ProjectsService,
                private readonly translate: TranslateService) {}

    @HostListener('click', ['$event'])
    public async onClick($event: MouseEvent): Promise<void> {
        if (this.isControlDisabled) {
            return;
        }

        const doesOpenedProjectNeedActionBeforeLeaving = this.projectSvc.doesOpenedProjectNeedActionBeforeLeaving();

        if (doesOpenedProjectNeedActionBeforeLeaving) {
            const { isConfirmedPromise } = ModalUtils.createOpenedProjectNeedsActionConfirmation(this.modalSvc, this.translate);

            if (await isConfirmedPromise) {
                this.afterConfirmed.emit($event);
            }
        } else {
            this.afterConfirmed.emit($event);
        }
    }
}
