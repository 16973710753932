import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PROJECT_PAGE_URL_SUBITEMS_TEMPLATE } from '../../../constants';
import { RouterService } from '../../../core/services/router.service';
import { EnumUtils } from '../../../core/utils/enum.utils';
import { BorrowerType } from '../../models/enums/borrowerType';
import { BusinessType } from '../../models/enums/businessType';
import { Gender } from '../../models/enums/gender';
import { ProjectStatus } from '../../models/enums/projectStatus';
import { IBorrower } from '../../models/IBorrower';
import { ILegalEntityBorrower } from '../../models/ILegalEntityBorrower';
import { IProject } from '../../models/IProject';
import { IProjectVersion } from '../../models/IProjectVersion';
import { OfferService } from '../../services/offer.service';
import { IInstitution } from '../../models/IInstitution';
import { ISelectOption } from 'sfx-commons';
import { IInstitutionsData } from '../../models/IInstitutionsData';
import { IUnsupportedPartnersPerBusinessType } from '../../models/IUnsupportedPartnersPerBusinessType';

export class ProjectUtils {
    public static calculateBackupTranslationKeyForBorrower(borrower: IBorrower): void {
        const { personalInfo } = borrower;
        const { firstName, gender, lastName } = personalInfo;

        let title = '';
        let shouldDisplayBackupTitle = false;
        let backupTitleTranslationKey = '';
        if (firstName) {
            if (lastName) {
                title = `${ firstName } ${ lastName }`;
            } else {
                title = firstName;
            }
            shouldDisplayBackupTitle = false;
        } else {
            if (lastName) {
                title = lastName;
                shouldDisplayBackupTitle = false;
            } else {
                let suffix = '';
                switch (gender) {
                    case Gender.Male:
                        suffix = 'mannlich';
                        break;
                    case Gender.Female:
                        suffix = 'weiblich';
                        break;
                    default:
                        suffix = 'nicht_spezifiziert';
                        break;
                }
                backupTitleTranslationKey = `borrower.${ suffix }`;
                shouldDisplayBackupTitle = true;
            }
        }

        borrower.displayProps = {
            backupTitleTranslationKey,
            shouldDisplayBackupTitle,
            title
        };
    }

    public static isProjectEditable(project: IProject | IProjectVersion): boolean {
        return project && !project.isFrozen;
    }

    public static conditionallyDisableFormGroupsForProject(isProjectEditable: boolean,
                                                           shortModelFormGroup: UntypedFormGroup | UntypedFormArray,
                                                           extendedModelFormGroup?: UntypedFormGroup | UntypedFormArray): void {
        const disableOptions = { emitEvent: true };
        if (!isProjectEditable) {
            if (shortModelFormGroup && !shortModelFormGroup.disabled) {
                shortModelFormGroup.disable(disableOptions);
            }

            if (extendedModelFormGroup && !extendedModelFormGroup.disabled) {
                extendedModelFormGroup.disable(disableOptions);
            }
        }
    }

    public static calculateBorrowersOverallIncomeBasedOnType(project: IProject): number {
        if (!project?.borrowersData) {
            return undefined;
        }
        if (project.borrowersData.borrowersType === BorrowerType.Individual) {
            return this.calculateBorrowersOverallIncome(project.borrowersData.individualBorrowers);
        } else {
            return this.calculateLegalEntityBorrowersOverallProfit(project.borrowersData.legalEntities);
        }
    }

    public static calculateBorrowersOverallIncome(borrowers: Array<IBorrower>): number {
        if (borrowers?.length) {
            let sum = 0;

            borrowers.map(borrower => {
                sum += +borrower.overallIncome;
            });

            return sum;
        } else {
            return undefined;
        }
    }

    public static calculateLegalEntityBorrowersOverallProfit(legalEntities: Array<ILegalEntityBorrower>): number {
        if (legalEntities?.length) {
            let sum = 0;

            legalEntities.map(legalEntity => {
                sum += +legalEntity.overallProfit;
            });

            return sum;
        } else {
            return null;
        }
    }

    public static isProjectStatusAtMost(latestStatus: ProjectStatus,
                                        currentStatus: ProjectStatus,
                                        extraStatuses: Array<ProjectStatus>,
                                        disallowedStatuses: Array<ProjectStatus>): boolean {
        const projectKeys = Object.keys(ProjectStatus);
        const currentStatusIndex = projectKeys.indexOf(currentStatus);
        const latestStatusIndex = projectKeys.indexOf(latestStatus);

        for (const status of disallowedStatuses) {
            if (status === currentStatus) {
                return false;
            }
        }

        if (currentStatusIndex <= latestStatusIndex) {
            return true;
        }

        for (const status of extraStatuses) {
            const statusIndex = projectKeys.indexOf(status);

            if (currentStatusIndex <= statusIndex) {
                return true;
            }
        }

        return false;
    }

    public static isProjectStatusAfter(currentStatus: ProjectStatus, determiningStatus: ProjectStatus, doIncludeSelf = true): boolean {
        return EnumUtils.isElementAfter(ProjectStatus, currentStatus, determiningStatus, doIncludeSelf);
    }

    public static hasProjectJustBeenSubmittedToCC(previousProjectStatus: ProjectStatus, currentProjectStatus: ProjectStatus): boolean {
        return this.hasProjectJustEnteredStatus(previousProjectStatus, currentProjectStatus, ProjectStatus.CompetenceCenterCheck);
    }

    public static hasProjectJustBeenSubmittedToLenders(previousProjectStatus: ProjectStatus, currentProjectStatus: ProjectStatus): boolean {
        return this.hasProjectJustEnteredStatus(previousProjectStatus, currentProjectStatus, ProjectStatus.SubmittedToLenders);
    }

    public static navigateToProject = async (projectId: number,
                                             offerSvc: OfferService,
                                             routerSvc: RouterService): Promise<void> => {
        offerSvc.clearCache();
        await routerSvc.navigateToRoute(PROJECT_PAGE_URL_SUBITEMS_TEMPLATE, [projectId]);
    };

    public static canCopyExistingProject(businessType: BusinessType): boolean {
        return businessType === BusinessType.Extension || businessType === BusinessType.Increase ||
            businessType === BusinessType.Redemption || businessType === BusinessType.Consolidation;
    }

    public static isFinancingPartnerOptional(businessType: BusinessType): boolean {
        return businessType === BusinessType.Redemption || businessType === BusinessType.Consolidation;
    }

    public static isFinancingPartnerUnsupportedForBusinessType(businessType: BusinessType, financingPartnerId: number, unsupportedPartnersPerBusinessType: Array<IUnsupportedPartnersPerBusinessType>): boolean {
        if (!unsupportedPartnersPerBusinessType?.length) {
            return false;
        }

        return !!unsupportedPartnersPerBusinessType.find(u => u.businessType === businessType && u.partnerIds.includes(financingPartnerId));
    }

    public static getInstitutionsData(institutions: Array<IInstitution>): IInstitutionsData {
        if (!institutions?.length) {
            return null;
        }

        const dropdownOptions: Array<ISelectOption> = [];
        const namesByValue: Record<string, string> = {};

        institutions.forEach(institution => {
            dropdownOptions.push({
                label: institution.name,
                value: institution.value,
                isDisabled: institution.isDisabled
            });
            namesByValue[institution.value] = institution.name;
        });

        return { institutions, dropdownOptions, namesByValue };
    }

    private static hasProjectJustEnteredStatus(previousProjectStatus: ProjectStatus, currentProjectStatus: ProjectStatus, relevantStatus: ProjectStatus): boolean {
        if (!previousProjectStatus || !currentProjectStatus || !relevantStatus) {
            return false;
        }
        return !ProjectUtils.isProjectStatusAfter(previousProjectStatus, relevantStatus) &&
            ProjectUtils.isProjectStatusAfter(currentProjectStatus, relevantStatus);
    }
}
