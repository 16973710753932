import { Injectable } from '@angular/core';
import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel, RetryContext
} from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { RealTimeNotificationType } from '../../projects/models/enums/realTimeNotificationType';
import { RealTimeServerNotificationType } from '../../projects/models/enums/realTimeServerNotificationType';
import {
    IRealTimeDocumentProcessingStatusUpdateNotification
} from '../../projects/models/IRealTimeDocumentProcessingStatusUpdateNotification';
import { IRealTimeDocumentThumbnailsNotification } from '../../projects/models/IRealTimeDocumentThumbnailsNotification';
import { IRealTimeMessageNotification } from '../../projects/models/IRealTimeMessageNotification';
import { IRealTimeNotification } from '../../projects/models/IRealTimeNotification';
import { IRealTimeOffersGeneratedUpdateNotification } from '../../projects/models/IRealTimeOffersGeneratedUpdateNotification';
import { IRealTimeOfferStatusUpdateNotification } from '../../projects/models/IRealTimeOfferStatusUpdateNotification';
import { IRealTimeOneOfferGeneratedUpdateNotification } from '../../projects/models/IRealTimeOneOfferGeneratedUpdateNotification';
import {
    IRealTimePossiblyNewProjectStatusImplicationsNotification
} from '../../projects/models/IRealTimePossiblyNewProjectStatusImplicationsNotification';
import {
    IRealTimeProjectAdvisorUpdateNotification
} from '../../projects/models/IRealTimeProjectAdvisorUpdateNotification';
import { IRealTimeProjectStatusUpdateNotification } from '../../projects/models/IRealTimeProjectStatusUpdateNotification';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { wait } from '../utils/utils';

@Injectable({
    providedIn: 'root'
})
export class RealTimeNotificationService {
    public readonly projectStatusUpdateNotifications: Observable<IRealTimeProjectStatusUpdateNotification>;
    public readonly offerStatusUpdateNotifications: Observable<IRealTimeOfferStatusUpdateNotification>;
    public readonly offersGeneratedUpdateNotifications: Observable<IRealTimeOffersGeneratedUpdateNotification>;
    public readonly oneOfferGeneratedUpdateNotifications: Observable<IRealTimeOneOfferGeneratedUpdateNotification>;
    public readonly thumbnailsGeneratedNotifications: Observable<IRealTimeDocumentThumbnailsNotification>;
    public readonly possiblyNewProjectStatusImplicationsNotifications: Observable<IRealTimePossiblyNewProjectStatusImplicationsNotification>;
    public readonly projectAdvisorUpdateNotifications: Observable<IRealTimeProjectAdvisorUpdateNotification>;
    public readonly offerPartnerUpdateNotifications: Observable<IRealTimeOfferStatusUpdateNotification>;
    public readonly documentProcessingStatusUpdateNotification: Observable<IRealTimeDocumentProcessingStatusUpdateNotification>;
    public readonly messageNotifications: Observable<IRealTimeMessageNotification>;
    public readonly isWebSocketConnected: Observable<boolean>;

    private hubConnection: HubConnection;
    private readonly RealTimeNotificationUrl = environment.notificationsUrl;
    private readonly projectStatusUpdateNotificationsSubject =
        new Subject<IRealTimeProjectStatusUpdateNotification>();
    private readonly offerStatusUpdateNotificationsSubject =
        new Subject<IRealTimeOfferStatusUpdateNotification>();
    private readonly offersGeneratedUpdateNotificationsSubject =
        new Subject<IRealTimeOffersGeneratedUpdateNotification>();
    private readonly oneOfferGeneratedUpdateNotificationsSubject =
        new Subject<IRealTimeOneOfferGeneratedUpdateNotification>();
    private readonly thumbnailsGeneratedNotificationSubject =
        new Subject<IRealTimeDocumentThumbnailsNotification>();
    private readonly possiblyNewProjectStatusImplicationsSubject =
        new Subject<IRealTimePossiblyNewProjectStatusImplicationsNotification>();
    private readonly projectAdvisorUpdateNotificationsSubject =
        new Subject<IRealTimeProjectAdvisorUpdateNotification>();
    private readonly offerPartnerUpdateNotificationsSubject =
        new Subject<IRealTimeOfferStatusUpdateNotification>();
    private readonly documentProcessingStatusUpdateSubject =
        new Subject<IRealTimeDocumentProcessingStatusUpdateNotification>();
    private readonly messageNotificationsSubject =
        new Subject<IRealTimeMessageNotification>();
    private readonly isWebSocketConnectedSubject = new BehaviorSubject<boolean>(false);
    private readonly serverEventsKey = 'DfsNotificationMessage';

    constructor(private readonly authService: AuthenticationService) {
        this.projectStatusUpdateNotifications = this.projectStatusUpdateNotificationsSubject.asObservable();
        this.offerStatusUpdateNotifications = this.offerStatusUpdateNotificationsSubject.asObservable();
        this.offersGeneratedUpdateNotifications = this.offersGeneratedUpdateNotificationsSubject.asObservable();
        this.oneOfferGeneratedUpdateNotifications = this.oneOfferGeneratedUpdateNotificationsSubject.asObservable();
        this.thumbnailsGeneratedNotifications = this.thumbnailsGeneratedNotificationSubject.asObservable();
        this.possiblyNewProjectStatusImplicationsNotifications = this.possiblyNewProjectStatusImplicationsSubject.asObservable();
        this.projectAdvisorUpdateNotifications = this.projectAdvisorUpdateNotificationsSubject.asObservable();
        this.offerPartnerUpdateNotifications = this.offerPartnerUpdateNotificationsSubject.asObservable();
        this.documentProcessingStatusUpdateNotification = this.documentProcessingStatusUpdateSubject.asObservable();
        this.messageNotifications = this.messageNotificationsSubject.asObservable();
        this.isWebSocketConnected = this.isWebSocketConnectedSubject.asObservable();
    }

    public async connect(): Promise<void> {
        await this.disconnect();
        this.createConnection();
        this.registerOnServerEvents();
        await this.innerConnect();
    }

    public async disconnect(): Promise<void> {
        if (!this.hubConnection) {
            return;
        }

        if (!this.isDisconnected()) {
            this.deregisterOnServerEvents();

            try {
                await this.hubConnection.stop();
            } catch (ex) {
                await wait(5000);
                await this.disconnect();
            }
        }
    }

    public async sendNotificationToServer(notificationType: RealTimeServerNotificationType, payload: unknown): Promise<void> {
        if (this.isConnected()) {
            await this.hubConnection.send(notificationType, payload);
        }
    }

    private createConnection(): void {
        this.hubConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Warning)
            .withUrl(this.RealTimeNotificationUrl, {
                accessTokenFactory: () => this.authService.getAccessToken(),

                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext: RetryContext): number | null => {
                    if (!retryContext) {
                        console.error('WebSocket failed to reconnect (no retryContext).');
                        return;
                    }

                    if (retryContext.previousRetryCount < 10 && this.shouldReconnect()) {
                        console.info(`WebSocket trying to reconnect (attempt ${ retryContext.previousRetryCount + 1 }).`);
                        return 5000 * retryContext.previousRetryCount;
                    }

                    console.error(`WebSocket reconnection stopped after ${ retryContext.previousRetryCount } attempts`);
                    return null;
                }
            })
            .build();
    }

    private deregisterOnServerEvents(): void {
        this.hubConnection.off(this.serverEventsKey);
    }

    private async innerConnect(): Promise<void> {
        try {
            if (this.isConnected() || !this.shouldReconnect()) {
                return;
            }

            await this.hubConnection.start();
            this.isWebSocketConnectedSubject.next(true);
        } catch (ex) {
            console.error('Error while establishing connection...');
            await wait(5000);
            await this.innerConnect();
        }
    }

    private isConnected(): boolean {
        return this.hubConnection.state === HubConnectionState.Connected;
    }

    private isDisconnected(): boolean {
        return this.hubConnection.state === HubConnectionState.Disconnected;
    }

    private shouldReconnect(): boolean {
        return this.authService.hasValidAccessToken();
    }

    private registerOnServerEvents(): void {
        this.hubConnection.on(this.serverEventsKey, (data: IRealTimeNotification) => {
            switch (data.notificationType) {
                case RealTimeNotificationType.ProjectStatusUpdate:
                    this.projectStatusUpdateNotificationsSubject
                        .next(data as IRealTimeProjectStatusUpdateNotification);
                    break;
                case RealTimeNotificationType.OffersCalculated:
                    this.offersGeneratedUpdateNotificationsSubject
                        .next(data as IRealTimeOffersGeneratedUpdateNotification);
                    break;
                case RealTimeNotificationType.OneOfferCalculated:
                    this.oneOfferGeneratedUpdateNotificationsSubject
                        .next(data as IRealTimeOneOfferGeneratedUpdateNotification);
                    break;
                case RealTimeNotificationType.OfferStatusUpdate:
                    this.offerStatusUpdateNotificationsSubject
                        .next(data as IRealTimeOfferStatusUpdateNotification);
                    break;
                case RealTimeNotificationType.DocumentThumbnailsGenerated:
                    this.thumbnailsGeneratedNotificationSubject
                        .next(data as IRealTimeDocumentThumbnailsNotification);
                    break;
                case RealTimeNotificationType.PossiblyNewProjectStatusImplications:
                    this.possiblyNewProjectStatusImplicationsSubject
                        .next(data as IRealTimePossiblyNewProjectStatusImplicationsNotification);
                    break;
                case RealTimeNotificationType.AdvisorUpdate:
                    this.projectAdvisorUpdateNotificationsSubject
                        .next(data as IRealTimeProjectAdvisorUpdateNotification);
                    break;
                case RealTimeNotificationType.OfferPartnerUpdate:
                    this.offerPartnerUpdateNotificationsSubject
                        .next(data as IRealTimeOfferStatusUpdateNotification);
                    break;
                case RealTimeNotificationType.DocumentProcessingStatusUpdate:
                    this.documentProcessingStatusUpdateSubject
                        .next(data as IRealTimeDocumentProcessingStatusUpdateNotification);
                    break;
                case RealTimeNotificationType.NotificationMessage:
                default:
                    this.messageNotificationsSubject
                        .next(data as IRealTimeMessageNotification);
                    break;
            }
        });

        this.hubConnection.onclose(async (error) => {
            this.isWebSocketConnectedSubject.next(false);
            if (error) {
                console.error(error);
            }
        });

        this.hubConnection.onreconnected(() => {
            console.info('WebSocket reconnected successfully.');
            this.isWebSocketConnectedSubject.next(true);
        });
    }
}
