import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { faCircleX, faHand } from '@fortawesome/pro-solid-svg-icons';
import { de, enGB, frCH, itCH } from 'date-fns/locale';
import { de_DE, en_US, fr_FR, it_IT } from 'ng-zorro-antd/i18n';
import { DEFAULT_NUMERIC_MASK, ISelectOption } from 'sfx-commons';
import { BusinessType } from '../projects/models/enums/businessType';
import { ObjectType } from '../projects/models/enums/objectType';
import { OfferStatus } from '../projects/models/enums/offerStatus';
import { ProductType } from '../projects/models/enums/productType';
import { ProjectStatus } from '../projects/models/enums/projectStatus';
import { ValuationQualityIAZI } from '../projects/models/enums/valuationQualityIAZI';
import { ValuationQualityWP } from '../projects/models/enums/valuationQualityWP';

export * from './select-option-constants';
export * from './mad-design-offer-illustrated-states';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export declare type SafeAny = any;

export const COLLATERAL_TYPES = {
    INSURANCE_POLICY: 'INSURANCE_POLICY',
    PENSION_FUND: 'PENSION_FUND',
    SAVINGS_THIRD_PILLAR: 'SAVINGS_THIRD_PILLAR'
};

export const DEFAULT_COORDS = {
    LON: 8.5417,
    LAT: 47.3769
};

export const DEFAULT_CREATE_PROJECT_FORM_VALUE = {
    title: undefined,
    businessType: BusinessType.Purchase,
    objectType: ObjectType.Condominium
};

export const DATE_HOUR_FORMAT = 'dd.MM.yyyy - HH:mm';

export const ONE_BILLION_MAX = 999999999;
export const DEFAULT_CURRENCY = 'CHF';
export const DEFAULT_THOUSAND_SEPARATOR = '\'';

export const DEFAULT_DECIMAL_NUMERIC_MASK: SafeAny = {
    mask: Number,
    scale: 1,
    min: 0,
    padFractionalZeros: false,
    normalizeZeros: true,
    radix: '.',
    mapToRadix: ['.'],
};

export const DEFAULT_INT_NUMERIC_MASK: SafeAny = {
    mask: Number,
    scale: 0,
    min: 0,
    padFractionalZeros: false,
    normalizeZeros: true,
};

export const SIGNED_DEFAULT_NUMERIC_MASK: SafeAny = {
    ...DEFAULT_NUMERIC_MASK,
    min: Number.MIN_SAFE_INTEGER
};

export const SIGNED_DECIMAL_MASK: SafeAny = {
    ...SIGNED_DEFAULT_NUMERIC_MASK,
    scale: 2
};

export const YEAR_NUMERIC_MASK: SafeAny = {
    ...DEFAULT_INT_NUMERIC_MASK,
    max: 9999
};

export const CLOSEST_PAYOUT_DATE_DELAY_IN_MINUTES = 5;
export const DEFAULT_PAGE_THUMBNAIL_WIDTH = 400;

export const DEFAULT_NOT_APPLICABLE_TRANCHE_RUNTIME = {
    [ProductType.Fixed]: 10,
    [ProductType.Saron]: null,
    [ProductType.Variable]: null,
    [ProductType.ConstructionCredit]: null
};

export const MAIN_ROUTES = {
    ABOUT_US: 'about-us',
    HOME: 'home',
    KEY4_TRAINING_COMPLETED: 'key4-training',
    LOGGING_IN: 'logging-in',
    LOGIN: 'login',
    LOGIN_BACKOFFICE: 'bo/login',
    PROJECTS: 'projects'
};

export const EDIT_PROJECT_PATHS = {
    ASSIGN_DOCUMENT: 'assign-document',
    BORROWER: 'borrower',
    BORROWER_ADDITIONAL_INCOME: 'additional-income',
    BORROWER_ADDITIONAL_INCOME_RENTAL_INCOME: 'rental-income',
    BORROWER_ASSETS: 'assets',
    BORROWER_INCOME_AT_RETIREMENT_AGE: 'income-at-retirement-age',
    BORROWER_LEGAL_ENTITY: 'legal-entity',
    BORROWER_LEGAL_ENTITY_COMPANY_DETAILS: 'company-details',
    BORROWER_LIABILITIES: 'liabilities',
    BORROWER_LIABILITIES_ALIMONY_LIABILITIES: 'alimony-liabilities',
    BORROWER_LIABILITIES_CONSUMER_CREDIT: 'consumer-credit',
    BORROWER_LIABILITIES_THIRD_PARTY_LOAN: 'third-party-loan',
    BORROWER_PERSONAL_INFO: 'personal-info',
    BORROWER_PAID_PER_HOUR_INCOME: 'paid-per-hour',
    BORROWER_SPECIAL_CASES: 'special-cases',
    BORROWER_SELF_EMPLOYMENT_INCOME: 'self-employment-income',
    BORROWER_VARIABLE_INCOME: 'variable-income',
    COLLATERAL: 'collateral',
    DESCRIPTION: 'description',
    DOCUMENTS: 'documents',
    EDIT: 'edit',
    REQUEST: 'request',
    OFFERS: 'offers',
    OFFER_DETAILS_DRAWER: 'offer',
    TEAM_SELLING: 'team-selling'
};

export const SIDEBAR_OUTLET_PATHS = {
    SERVICE_CENTER: 'service-center',
    SETTINGS: 'settings',
    NEWS: 'news'
};

export const FINANCING_MODAL_PATHS = {
    FINANCING: 'financing',
    EARLY_WITHDRAWALS: 'early-withdrawals',
    GIFTS_AND_INHERITANCE: 'gifts-and-inheritance',
    THIRD_PARTY_LOAN: 'third-party-loan'
};

export const COLLATERAL_MODAL_PATHS = {
    COLLATERAL: 'additional-collaterals'
};

export const DOCUMENT_VERSIONS_MODAL_PATH = 'document-versions';

export const PROPERTY_MODAL_PATHS = {
    FULL_VALUATION: 'full-valuation',
    LIGHT_VALUATION: 'light-valuation',
    PROPERTY: 'property',
    SPECIAL_CASES: 'special-cases'
};

export const PROJECT_PAGE_URL_SUBITEMS_TEMPLATE = [MAIN_ROUTES.PROJECTS, EDIT_PROJECT_PATHS.EDIT, '{projectId}'];
export const PROJECT_DOCUMENTS_PAGE_URL_SUBITEMS_TEMPLATE = [
    ...PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
    EDIT_PROJECT_PATHS.DOCUMENTS
];
export const OFFER_LIST_PAGE_URL_SUBITEMS_TEMPLATE = [
    ...PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
    EDIT_PROJECT_PATHS.REQUEST
];
export const OFFERS_PAGE_URL_SUBITEMS_TEMPLATE = [
    ...PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
    EDIT_PROJECT_PATHS.OFFERS
];

export enum OUTLET_NAMES {
    BORROWER_ADDITIONAL_INCOME_MODAL_OUTLET = 'ai',
    BORROWER_ADDITIONAL_INCOME_RENTAL_INCOME_MODAL_OUTLET = 'ri',
    BORROWER_ASSETS_MODAL_OUTLET = 'a',
    BORROWER_INCOME_AT_RETIREMENT_AGE_MODAL_OUTLET = 'iara',
    BORROWER_LEGAL_ENTITY_MODAL = 'ble',
    BORROWER_LEGAL_ENTITY_COMPANY_DETAILS_MODAL_OUTLET = 'blecd',
    BORROWER_LIABILITIES_MODAL_OUTLET = 'lia',
    BORROWER_LIABILITIES_ALIMONY_LIABILITIES_MODAL_OUTLET = 'alia',
    BORROWER_LIABILITIES_CONSUMER_CREDIT_MODAL_OUTLET = 'cc',
    BORROWER_LIABILITIES_THIRD_PARTY_LOAN_MODAL_OUTLET = 'btpl',
    BORROWER_MODAL_OUTLET = 'bor',
    BORROWER_PAID_PER_HOUR_INCOME_MODAL_OUTLET = 'bpphi',
    BORROWER_PERSONAL_INFO_MODAL_OUTLET = 'bpi',
    BORROWER_SPECIAL_CASES_MODAL_OUTLET = 'bsc',
    BORROWER_SELF_EMPLOYMENT_INCOME_MODAL_OUTLET = 'bsei',
    BORROWER_VARIABLE_INCOME_MODAL_OUTLET = 'vi',

    COLLATERAL_MODAL_OUTLET = 'coll',

    DOCUMENT_MODAL_OUTLET = 'doc',
    DOCUMENT_VERSIONS_MODAL_OUTLET = 'dv',

    FINANCING_MODAL_OUTLET = 'fin',
    FINANCING_EARLY_WITHDRAWALS_OUTLET = 'ewi',
    FINANCING_THIRD_PARTY_LOAN_MODAL_OUTLET = 'tpl',
    GIFTS_AND_INHERITANCE_OUTLET = 'gin',

    NEWS_MODAL_OUTLET = 'n',
    OFFER_DETAILS_DRAWER_OUTLET = 'od',

    PROJECT_DESCRIPTION_OUTLET = 'desc',

    PROPERTY_MODAL_OUTLET = 'pro',
    PROPERTY_FULL_VALUATION_MODAL_OUTLET = 'pfv',
    PROPERTY_LIGHT_VALUATION_MODAL_OUTLET = 'plv',
    PROPERTY_SPECIAL_CASES_OUTLET = 'prspc',

    SERVICE_CENTER_MODAL_OUTLET = 'sc',
    SETTINGS_MODAL_OUTLET = 'cfg',

    TEAM_SELLING_MODAL_OUTLET = 'ts'
}

export const PATH_NAMES_PER_OUTLETS = {
    [OUTLET_NAMES.BORROWER_ADDITIONAL_INCOME_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_ADDITIONAL_INCOME,
    [OUTLET_NAMES.BORROWER_ADDITIONAL_INCOME_RENTAL_INCOME_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_ADDITIONAL_INCOME_RENTAL_INCOME,
    [OUTLET_NAMES.BORROWER_ASSETS_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_ASSETS,
    [OUTLET_NAMES.BORROWER_INCOME_AT_RETIREMENT_AGE_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_INCOME_AT_RETIREMENT_AGE,
    [OUTLET_NAMES.BORROWER_LEGAL_ENTITY_MODAL]: EDIT_PROJECT_PATHS.BORROWER_LEGAL_ENTITY,
    [OUTLET_NAMES.BORROWER_LEGAL_ENTITY_COMPANY_DETAILS_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_LEGAL_ENTITY_COMPANY_DETAILS,
    [OUTLET_NAMES.BORROWER_LIABILITIES_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_LIABILITIES,
    [OUTLET_NAMES.BORROWER_LIABILITIES_ALIMONY_LIABILITIES_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_LIABILITIES_ALIMONY_LIABILITIES,
    [OUTLET_NAMES.BORROWER_LIABILITIES_CONSUMER_CREDIT_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_LIABILITIES_CONSUMER_CREDIT,
    [OUTLET_NAMES.BORROWER_LIABILITIES_THIRD_PARTY_LOAN_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_LIABILITIES_THIRD_PARTY_LOAN,
    [OUTLET_NAMES.BORROWER_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER,
    [OUTLET_NAMES.BORROWER_PAID_PER_HOUR_INCOME_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_PAID_PER_HOUR_INCOME,
    [OUTLET_NAMES.BORROWER_PERSONAL_INFO_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_PERSONAL_INFO,
    [OUTLET_NAMES.BORROWER_SPECIAL_CASES_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_SPECIAL_CASES,
    [OUTLET_NAMES.BORROWER_SELF_EMPLOYMENT_INCOME_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_SELF_EMPLOYMENT_INCOME,
    [OUTLET_NAMES.BORROWER_VARIABLE_INCOME_MODAL_OUTLET]: EDIT_PROJECT_PATHS.BORROWER_VARIABLE_INCOME,

    [OUTLET_NAMES.COLLATERAL_MODAL_OUTLET]: COLLATERAL_MODAL_PATHS.COLLATERAL,

    [OUTLET_NAMES.DOCUMENT_MODAL_OUTLET]: EDIT_PROJECT_PATHS.DOCUMENTS,
    [OUTLET_NAMES.DOCUMENT_VERSIONS_MODAL_OUTLET]: DOCUMENT_VERSIONS_MODAL_PATH,

    [OUTLET_NAMES.FINANCING_MODAL_OUTLET]: FINANCING_MODAL_PATHS.FINANCING,
    [OUTLET_NAMES.FINANCING_EARLY_WITHDRAWALS_OUTLET]: FINANCING_MODAL_PATHS.EARLY_WITHDRAWALS,
    [OUTLET_NAMES.FINANCING_THIRD_PARTY_LOAN_MODAL_OUTLET]: FINANCING_MODAL_PATHS.THIRD_PARTY_LOAN,
    [OUTLET_NAMES.GIFTS_AND_INHERITANCE_OUTLET]: FINANCING_MODAL_PATHS.GIFTS_AND_INHERITANCE,

    [OUTLET_NAMES.NEWS_MODAL_OUTLET]: SIDEBAR_OUTLET_PATHS.NEWS,

    [OUTLET_NAMES.OFFER_DETAILS_DRAWER_OUTLET]: EDIT_PROJECT_PATHS.OFFER_DETAILS_DRAWER,

    [OUTLET_NAMES.PROJECT_DESCRIPTION_OUTLET]: EDIT_PROJECT_PATHS.DESCRIPTION,

    [OUTLET_NAMES.PROPERTY_MODAL_OUTLET]: PROPERTY_MODAL_PATHS.PROPERTY,
    [OUTLET_NAMES.PROPERTY_FULL_VALUATION_MODAL_OUTLET]: PROPERTY_MODAL_PATHS.FULL_VALUATION,
    [OUTLET_NAMES.PROPERTY_LIGHT_VALUATION_MODAL_OUTLET]: PROPERTY_MODAL_PATHS.LIGHT_VALUATION,
    [OUTLET_NAMES.PROPERTY_SPECIAL_CASES_OUTLET]: PROPERTY_MODAL_PATHS.SPECIAL_CASES,

    [OUTLET_NAMES.TEAM_SELLING_MODAL_OUTLET]: EDIT_PROJECT_PATHS.TEAM_SELLING,
    [OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET]: SIDEBAR_OUTLET_PATHS.SERVICE_CENTER,
    [OUTLET_NAMES.SETTINGS_MODAL_OUTLET]: SIDEBAR_OUTLET_PATHS.SETTINGS,
};

export const FIXED_TRANCHE_RUNTIME_OPTIONS = {
    MIN: 1,
    MAX: 25
};

export enum SUPPORTED_LANGUAGE {
    DE_CH = 'DE_CH',
    EN = 'EN',
    FR_CH = 'FR_CH',
    IT_CH = 'IT_CH'
}

export const MOMENT_DATE_FORMATS_PER_LANGUAGE = {
    [SUPPORTED_LANGUAGE.DE_CH]: ['dd.MM.yyyy', 'd.M.yyyy', 'DD.M.yyyy', 'D.MM.yyyy'],
    [SUPPORTED_LANGUAGE.EN]: ['dd.MM.yyyy', 'd.M.yyyy', 'DD.M.yyyy', 'D.MM.yyyy'],
    [SUPPORTED_LANGUAGE.FR_CH]: ['dd.MM.yyyy', 'd.M.yyyy', 'dd.M.yyyy', 'd.MM.yyyy'],
    [SUPPORTED_LANGUAGE.IT_CH]: ['dd.MM.yyyy', 'd.M.yyyy', 'dd.M.yyyy', 'd.MM.yyyy']
};

export const MOMENT_TIME_DATE_FORMATS_PER_LANGUAGE = {
    [SUPPORTED_LANGUAGE.DE_CH]: ['dd.MM.yyyy HH:mm', 'd.M.yyyy HH:mm', 'DD.M.yyyy HH:mm', 'D.MM.yyyy HH:mm'],
    [SUPPORTED_LANGUAGE.EN]: ['dd.MM.yyyy HH:mm', 'd.M.yyyy HH:mm', 'DD.M.yyyy HH:mm', 'D.MM.yyyy HH:mm'],
    [SUPPORTED_LANGUAGE.FR_CH]: ['dd.MM.yyyy HH:mm', 'd.M.yyyy HH:mm', 'dd.M.yyyy HH:mm', 'd.MM.yyyy HH:mm'],
    [SUPPORTED_LANGUAGE.IT_CH]: ['dd.MM.yyyy HH:mm', 'd.M.yyyy HH:mm', 'dd.M.yyyy HH:mm', 'd.MM.yyyy HH:mm']
};

export const MOMENT_DATE_MONTH_FORMATS_PER_LANGUAGE = {
    [SUPPORTED_LANGUAGE.DE_CH]: ['MM.yyyy', 'M.yyyy'],
    [SUPPORTED_LANGUAGE.EN]: ['MM.yyyy', 'M.yyyy'],
    [SUPPORTED_LANGUAGE.FR_CH]: ['MM.yyyy', 'M.yyyy'],
    [SUPPORTED_LANGUAGE.IT_CH]: ['MM.yyyy', 'M.yyyy']
};

export const DateFnsLocales = {
    [SUPPORTED_LANGUAGE.EN]: enGB,
    [SUPPORTED_LANGUAGE.DE_CH]: de,
    [SUPPORTED_LANGUAGE.IT_CH]: itCH,
    [SUPPORTED_LANGUAGE.FR_CH]: frCH,
};

export const SWISS_NATIONALITY_ALPHA2_CODE = 'CH';

export const LANGUAGE_FILE_NAME_SUFFIX = {
    [SUPPORTED_LANGUAGE.DE_CH]: 'de-ch',
    [SUPPORTED_LANGUAGE.EN]: 'en',
    [SUPPORTED_LANGUAGE.FR_CH]: 'fr-ch',
    [SUPPORTED_LANGUAGE.IT_CH]: 'it-ch'
};

export const I18N_LANGUAGE_FILE_NAME = {
    [SUPPORTED_LANGUAGE.DE_CH]: de_DE,
    [SUPPORTED_LANGUAGE.EN]: en_US,
    [SUPPORTED_LANGUAGE.FR_CH]: fr_FR,
    [SUPPORTED_LANGUAGE.IT_CH]: it_IT
};

export const IAZI_CULTURES = {
    [SUPPORTED_LANGUAGE.DE_CH]: 'de-CH',
    [SUPPORTED_LANGUAGE.EN]: 'en-US',
    [SUPPORTED_LANGUAGE.FR_CH]: 'fr-CH',
    [SUPPORTED_LANGUAGE.IT_CH]: 'it-CH'
};

export const ISO_2_LANGUAGES = {
    [LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.EN]]: 'en',
    [LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.FR_CH]]: 'fr',
    [LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.DE_CH]]: 'de',
    [LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.IT_CH]]: 'it'
};

export const HYPO_DOSSIER_LANGUAGES = { ...ISO_2_LANGUAGES };

export const EXTENDED_SUPPORTED_LANGUAGES = {
    'de-DE': LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.DE_CH],
    'en-US': LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.EN],
    'it-IT': LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.IT_CH],
    'fr-FR': LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.FR_CH]
};

export const LANGUAGE_OPTIONS: Array<ISelectOption> = [
    { value: LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.DE_CH], label: 'languages.german' },
    { value: LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.FR_CH], label: 'languages.french' },
    { value: LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.IT_CH], label: 'languages.italian' },
    { value: LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.EN], label: 'languages.english' }
];

export const NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
};

export const OSM_NOMINATIM_API = 'https://nominatim.openstreetmap.org/search?q={{address}}&format=json';
export const OSM_NOMINATIM_API_PARAMS =
    'https://nominatim.openstreetmap.org/search?street={{street}}&city={{town}}&postalcode={{zip}}&format=json';
export const OSM_ACCEPTED_TYPES = {
    APARTMENTS: 'apartments',
    BUNGALOW: 'bungalow',
    CABIN: 'cabin',
    DETACHED: 'detached',
    DORMITORY: 'dormitory',
    FARM: 'farm',
    HOTEL: 'hotel',
    HOUSE: 'house',
    HOUSEBOAT: 'houseboat',
    RESIDENTIAL: 'residential',
    STATIC_CARAVAN: 'static_caravan',
    TERRACE: 'terrace'
};

export enum OfferState {
    DISABLED = 'DISABLED',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    HIDDEN = 'HIDDEN'
}

export const OFFER_SELECTION_SWITCH_TYPES = {
    FIXABLE_TO_FIXABLE: 'FIXABLE_TO_FIXABLE',
    FIXABLE_TO_FIXABLE_FROM_SAME_LENDER: 'FIXABLE_TO_FIXABLE_FROM_SAME_LENDER',
    FIXABLE_TO_SUBMITTABLE_SINGLE_SELECTION: 'FIXABLE_TO_SUBMITTABLE_SINGLE_SELECTION',
    FIXABLE_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER: 'FIXABLE_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER',
    FIXABLE_TO_SUBMITTABLE: 'FIXABLE_TO_SUBMITTABLE',
    FIXABLE_TO_SUBMITTABLE_FROM_SAME_LENDER: 'FIXABLE_TO_SUBMITTABLE_FROM_SAME_LENDER',
    SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Good: 'SUBMITTABLE_TO_SUBMITTABLE',
    SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Low: 'SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Low',
    SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_VeryLow: 'SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_VeryLow',
    SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Catastrophic: 'SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Catastrophic',
    SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Beginner: 'SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Beginner',
    SUBMITTABLE_TO_SUBMITTABLE_FROM_SAME_LENDER: 'SUBMITTABLE_TO_SUBMITTABLE_FROM_SAME_LENDER',
    SUBMITTABLE_TO_FIXABLE: 'SUBMITTABLE_TO_FIXABLE',
    SUBMITTABLE_TO_FIXABLE_FROM_SAME_LENDER: 'SUBMITTABLE_TO_FIXABLE_FROM_SAME_LENDER',
    SUBMITTABLE_TO_SUBMITTABLE_SINGLE_SELECTION: 'SUBMITTABLE_TO_SUBMITTABLE_SINGLE_SELECTION',
    SUBMITTABLE_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER: 'SUBMITTABLE_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER',
    SUBMITTABLE_SINGLE_SELECTION_TO_FIXABLE: 'SUBMITTABLE_SINGLE_SELECTION_TO_FIXABLE',
    SUBMITTABLE_SINGLE_SELECTION_TO_FIXABLE_FROM_SAME_LENDER: 'SUBMITTABLE_SINGLE_SELECTION_TO_FIXABLE_FROM_SAME_LENDER',
    SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_SINGLE_SELECTION: 'SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_SINGLE_SELECTION',
    SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER: 'SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER',
    SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE: 'SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE',
    SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_FROM_SAME_LENDER: 'SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_FROM_SAME_LENDER'
};

export const OFFER_SELECTION_SWITCH_KEYS = {
    [OFFER_SELECTION_SWITCH_TYPES.FIXABLE_TO_FIXABLE]: 'project.offer.fixable_to_fixable',
    [OFFER_SELECTION_SWITCH_TYPES.FIXABLE_TO_FIXABLE_FROM_SAME_LENDER]: 'project.offer.fixable_to_fixable_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.FIXABLE_TO_SUBMITTABLE_SINGLE_SELECTION]: 'project.offer.fixable_to_submittable_single_selection',
    [OFFER_SELECTION_SWITCH_TYPES.FIXABLE_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER]: 'project.offer.fixable_to_submittable_single_selection_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.FIXABLE_TO_SUBMITTABLE]: 'project.offer.fixable_to_submittable',
    [OFFER_SELECTION_SWITCH_TYPES.FIXABLE_TO_SUBMITTABLE_FROM_SAME_LENDER]: 'project.offer.fixable_to_submittable_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_FIXABLE]: 'project.offer.submittable_to_fixable',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_FIXABLE_FROM_SAME_LENDER]: 'project.offer.submittable_to_fixable_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_SINGLE_SELECTION]: 'project.offer.submittable_to_submittable_single_selection',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER]: 'project.offer.submittable_to_submittable_single_selection_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Good]: 'project.offer.submittable_to_submittable',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Low]: 'project.offer.submittable_to_submittable_with_conversion_rate_low',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_VeryLow]: 'project.offer.submittable_to_submittable_with_conversion_rate_very_low',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Catastrophic]: 'project.offer.submittable_to_submittable_with_conversion_rate_catastrophic',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_WITH_CONVERSION_RATE_Beginner]: 'project.offer.submittable_to_submittable_with_conversion_rate_beginner',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_TO_SUBMITTABLE_FROM_SAME_LENDER]: 'project.offer.submittable_to_submittable_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_SINGLE_SELECTION_TO_FIXABLE]: 'project.offer.submittable_single_selection_to_fixable',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_SINGLE_SELECTION_TO_FIXABLE_FROM_SAME_LENDER]: 'project.offer.submittable_single_selection_to_fixable_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_SINGLE_SELECTION]: 'project.offer.submittable_single_selection_to_submittable_single_selection',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_SINGLE_SELECTION_FROM_SAME_LENDER]: 'project.offer.submittable_single_selection_to_submittable_single_selection_from_same_lender',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE]: 'project.offer.submittable_single_selection_to_submittable',
    [OFFER_SELECTION_SWITCH_TYPES.SUBMITTABLE_SINGLE_SELECTION_TO_SUBMITTABLE_FROM_SAME_LENDER]: 'project.offer.submittable_single_selection_to_submittable_from_same_lender'

};

export enum ProjectStatusTableSizeOptions {
    Small = 'Small',
    Large = 'Large'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EMPTY_KEYPRESS_FUNC = (event) => true;

export const UPLOAD_STATUSES = {
    PROGRESS: 'progress',
    RESPONSE: 'response'
};

export const OFFERS_STEP = {
    NO_OFFERS: 'NO_OFFERS',
    BOUND_OFFERS: 'BOUND_OFFERS',
    AMENDED_OFFERS: 'AMENDED_OFFERS',
    ACCEPTED_OFFER: 'ACCEPTED_OFFER',
    FIXED_OFFER: 'FIXED_OFFER'
};

export const LOCATION_AUTOCOMPLETE_FIELDS = {
    STREET: 'streetname',
    HOUSE_NUMBER: 'houseNumber',
    ZIP: 'zipcode',
    TOWN: 'townName'
};

export const AUTOCOMPLETE_UPDATE_ACTION = {
    BLUR: 'BLUR',
    INPUT: 'INPUT'
};

export const LOCATION_SERVICE_CHECK_TIMEOUT = 30000;
export const INPUT_PER_LETTER_INDENT = 12;

export const UNBOUND_OFFERS_PROJECT_STATUSES = [
    ProjectStatus.Open,
    ProjectStatus.LightOfferCreated,
    ProjectStatus.OfferOverviewCreated
];

export const OFFER_CHANGED_STATUSES = [
    OfferStatus.AcceptedByLender,
    OfferStatus.RejectedByLender,
    OfferStatus.AmendedByLender
];

export const DEFAULT_ICONS = {
    MORE_INFO: faCircleInfo,
    EDIT_ICON: faPenToSquare,
    CLEAR_INPUT: faCircleX,
    ALERT_ICON: faHand
};

export enum IdentityProvider {
    SwissLife,
    SwissLifeSelect,
    Azure,
    DfsIdentityServer
}

export const StringifiedIdentityProvider = {
    [IdentityProvider.SwissLife]: 'SwissLife',
    [IdentityProvider.SwissLifeSelect]: 'SwissLifeSelect',
    [IdentityProvider.Azure]: 'Azure',
    [IdentityProvider.DfsIdentityServer]: 'DfsIdentityServer'
};

export enum ThirdPartyLoanLocation {
    Borrower,
    Financing
}

export const FILE_TYPES = {
    FILE: 'FILE',
    VIRTUAL: 'VIRTUAL'
};

export const VIRTUAL_FILE_MIME = 'application/x-dnd-move';

export const IAZI_PROP_TRANSLATION_KEYS = (objectType: ObjectType) => ({
    zip: 'property.zip',
    town: 'property.town',
    street: 'property.street',
    ortId: 'property.town',
    bathNb: 'property.full_valuation.number_of_regular_bathrooms',
    buildCondition: 'property.full_valuation.building_condition',
    buildQuality: '',
    buildRightDate: 'property.special_cases.leasehold_expiry_date',
    buildYear: 'property.full_valuation.build_year',
    flatCondition: 'property.light_valuation.flat_condition',
    flatNb: 'property.full_valuation.total_number_of_flats',
    floor: 'property.full_valuation.floor',
    floorNb: 'property.full_valuation.total_number_of_floors',
    liftNb: '',
    luxusFlag: 'property.full_valuation.is_luxury_property',
    maisonetteFlag: '',
    minergieCode: 'property.full_valuation.minergie_code',
    ownershipPercent: 'property.full_valuation.ownership_percentage',
    parkCoverNb: 'property.full_valuation.parking.' + objectType + '.number_of_garages_separate_building',
    parkExternalNb: 'property.full_valuation.parking.' + objectType + '.number_of_garages_outside_the_property',
    parkOpenNb: 'property.full_valuation.parking.' + objectType + '.has_outdoor_parking_spaces',
    primSecFlag: '',
    qualityFlatHouse: '',
    qualityMicro: '',
    renovationYear: 'property.full_valuation.year',
    roofFlag: '',
    roomNb: 'property.full_valuation.number_of_rooms',
    roomShiftCode: '',
    sepWcNb: 'property.full_valuation.number_of_guest_bathrooms',
    servituteFlag: 'property.special_cases.servitute_flag',
    surfaceBalcony: 'property.full_valuation.surface_balcony',
    surfaceGround: 'property.full_valuation.surface_land',
    surfaceLiving: 'property.full_valuation.surface_living_net',
    typeFlatBuildingCode: '',
    typeFlatCode: '',
    typeHouseCode: 'property.light_valuation.house_type',
    volume: 'property.full_valuation.volume',
    volumeCode: 'property.full_valuation.volume_code'
});

export const SPECIFIC_DOCUMENT_GROUPS = {
    NotCategorized: 'NotCategorized',
    NotUsed: 'NotUsed'
};

export const SwissLifePensionInstitution = 'SwissLife';

export const DOCUMENTS_FOOTER_ACTION = {
    SubmitToCC: 'SubmitToCC',
    SubmitToLender: 'SubmitToLender',
    ReleaseProject: 'ReleaseProject'
};

export const CANCELLED_REQUEST_ERROR = 'The request was cancelled.';

export const DefaultEmptyLenderId = -1;

export const TextEndingWithParenthesesRegex = /^(.*)\(([^)]*)\)$/;

export const LOGOUT_REASON = {
    UNAUTHORIZED: '401',
    SESSION_EXPIRED: 'session-expired',
    FORBIDDEN: '403'
};

export const LOGIN_LOGOUT_QUERY_PARAM = {
    [LOGOUT_REASON.UNAUTHORIZED]: 'err',
    [LOGOUT_REASON.SESSION_EXPIRED]: 'err',
    [LOGOUT_REASON.FORBIDDEN]: 'err',
};

enum PROPERTY_VALUATION_QUALITY_COLOURS {
    GREEN = '#6cd5c6',
    RED = '#ff7b7b',
    YELLOW = 'yellow'
}

export const PROPERTY_VALUATION_QUALITY_COLORS_IAZI: { [key in ValuationQualityIAZI]: PROPERTY_VALUATION_QUALITY_COLOURS } = {
    [ValuationQualityIAZI.Extrapolated]: PROPERTY_VALUATION_QUALITY_COLOURS.RED,
    [ValuationQualityIAZI.Prediction]: PROPERTY_VALUATION_QUALITY_COLOURS.RED,
    [ValuationQualityIAZI.Fair]: PROPERTY_VALUATION_QUALITY_COLOURS.YELLOW,
    [ValuationQualityIAZI.Good]: PROPERTY_VALUATION_QUALITY_COLOURS.GREEN
};

export const PROPERTY_VALUATION_QUALITY_COLORS_WP: { [key in ValuationQualityWP]: PROPERTY_VALUATION_QUALITY_COLOURS } = {
    [ValuationQualityWP.Green]: PROPERTY_VALUATION_QUALITY_COLOURS.GREEN,
    [ValuationQualityWP.Yellow]: PROPERTY_VALUATION_QUALITY_COLOURS.YELLOW,
    [ValuationQualityWP.Red]: PROPERTY_VALUATION_QUALITY_COLOURS.RED
};

export const PROPERTY_VALUATION_PROVIDERS = {
    IAZI: 'IAZI',
    WP: 'Wüest Partner'
};