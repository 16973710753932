import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[appScrollToActiveStatus]'
})
export class ScrollToActiveStatusDirective implements AfterViewInit, OnDestroy {
    @Input({ required: true })
    public currentStopSelector: string;
    @Input({ required: true })
    public containerSelector: string;
    @Input()
    public parallelSelector: string;
    @Input()
    public scrollableSelector: string;
    @Input()
    public updateTriggerObservable: Observable<boolean>;

    private containerElement: HTMLElement;
    private parallelElement: HTMLElement;
    private scrollableElement: HTMLElement;
    private resizeObserver: ResizeObserver;

    constructor(private readonly elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        if (this.updateTriggerObservable) {
            this.updateTriggerObservable
                .pipe(untilDestroyed(this))
                .subscribe(shouldUpdate => {
                    if (shouldUpdate) {
                        setTimeout(() => {
                            this.setup();
                        }, 150);
                    }
                });
        }

        this.setup();
    }

    public ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    private setup(): void {
        if (this.containerSelector) {
            this.containerElement = document.querySelector(this.containerSelector);
            this.scrollableElement = this.scrollableSelector ? document.querySelector(this.scrollableSelector) :
                this.elementRef.nativeElement;
            this.parallelElement = document.querySelector(this.parallelSelector);

            if (this.parallelElement) {
                this.resizeObserver = new ResizeObserver(() => {
                    this.scrollToCurrentStop();
                });
                this.resizeObserver.observe(this.parallelElement);
            }

            this.scrollToCurrentStop();
        }
    }

    private scrollToCurrentStop(): void {
        const activeElement = (this.containerElement ? this.containerElement : document)
            .querySelector(this.currentStopSelector) as HTMLElement;

        if (!activeElement) {
            return;
        }

        this.scrollableElement.scrollTo({ top: activeElement.offsetTop, behavior: 'smooth' });
    }

    private setupHeight(): void {
        if (this.parallelElement && this.containerElement) {
            if (this.parallelElement !== this.containerElement) {
                this.containerElement.setAttribute('style', `min-height: 0; max-height: 0;`);
            } else {
                this.containerElement.removeAttribute('style');
            }
            const newHeight = this.parallelElement.getBoundingClientRect().height + 'px';
            this.containerElement.setAttribute('style', `min-height: ${newHeight}; max-height: ${newHeight};`);
        }
    }
}
