import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';
import { RouterService } from '../../core/services/router.service';
import { isNullOrUndefined } from 'sfx-commons';

@Pipe({
    name: 'translateRoute',
})
export class TranslateRoutePipe implements PipeTransform {
    constructor(private readonly routerSvc: RouterService) {}

    public transform(routeSubItems: Array<string | number>, ...args: Array<SafeAny>): string {
        let parameters: Array<number> = null;
        let isAbsoluteRoute = true;
        if (args?.length) {
            const config = args[0];
            if (config) {
                const { isAbsolute: isAbsoluteInConfig, ...restOfParams } = config;
                if (!isNullOrUndefined(isAbsoluteInConfig)) {
                    isAbsoluteRoute = isAbsoluteInConfig;
                }
                const keys = Object.keys(restOfParams);
                if (keys.length) {
                    parameters = [];
                    keys.map(key => parameters.push(restOfParams[key]));
                }
            }
        }

        return this.routerSvc.translateRoute(routeSubItems, parameters, isAbsoluteRoute);
    }
}
