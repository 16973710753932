<sfx-modal [isVisible]="true"
           *ngIf="news?.length"
           [title]="modalTitle"
           extraModalClasses="news-modal"
           (cancelClicked)="hideModal()"
>
    <ng-template #modalTitle>
        <sfx-title-bar [icon]="bellIcon"
                       [isModalTitleBar]="true"
                       [isDataInputInModal]="false"
                       titleKey="navigation_title.news"
                       [hasCloseButton]="true"
                       (closeClicked)="hideModal()"
        ></sfx-title-bar>
    </ng-template>
    <div class="keep-text-format news-content mad-auto-scroll mad-padding-top-8 mad-padding-bottom-8">
        <ng-container *ngIf="news?.length">
            <div class="news-entry" *ngFor="let newsEntry of news; let i = index">
                <ng-container [ngTemplateOutlet]="newsEntryTemplate"
                              [ngTemplateOutletContext]="{ newsEntry, isExpandable: i > 0 }"
                ></ng-container>
            </div>
        </ng-container>

        <ng-template #newsEntryTemplate let-newsEntry="newsEntry" let-isExpandable="isExpandable">
            <div class="news-entry__title"
                 [ngClass]="{ 'news-entry__title--non-expandable': !isExpandable }"
                 (click)="onNewsEntryClick(newsEntry, isExpandable)">
                <span class="mad-title mad-title--sm">{{ newsEntry.title }}</span>
                <sfx-label *ngIf="newsEntry.status === NewsStatus.Draft"
                           label="news.draft_label"
                           size="large"
                           class="news-entry__label"
                ></sfx-label>
                <div *ngIf="newsEntry.status === NewsStatus.Published"
                     class="news_entry__date mad-text mad-text--s">{{ newsEntry.publishedAt | date: dateFormat }}</div>
            </div>
            <div *ngIf="newsEntry.isExpanded || !isExpandable"
                 class="mad-padding-top-8 keep-text-format"
                 [innerHTML]="newsEntry.content">
            </div>
        </ng-template>
    </div>
</sfx-modal>
